import React, { useEffect, useRef, MouseEvent } from 'react'
import './buttons.scss'

/* interface buttonRef extends HTMLButtonElement {
  disabled: boolean
} */
const Button = (props) => {
  const {
    id,
    cssName,
    disabled,
    onClick,
    width,
    height,
    color,
    bgColor,
    bgImage,
    children,
    fontSize,
    fontFamily,
    border,
    borderRadius,
    padding,
    margin,
    disableShadow,
    justifyContent,
    buttonPosition
  } = props
  //const { fontSize, ref } = useFitText(props.fontSize)
  const ref = useRef(null)
  const mouseOnClick = useRef(false)
  const setStyle = () => {
    let style = {
      fontFamily: 'inherit'
    }
    if (fontSize) {
      style = { ...style, fontSize: fontSize }
    }
    if (fontFamily) {
      style = { ...style, fontFamily: fontFamily }
    }
    if (width) {
      style = { ...style, width: width }
    }
    if (height) {
      style = { ...style, height: height }
    }
    if (color) {
      style = { ...style, color: color }
    }
    if (bgColor) {
      style = { ...style, backgroundColor: bgColor }
    }
    if (bgImage) {
      style = { ...style, backgroundImage: `url(${bgImage})` }
    }
    if (border) {
      style = { ...style, border: border }
    }
    if (borderRadius) {
      style = { ...style, borderRadius: borderRadius }
    }
    if (padding) {
      style = { ...style, padding: padding }
    }
    if (margin) {
      style = { ...style, margin: margin }
    }
    if (!disableShadow) {
      style = { ...style, boxShadow: 'inset 0px 0px 3px #0000001c' }
    }
    if (justifyContent) {
      style = { ...style, justifyContent: justifyContent }
    }
    if (buttonPosition) {
      if (buttonPosition === 'LEFT') {
        style = { ...style, padding: '0 50px 0 20px' }
      } else if (buttonPosition === 'RIGHT') {
        style = { ...style, padding: '0 20px 0 50px' }
      } else {
        style = { ...style, padding: padding }
      }
    }
    return style
  }
  const buttonStyle = setStyle()

  useEffect(() => {
    if (disabled) {
      if (ref.current) {
        // ref.current.disabled = true
        ref.current.style.opacity = '0.6'
      }
    } else {
      if (ref.current) {
        // ref.current.disabled = false
        ref.current.style.opacity = '1'
      }
    }
  }, [disabled])

  return (
    <button
      dir="auto"
      key={id}
      id={id}
      ref={ref}
      style={buttonStyle}
      className={cssName ? 'button-default ' + cssName : 'button-default'}
      aria-disabled={disabled}
      tabIndex={0}
      // disabled={disabled}
      onMouseOut={() => {
        if (ref.current && !disabled && mouseOnClick.current === true) {
          ref.current.style.opacity = '1'
        }
      }}
      onMouseDown={() => {
        mouseOnClick.current = true
        if (ref.current && !disabled) {
          ref.current.style.opacity = '0.6'
        }
      }}
      onMouseUp={() => {
        mouseOnClick.current = false
        if (ref.current && !disabled) {
          ref.current.style.opacity = '1'
        }
      }}
      onClick={(event) => {
        if (!disabled && onClick) {
          onClick(event)
        }
      }}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  fontSize: '24px',
  disable: false,
  width: '200px',
  height: '60px'
}

export default Button
