import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { Spacer, PageTitle, PageSubTitle, DynamicImage, PageFooter, PageSubContent } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility } from 'main'
// import { Footer } from '../footer'
import useMedia from 'hooks/useMedia'

const GenericPleaseWait = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const locale = useSelector((state) => state.localData.locale)
  const [displayMessage, setDisplayMessage] = useState('')
  // const [displayTitle, setDisplayTitle] = useState(intl.formatMessage(messages.PleaseWaitTitle))
  // const location = useLocation()
  // const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  // navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitBagProcess' } }, 20)

  // useEffect(() => {
  //   if (location?.state?.messageId) {
  //     setDisplayMessage(intl.formatMessage(messages[location.state.messageId]))
  //     if (location.state.messageId === 'PleaseWaitFindReservation') {
  //       setDisplayTitle(intl.formatMessage(messages.PleaseWaitFindReservationTitle))
  //     }
  //   } else if (props.pleaseWaitTextId) {
  //     setDisplayMessage(intl.formatMessage(messages[pleaseWaitTextId]))
  //     if (props.pleaseWaitTextId === 'PleaseWaitFindReservation') {
  //       setDisplayTitle(intl.formatMessage(messages.PleaseWaitFindReservationTitle))
  //     }
  //   }
  // }, [])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  /* useEffect(() => {
    if (location?.state?.messageId || props.pleaseWaitTextId) {
      if (displayMessage !== '') {
        handleAccessibility()
      }
    } else {
      handleAccessibility()
    }
  }, [displayMessage]) */

  useEffect(() => {
    handleAccessibility()
  }, [locale, displayMessage])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'pleaseWait',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [intl.formatMessage(messages.PleaseWaitTitle), displayMessage]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${themeContext.AnimationPleaseWait}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {messages.PleaseWaitTitleEN}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {messages.PleaseWaitBagProcessEN}
      </PageSubTitle>
      <Spacer height={'20px'} />
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {messages.PleaseWaitTitleFR}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {messages.PleaseWaitBagProcessFR}
      </PageSubTitle>
    </>
  )

  const header = null

  // const footer = (
  //   <>
  //     <Footer
  //       isQuitRequired={false}
  //       isBackRequired={false}
  //       isSkipRequired={false}
  //       isConfirmRequired={false}
  //       isLangRequired={false}
  //       displayItinerary={false}
  //     />
  //   </>
  // )

  const brandLogo = <DynamicImage imageName={`${themeContext.BrandLogo}`} width={71} height={12} />

  const footer = (
    <PageFooter>
      <PageSubContent
        flexFlow={isLandscape ? 'row' : 'column-reverse'}
        width="100%"
        height={'150px'}
        padding={themeContext.Footer.atcPadding[ratioKey]}
      ></PageSubContent>
      <PageSubContent flexFlow="row" padding="0" width="100%" height={themeContext.Footer.brandHeight[ratioKey]}>
        {themeContext.ShowEMBlogo ? brandLogo : ''}
      </PageSubContent>
    </PageFooter>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}
export default GenericPleaseWait
