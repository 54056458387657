import axios from 'axios'
import { ErrCodes } from 'constants/Errors'
import { TraceLevels, HostInterfaceEventType } from 'embross-device-manager'
import { appLog } from 'utils/Logger'
import { isInteger, replacer, navigate } from 'utils/helper'
import { store, getTSDManager, history, getSummaryStore } from 'main'
import { transitions } from 'actions/etsTransactions/transitions'
import { updateResponses } from 'actions/responseActions'
import { updateFetchError, updateLocalError } from 'actions/commonActions'
import { TransitionCodes } from 'constants/Constants'

const axiosInstance = axios.create({
  baseURL: clientConfig.hostURL,
  timeout: config.timeoutHostTransaction * 1000,
})

const bioInstance = axios.create({
  timeout: config.timeoutHostTransaction * 1000,
})

const resquestHandler = (requestBody) => {
  //console.log('axios request interceptors:', requestBody)
  const fullUrl = `${requestBody.baseURL}/${requestBody.url}`
  appLog(TraceLevels.LOG_TRACE, '>> (FetchWithTimeout.js) request interceptors uri = "' + requestBody.url + '"')
  appLog(TraceLevels.LOG_EXT_TRACE, '>> (FetchWithTimeout.js) request timeout - ms = "' + requestBody.timeout + '"')
  appLog(TraceLevels.LOG_TRACE, '>> (FetchWithTimeout.js) fetch(' + fullUrl + ')')
  const matchingList = config.hostRequestWhitelist
    ? config.hostRequestWhitelist.filter((endpoint) => {
        const regex = /\//gi
        const checkuri = requestBody.url.replace(regex, '')
        const uri = endpoint.replace(regex, '')
        return uri === checkuri
      })
    : []
  if (matchingList.length > 0) {
    getTSDManager().addHostInterfaceEvent(HostInterfaceEventType.REQUEST, fullUrl, requestBody.url, requestBody.data)
  } else {
    getTSDManager().addHostInterfaceEvent(HostInterfaceEventType.REQUEST, fullUrl, requestBody.url, {})
  }
  return requestBody
}

const requestErrorHandler = (error) => {
  appLog(TraceLevels.LOG_TRACE, '>> (FetchWithTimeout.js) request interceptor error = "' + error + '"')
  return Promise.reject(error)
}

const responseHandler = (res) => {
  const requestBody = res.config
  const currentSessionInfo = store.getState().sessions.sessionInfo
  //console.log('axios response interceptors:', res)
  //console.log('axios local session INFO:', requestBody.url, requestBody.sessionInfo, currentSessionInfo)
  getTSDManager().addHostInterfaceEvent(
    HostInterfaceEventType.RESPONSE,
    `${requestBody.baseURL}/${requestBody.url}`,
    requestBody.url,
    {}
  )
  if (requestBody.sessionInfo != null && currentSessionInfo.etsSessionID !== requestBody.sessionInfo.etsSessionID) {
    return Promise.reject(diffSessionError())
  }
  return res
}

const responseErrorHandler = (err) => {
  return Promise.reject(err)
}

axiosInstance.interceptors.request.use(resquestHandler, requestErrorHandler)

axiosInstance.interceptors.response.use(responseHandler, responseErrorHandler)

bioInstance.interceptors.request.use(resquestHandler, requestErrorHandler)

bioInstance.interceptors.response.use(responseHandler, responseErrorHandler)

export async function fetchWithTimeout(uri, data, ms) {
  const sessionInfo = store.getState().sessions.sessionInfo
  if (!isInteger(ms)) ms = config.timeoutHostTransaction * 1000
  let response = null

  try {
    response = await axiosInstance.post(uri, data, { timeout: ms, sessionInfo })
    if (response && response.data) {
      if (response.data.error) {
        console.log(response.data.error)
      }
    }
    return response.data
  } catch (err) {
    appLog(TraceLevels.LOG_ALERT, '>> (FetchWithTimeout.js) Catch2:' + JSON.stringify(err))
    getTSDManager().addHostInterfaceEvent(HostInterfaceEventType.EXCEPTION, clientConfig.hostURL, uri, err.message)
    // if (err.errorCode === ErrCodes.DIFFERENT_SESSION) {
    //   throw err
    // } else {
    if (err.code === 'ERR_NETWORK') {
      throw commError(err)
    } else if (err.message.indexOf('timeout') >= 0) {
      throw timeoutError(err)
    } else {
      throw commError(err) // convert to expected errors
    }
    // }
  }
}

export async function fetchWithUrl(url, data, ms) {
  const sessionInfo = store.getState().sessions.sessionInfo
  if (!isInteger(ms)) ms = config.timeoutHostTransaction * 1000

  let response = null

  appLog(TraceLevels.LOG_TRACE, '>> (FetchWithTimeout.js) fetchWithUrl() ***** START ***** url = "' + url + '"')

  try {
    response = await bioInstance.post(url, data, { timeout: ms, sessionInfo })
    if (response && response.data) {
      if (response.data.error) {
        console.log(response.data.error)
      }
    }
    return response.data
  } catch (err) {
    appLog(TraceLevels.LOG_ALERT, '>> (FetchWithTimeout.js) Catch2:' + JSON.stringify(err))
    getTSDManager().addHostInterfaceEvent(HostInterfaceEventType.EXCEPTION, clientConfig.hostURL, uri, err.message)
    // if (err.errorCode === ErrCodes.DIFFERENT_SESSION) {
    //   throw err
    // } else {
    if (err.message.indexOf('Failed to fetch') >= 0) throw commError(err)
    else throw err // convert to expected errors
    // }
  }
}

function stringifyError(err) {
  let plainObject = {}
  Object.getOwnPropertyNames(err).forEach(function (key) {
    plainObject[key] = err[key]
  })
  return JSON.stringify(plainObject)
}

function fetchStatusHandler(response) {
  //  appLog(TraceLevels.LOG_EXT_TRACE,'fetchStatusHandler response.status: ' + response.status)
  if (response.status === 200) {
    appLog(TraceLevels.LOG_TRACE, '>> (FetchWithTimeout.js) fetchStatusHandler() - response.status === 200')
    return response
  } else {
    appLog(
      TraceLevels.LOG_TRACE,
      '>> (FetchWithTimeout.js) fetchStatusHandler() - response.status !== 200 ' + response.status
    )
    throw commError(response.statusText)
  }
}

function timeoutError() {
  let error = new Error('timeout')
  error.errorCode = ErrCodes.ETS_TIMEOUT
  error.source = 'fetch'
  error.transition = 'OOS'
  error.screenMsgs = 'Error_Default'
  appLog(TraceLevels.LOG_TRACE, stringifyError(error))
  return error
}

function commError(details) {
  let error = new Error(details)
  error.errorCode = ErrCodes.ETS_COMM_ERROR
  error.source = 'fetch'
  error.transition = 'OOS'
  error.screenMsgs = 'Error_Default'
  appLog(TraceLevels.LOG_TRACE, stringifyError(error))
  return error
}

function fetchTimeout(ms, promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(timeoutError())
    }, ms)
    promise.then(resolve, reject)
  })
}

function diffSessionError() {
  let error = new Error('differentSession')
  error.errorCode = ErrCodes.DIFFERENT_SESSION
  appLog(TraceLevels.LOG_TRACE, stringifyError(error))
  return error
}

export function handleFetchErrors(err, onPage, errorPage) {
  if (err.errorCode !== ErrCodes.DIFFERENT_SESSION) {
    appLog(TraceLevels.LOG_ALERT, onPage + ' catch:' + JSON.stringify(err) + ' err.message:' + err.message)
    appLog(TraceLevels.LOG_ALERT, onPage + ' catch err.details:' + err.detail + ' errCode: ' + err.errCode)
    if (store.getState().sessions.OOS) {
      return store.dispatch(transitions(TransitionCodes.OOS_ERROR, null))
    }
    if (err.errorCode === ErrCodes.ETS_COMM_ERROR || err.errorCode === ErrCodes.ETS_TIMEOUT) {
      store.dispatch(updateLocalError(err.errorCode, err.screenMsgs, err.source, err.transition))
    } else if (err.errorCode != undefined) {
      store.dispatch(updateFetchError(err.errorCode, err.screenMsgs, err.source, err.transition))
    } else {
      store.dispatch(updateFetchError(err.detail, err.message, err.source, err.transition))
    }
    navigate(errorPage)
    // navigate to the Error page - set the error code and message before
  }
}

export function goToFetchError(funcName, json, dispatch, transitionPage, summaryParams = null) {
  const summaryStore = getSummaryStore()
  if (summaryParams !== null) {
    if (summaryParams.error !== undefined) {
      // scan error
      summaryStore.updateDocScanError(summaryParams.error)
    }
  } else {
    // generalError
    summaryStore.GeneralErrors = json.error.errorCode
  }

  if (json.kacResources) {
    dispatch(updateResponses('updateKacResources', json.kacResources))
  }
  if (json.kacPrintstream) {
    dispatch(updateResponses('updateKacPrintstream', json.kacPrintstream))
  }
  let desc = json.error.screenMsgs ? json.error.screenMsgs : ''
  const errorObj = {
    errorCode: json.error.errorCode,
    screenMsgs: desc,
    source: funcName,
    transition: json.transition,
  }
  handleFetchErrors(errorObj, funcName, transitionPage)
}

export function goToFetchGenericError(funcName, json, dispatch, summaryParams = null) {
  if (json.transition === 'AGENT_CHECK') {
    goToFetchError(funcName, json, dispatch, 'AgentOverride', summaryParams)
  } else {
    goToFetchError(funcName, json, dispatch, 'error', summaryParams)
  }
}
