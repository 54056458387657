const acc_en = {
  /************************************************************/
  /**            Common Component Speeches                   **/
  /************************************************************/
  // Language speaks in its language
  en: 'English',
  ru: 'русский',
  pt: 'Português',
  fr: 'Français',
  ar: 'العربية',
  sw: 'Kiswahili',
  de: 'Deutsch',
  zh: '中文',
  sv: 'Svenska',
  el: 'Eλληνικά',
  nl: 'Nederlands',
  tr: 'Türkçe',
  it: 'Italiano',
  fi: 'Suomi',
  ur: 'اردو',
  es: 'Español',
  ja: '日本語',
  ko: '한국어',
  'zh-Hant': '繁體中文',
  // KeyPad
  btnA: 'A ',
  btnB: 'B ',
  btnC: 'C ',
  btnD: 'D ',
  btnE: 'E ',
  btnF: 'F ',
  btnG: 'G ',
  btnH: 'H ',
  btnI: 'I ',
  btnJ: 'J ',
  btnK: 'K ',
  btnL: 'L ',
  btnM: 'M ',
  btnN: 'N ',
  btnO: 'O ',
  btnP: 'P ',
  btnQ: 'Q ',
  btnR: 'R ',
  btnS: 'S ',
  btnT: 'T ',
  btnU: 'U ',
  btnV: 'V ',
  btnW: 'W ',
  btnX: 'X ',
  btnY: 'Y ',
  btnZ: 'Z ',
  btn1: '1 ',
  btn2: '2 ',
  btn3: '3 ',
  btn4: '4 ',
  btn5: '5 ',
  btn6: '6 ',
  btn7: '7 ',
  btn8: '8 ',
  btn9: '9 ',
  btn0: '0 ',
  // special keys
  btnBS: 'Backspace. Press enter to erase 1 character.',
  btnAT: 'at sign',
  btnDT: 'dot',
  currentInputValue: 'you have entered <say-as interpret-as="characters">{0}</say-as>',

  // common
  UP: 'UP',
  DOWN: 'DOWN',
  ButtonYes: 'Yes',
  ButtonNo: 'No',
  ButtonOk: 'Ok',
  ButtonRetry: 'Retry',
  ButtonAccept: 'Accept',
  ButtonDecline: 'Decline',
  NavBack: 'Back to previous step',
  NavConfirm: 'Confirm',
  NavContinue: 'Continue',
  NavDone: 'Done',
  NavHelp: 'Help',
  NavNext: 'Next',
  NavQuit: 'Quit Application',
  NavSelectLanguage: 'Select Language',
  NavSkip: 'Skip',
  EndOfHelp: 'end of help',
  PlusInfant: 'Plus infant',
  DOB: 'Date of Birth ',
  FirstName: 'First name',
  LastName: 'Last name',
  FlightNumber: 'flight number',
  Selected: ' selected ',
  UnSelected: ' not selected ',
  ScanBoardingPass: 'Scan your boarding pass',
  PlaceBagOnBelt: 'Place your bag on the belt',
  AgentHelp: 'Please see an agent for additional help.',
  /************************************************************/
  /**            Device Help (set by application)            **/
  /************************************************************/
  // FileName: Device Help Messages
  Barcode_Reader:
    'The Barcode scanner is located under the screen monitor. Scan your boarding pass or bar code face {0}',
  Card_Reader: 'This is a sample message for Card Reader Help. ',
  Passport_Reader: 'This is a sample message for Passport reader help. ',
  BagTag_Printer: 'This is a sample message for Bag Tag Printer help. ',
  Aea_BagDrop: 'This is a sample message for AEA Bag drop help. ',
  Cuss_Accessibility_keypad: 'This is a sample message for Accessibility keypad help. ',
  /************************************************************/
  /**            Basic Flow Accessibility Speeches           **/
  /************************************************************/
  // Headset inserted
  Application:
    'Welcome to {0} Bag Drop Kiosk. This self-service kiosk will guide you though the bag drop process. ' +
    'You will need to interact with various hardware devices that are part of this kiosk to complete the bag drop process. ' +
    'The headset plug is part of a navigation keypad that facilitates your interaction with the application.  ' +
    'Press the square button beside the headset plug to cycle through different volume levels.  ' +
    'There are five keys in a diamond group beneath the headset plug. The flat square key in the middle of the diamond is the Enter key. ' +
    'The other four keys are up, down, previous and next.  Press and hold the Enter key for two seconds to select help. ' +
    'Above the navigation keypad is a touchscreen.  To your {1} is a conveyor with built-in weigh scale for your baggage. ' +
    'The top of the conveyor is 0 point 2 meters or eight inches above the floor.   The conveyor area is monitored.  ' +
    'Its motion will stop when you reach in.  As you progress through the bag drop process, ' +
    'the application will provide further instructions on what to do and how to use the kiosk components. ',

  // 'Welcome to {0} Bag Drop Kiosk. You may navigate to Help by pressing the enter key twice at anytime during this transaction to repeat the last message. Or press the enter key three times to access the device help. You may also quit the transaction by using the up and down keys to navigate to Quit. Always press enter once to make your selection. ',
  SelectLanguage: 'Press up until you hear {0} and press Enter to switch languages. ',
  NavKeypadHelp:
    'This kiosk has a navigation keypad to control the application. Press down key for further instructions',
  NotSupportAccessibility: 'Accessibility is not supported at this time',
  AccessibilityEnabled: 'Accessibility enabled',
  AccessibilityDisabled: 'Accessibility disabled',
  TranslationUnavailable: 'Language translation unavailable for textId ',
  StartApp: 'Press enter to start application',
  // Timeout popup
  TimeoutPopupDescription:
    'Current screen is about to expire, Do you need more time? Please use up and down key to confirm your action.',
  TimeoutPopupYes: 'Yes, Press enter to return to the previous step',
  TimeoutPopupNo: 'No, Press enter to end current transaction',
  // Exit popup
  ExitPopupDescription:
    '"You have chosen to cancel your transaction. Are you sure? Please use up and down key \' + \'to confirm your action."',
  ExitPopupYes: 'Yes, Press enter to end current transaction',
  ExitPopupNo: 'No, Press enter to return to previous step',
  // Language popup
  LanguageSwitchPopupTitle: 'Please select your language',
  // MISC
  OneDynamicText: '{0}',
  TwoDynamicText: '{0}, {1}',
  ThreeDynamicText: '{0}, {1}, {2}',
  /************************************************************/
  /**           Accessibility Speeches per Screen            **/
  /************************************************************/
  // FileName: 6.2 WELCOME SCREEN
  WelcomeScreenPrompt:
    'Place your heaviest bag on the conveyor. Ensure there is a bag tag attached to your luggage.  The conveyor is to your {0}.  Its top surface is 0 point 2 meters or eight inches above the floor.  Place the largest side down with no wheels contacting the conveyor. Step back from the conveyor once the bag is placed.',
  WelcomeScreenAllPrompt:
    'Use one of the following options to process your bag. {0} or {1}. To scan your boarding pass - Please place your boarding pass barcode face {2} under the scanner. You can use your paper boarding pass or the barcode on your mobile phone. Leave the barcode there until you hear the next prompt. Press the Help key for information on the barcode reader location.',
  WelcomeScreenBPPrompt:
    'Please place your boarding pass barcode face {0} under the scanner. You can use your paper boarding pass or the barcode on your mobile phone. Leave the barcode there until you hear the next prompt. Press the Help key for information on the barcode reader location.',
  WelcomeScreenBTPrompt:
    'Please place your bag flat down and horizontally on the bag drop belt. Ensure the bag tag is placed on top of the bag and not hanging off the bag drop belt. If your bag has wheels, make sure the wheels are facing up. Then stand clear of belt.',
  WelcomeScreenDescription: 'This is a sample of Welcome screen description',
  // FileName: 6.5 Passenger Selection for bag drop
  PassengerSelectionScreenPrompt:
    'Please select the passengers who are ready to drop off their bags now. Press down key to start',
  NavigateSelectPassenger:
    '{0} is {1}. Press the enter key to {2} this passenger. Press the down key to select another passenger or go to the Next button and press enter to go to the next page.',
  SelectedAndDisabledPassenger:
    '{0}, is already selected. Press the down key to select another passenger or go to the Next button and press enter to go to the next page.',
  SelectAllPassengers: 'Press enter to select all passengers',
  SelectedAllPassengers:
    'All checked-in passengers have been selected- {0}. Press down key to go to the Next button and press enter to go to the next page. ',
  PassengerSelectionScreenDescription:
    '"You will be unable to select passengers who have not yet checked in. You may Press the Select All Passengers button to select all passengers. You may also select individual passengers by pressing the down key to go to the passenger\'s name then press the enter key to select the passenger. After selecting passengers, press the down key to go to the Next button and press enter to go to the next page. "',
  // FileName: 6.6 Scan Boarding Pass
  ScanBoardingPassScreenPrompt:
    'Scan the boarding pass of all passengers on your reservation who wish to drop off their bags now. Please place each boarding pass barcode face {0} under the scanner. You can use their paper boarding pass or the barcode on their mobile devices. Leave the boarding pass in the scanner until you hear a beep then scan the next boarding pass until you are done scanning all boarding passes. Press the Help key for information on the barcode reader location. Navigate to the Next button and press enter to go to the next page.',
  PassengerAlreadyScannedBP:
    '[TBD] {0} boarding pass has been scanned. Press down key to go to the Next button and press enter to go to the next page',
  // FileName: Confirm Total Weight within Pool Limits
  ConfirmTotalWeightTitle:
    'To continue, please confirm that the total weight of all your bags is less than the maximum pool limit of {0} kg. You press the left key for No and the right key for Yes',
  ConfirmTotalWeightYesBtn:
    'Yes, press enter to confirm that the total weight of all your bags is less than the maximum pool limit allowed. This will allow you continue processing your bags.',
  ConfirmTotalWeightNoBtn:
    'No, press enter to confirm that the total weight of all your bags is greater than the maximum pool limit allowed. This will cancel your transaction.',
  // FileName: TaKePhoto.js
  TakePhotoInstruction:
    '{0}, Instructions. The camera is located above the monitor. Take off your glasses and hat, Open your eyes and close your mouth. Face front, and please wait.',
  TakePhotoInstructionError:
    '{0}, Your photo does not match your ID photo, please take off your glasses and hat, open your eyes and close your mouth, face front. You may press the down key to try again.',
  // FileName: 6.14 Enter Number of Bag Tags to Print. {0} = each passenger, all passengers
  BagEntryScreenPrompt:
    '"Please select the number of bag tags to be printed for each passenger. For this trip you currently have a total of {0} bag tags. You may add or remove individual passengers bag tags by pressing the up or down key to go to each passenger\'s name. Note that the number of bags declared at check-in is already automatically selected for each passenger. "',
  BagEntryNavigatePassenger:
    '"{0}, is selected and has {1} bags. Press left to navigate to the minus button to decrease the number of bag tags for this passenger or press the right to navigate to the plus button to increase the number of bag tags. You may press the down key to move to the next passenger or navigate to the next screen.',
  BagEntryTotalBagTags: '{0} Bag tags will be printed for this trip.',
  // FileName: 6.15 Bags Entry - Head of pool.
  BagEntryScreenHeadOfPoolPrompt:
    'Please select the number of bag tags to be printed for all passengers on this reservation. For this trip you currently have a total of {0} bag tags.  You may change this  by pressing the minus or plus button. You will not be able to add more bags than are allowed for your reservation. You may press the down key to start. ',
  BagEntryNavigateHeadOfPool:
    '{0} bag tags. Press left key to decrease the number of bag tags or press right key to increase the number of bag tags.',
  BagEntryNavigateHeadOfPoolInc: '{0} bag tags. Press right key to increase the number of bag tags.',
  BagEntryNavigateHeadOfPoolDec: '{0} bag tags. Press left key to decrease the number of bag tags',
  BagEntryScreenDescription: 'This is a sample of bag entry screen description',
  // FileName: 6.16 Print Bag Tags
  PrintBagTagsScreenPrompt:
    'Please wait while your bag tags are printing. Now printing {0} or {1} bag tags. Press help for instructions on how to place the bag tags on your bags.',
  // FileName: 6.17 Put Bag on Belt
  PutBagOnBeltScreenPrompt:
    '{0} for {1}. Place your next bag on the conveyor then step away. Ensure there is a bag tag attached to your luggage. Place the largest side down with no wheels contacting the conveyor.',
  // '{0}, Please place your bag flat down and horizontally on the bag drop belt. Ensure the bag tag is placed on top of the bag and not hanging off the bag drop belt. If your bag has wheels, make sure the wheels are facing up. Then stand clear of belt.',
  BagStatusActivated: 'Bag tag number {0} is activated',
  BagStatusNotActivated: 'Bag tag number {0} is not activated',
  // FileName: 6.18 Bag Processing
  BagProcessingScreenPrompt: 'Your bag is being analyzed. Please wait. Your bag is being dispatched.',
  // '{0} Please wait, your bag is being procesed. This may take a few seconds.',
  // FileName: 6.19 Heavy Label Prompt
  PrintHeavytagsScreenPrompt:
    '{0}, a heavy label is being printed for your bag. Please take the label from the bag tag printer and attached it on your bag facing up. Then stand clear of the belt and navigate to the done button and press enter.',
  // FileName: 6.21 Put next bag on belt
  PutNextBagOnBelt:
    '[TBD] (Add sound or indication that previous bag has been dispatched before this speech begins) {0} of {1} bags has been accepted. Now place the next bag on the belt. If you do not have any more bags, navigate to the No more bags button and press enter.',
  NoMoreBags: 'No more bags is selected. Press enter to go to the next page.',
  // FileName: 6.22 Claim Receipt Printing [TBD]
  ClaimReceiptPrintingScreenPrompt:
    '[TBD] Please wait while your bag claim receipt is being printed. When you hear a beep, take your claim receipt. (Add a sound when printing is complete.)',
  // FileName: Device Helps
  HelpBardeLocation:
    'The Barcode scanner is located {0} under the screen monitor. Scan your boarding pass or bar code face {1}',
  HelpBeltLocation: '[TBD] Belt (i.e. belt location)',
  // FileName: GenericErrors.js
  GenericErrorsScreenPrompt: ' Attention. {0}, {1}',
  // GenericErrorsScreenPrompt: '{0}, press enter to continue.',
  FatalErrorsMessage: ' {0}, Please see an agent for assistance.',
  // FileName: ClosingRemarks.js
  ClosingRemarksScreenPrompt:
    // 'Please wait while your {0} boarding passes and {1} bag tags are printed. After you receive all your documents please go to the bag drop area if you have checked bags. Otherwise go to the security gate. Your gate number is {2}, time at gate is {3}'
    'Thank you. Please go to the security gate. Your gate number is {0}, time at gate is {1}',
  EmailInput: 'Your email address is {0}. Please press enter to edit. Otherwise press down key to continue.',
  EmailInputEmpty: 'Please press enter to add email address',
  PaymentCardSwipeTitlePrompt:
    'Please insert your credit card. We will request {0} from your card issuer. We accept the following cards: American Express, Master Card and Visa. Press the Help key for information on the card reader. Press the down key to Quit.',
  HelpPaymentCard:
    'Insert your credit card with the embossed side facing up.  The leading edge should be the narrow side closest to the start of your embossed credit card number.    Insert card into the reader until you have hit a stop.  Hold the card in the inserted position for 2 seconds then slide the card out of the reader completely.',
  OverweightPaymentPrompt:
    'Overweight Bag Charges - Overweight Bag Fee : {0}, Baggage Allowance : {1}, Total Bag Weight : {2}, press the down key to go to the Continue or Quit button.',
  ContinueButtonPrompt: 'Continue. Press enter to continue to next screen.',
  EmailButtonPrompt: 'Email. Press enter to go to the get email screen.',
  SkipButtonPrompt: 'Skip. Press enter to skip this and proceed to the next screen.',
  ConfirmButtonPrompt: 'Continue. Press enter to confirm your email address',
  EnterEmailAddressPrompt: 'The current email address is {0}. Press enter to modify this email address.',
  DisplayPaymentReceiptPrompt:
    'Your payment was successful. Amount Paid {0}. Paper receipt will not be printed. Press the down key to select Email button to email yourself the payment receipt or to select Continue button to go to the next screen.',
  GetContactEmailPrompt:
    'Please provide your email address. Your payment receipt will be emailed to this address. Please use up and down key to confirm your action.',

  PleaseRemoveCard: 'Please remove your card',
  CardReadError: 'Your card could not be read. Touch OK to try again. ',
}

export default acc_en
