const acc_zh = {
  /************************************************************/
  /**            Common Component Speeches                   **/
  /************************************************************/
  // Language speaks in its language
  en: 'English',
  ru: 'русский',
  pt: 'Português',
  fr: 'Français',
  ar: 'العربية',
  sw: 'Kiswahili',
  de: 'Deutsch',
  zh: '中文',
  sv: 'Svenska',
  el: 'Eλληνικά',
  nl: 'Nederlands',
  tr: 'Türkçe',
  it: 'Italiano',
  fi: 'Suomi',
  ur: 'اردو',
  es: 'Español',
  ja: '日本語',
  ko: '한국어',
  'zh-Hant': '繁體中文',
  // common
  ButtonYes: '',
  ButtonNo: '',
  NavQuit: '',
  NavBack: '',
  NavSkip: '',
  NavNext: '',
  NavSelectLanguage: '',
  NavConfirm: '',
  NavContinue: '',
  NavDone: '',
  EndOfHelp: '',
  PlusInfant: '',
  DOB: '',
  FirstName: '',
  LastName: '',
  FlightNumber: '',
  Selected: '',
  UnSelected: '',
  ScanBoardingPass: '',
  PlaceBagOnBelt: '',
  AgentHelp: '',
  /************************************************************/
  /**                     Device Help                        **/
  /************************************************************/
  // FileName: Device Help Messages
  Barcode_Reader: '',
  Card_Reader: '',
  Passport_Reader: '',
  BagTag_Printer: '',
  Aea_BagDrop: '',
  Cuss_Accessibility_keypad: '',
  /************************************************************/
  /**            Basic Flow Accessibility Speeches           **/
  /************************************************************/
  // Headset inserted
  Application: '',
  SelectLanguage: '',
  NavKeypadHelp: '',
  NotSupportAccess: '',
  StartApp: '',
  // Timeout popup
  TimeoutPopupDescription: '',
  TimeoutPopupYes: '',
  TimeoutPopupNo: '',
  // Exit popup
  ExitPopupDescription: '',
  ExitPopupYes: '',
  ExitPopupNo: '',
  // Language popup
  LanguageSwitchPopupTitle: '',
  // MISC
  OneDynamicText: '',
  TwoDynamicText: '',
  /************************************************************/
  /**           Accessibility Speeches per Screen            **/
  /************************************************************/
  // FileName: 6.2 WELCOME SCREEN
  WelcomeScreenAllPrompt: '',
  WelcomeScreenBPPrompt: '',
  WelcomeScreenBTPrompt: '',
  WelcomeScreenDescription: '',
  // FileName: 6.5 Passenger Selection for bag drop
  PassengerSelectionScreenPrompt: '',
  NavigateSelectPassenger: '',
  SelectAllPassengers: '',
  SelectedAllPassengers: '',
  PassengerSelectionScreenDescription: '',
  // FileName: 6.6 Scan Boarding Pass
  ScanBoardingPassScreenPrompt: '',
  PassengerAlreadyScannedBP: '',
  // FileName: Confirm Total Weight within Pool Limits
  ConfirmTotalWeightTitle: '',
  ConfirmTotalWeightYesBtn: '',
  ConfirmTotalWeightNoBtn: '',
  // FileName: 6.14 Enter Number of Bag Tags to Print. {0} = each passenger, all passengers
  BagEntryScreenPrompt: '',
  BagEntryNavigatePassenger: '',
  BagEntryTotalBagTags: '',
  // FileName: 6.15 Bags Entry - Head of pool.
  BagEntryScreenHeadOfPoolPrompt: '',
  BagEntryNavigateHeadOfPool: '',
  BagEntryNavigateHeadOfPoolInc: '',
  BagEntryNavigateHeadOfPoolDec: '',
  BagEntryScreenDescription: '',
  // FileName: 6.16 Print Bag Tags
  PrintBagTagsScreenPrompt: '',
  // FileName: 6.17 Put Bag on Belt
  PutBagOnBeltScreenPrompt: '',
  // FileName: 6.18 Bag Processing
  BagProcessingScreenPrompt: '',
  // FileName: 6.19 Heavy Label Prompt
  PrintHeavytagsScreenPrompt: '',
  // FileName: 6.21 Put next bag on belt
  PutNextBagOnBelt: '',
  NoMoreBags: '',
  // FileName: 6.22 Claim Receipt Printing [TBD]
  ClaimReceiptPrintingScreenPrompt: '',
  // FileName: Device Helps
  HelpBardeLocation: '',
  HelpBeltLocation: '',
  // FileName: GenericErrors.js
  GenericErrorsScreenPrompt: '',
  FatalErrorsMessage: '',
  // FileName: ClosingRemarks.js
  ClosingRemarksScreenPrompt: '',
}

export default acc_zh
