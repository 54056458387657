const acc_es = {
  /************************************************************/
  /**            Common Component Speeches                   **/
  /************************************************************/
  // Language speaks in its language
  en: 'English',
  ru: 'русский',
  pt: 'Português',
  fr: 'Français',
  ar: 'العربية',
  sw: 'Kiswahili',
  de: 'Deutsch',
  zh: '中文',
  sv: 'Svenska',
  el: 'Eλληνικά',
  nl: 'Nederlands',
  tr: 'Türkçe',
  it: 'Italiano',
  fi: 'Suomi',
  ur: 'اردو',
  es: 'Español',
  ja: '日本語',
  ko: '한국어',
  'zh-Hant': '繁體中文',
  // KeyPad
  btnA: 'A ',
  btnB: 'B ',
  btnC: 'C ',
  btnD: 'D ',
  btnE: 'E ',
  btnF: 'F ',
  btnG: 'G ',
  btnH: 'H ',
  btnI: '<say-as interpret-as="characters">I</say-as>',
  btnJ: 'J ',
  btnK: 'K ',
  btnL: 'L ',
  btnM: 'M ',
  btnN: 'N ',
  btnO: 'O ',
  btnP: 'P ',
  btnQ: 'Q ',
  btnR: 'R ',
  btnS: 'S ',
  btnT: 'T ',
  btnU: 'U ',
  btnV: '<say-as interpret-as="characters">V</say-as>',
  btnW: 'W ',
  btnX: '<say-as interpret-as="characters">X</say-as>',
  btnY: 'i griega ',
  btnZ: 'Z ',
  btn1: '1 ',
  btn2: '2 ',
  btn3: '3 ',
  btn4: '4 ',
  btn5: '5 ',
  btn6: '6 ',
  btn7: '7 ',
  btn8: '8 ',
  btn9: '9 ',
  btn0: '0 ',
  // special keys
  btnBS: 'RETROCESO. Oprima para borrar 1 caracter.',
  btnAT: 'la arroba',
  btnDT: 'el punto',
  currentInputValue: 'Usted ha ingresado <say-as interpret-as="characters">{0}</say-as>',

  // common
  UP: 'ARRIBA',
  DOWN: 'ABAJO',
  ButtonYes: 'Sí',
  ButtonNo: 'No',
  ButtonOk: 'Aceptar',
  ButtonRetry: 'Rever',
  ButtonAccept: 'Aceptar',
  ButtonDecline: 'Rechazar',
  NavQuit: 'Salir de la aplicación',
  NavBack: 'Volver al paso anterior',
  NavSkip: 'Saltar',
  NavNext: 'Siguiente',
  NavSelectLanguage: 'Seleccione el idioma',
  NavConfirm: 'Confirmar',
  NavContinue: 'Continuar',
  NavDone: 'Hecho',
  EndOfHelp: 'final de ayuda',
  PlusInfant: 'Más bebé',
  DOB: 'Fecha de nacimiento ',
  FirstName: 'Nombre',
  LastName: 'Apellido',
  FlightNumber: 'número del vuelo',
  Selected: 'seleccionado ',
  UnSelected: 'no seleccionado ',
  ScanBoardingPass: 'Escanee su tarjeta de embarque',
  PlaceBagOnBelt: 'Coloque su maleta en el cinturón',
  AgentHelp: 'Consulte a un agente para obtener ayuda adicional.',
  /************************************************************/
  /**            Device Help (set by application)            **/
  /************************************************************/
  // FileName: Device Help Messages
  Barcode_Reader:
    'El escáner de código de barras se encuentra debajo del monitor de pantalla. Escanee su tarjeta de embarque o su código ' +
    'de barras {0}',
  Card_Reader: 'Este es un mensaje de ejemplo para la Ayuda del lector de tarjetas. ',
  Passport_Reader: 'Este es un mensaje de ejemplo para la Ayuda del lector de pasaportes. ',
  BagTag_Printer: 'Este es un mensaje de ejemplo para la Ayuda de la impresora de etiquetas de bolsa. ',
  Aea_BagDrop: 'Este es un mensaje de ejemplo para la Ayuda de entrega de maletas AEA. ',
  Cuss_Accessibility_keypad: 'Este es un mensaje de ejemplo para la Ayuda del teclado de accesibilidad. ',
  /************************************************************/
  /**            Basic Flow Accessibility Speeches           **/
  /************************************************************/
  // Headset inserted
  Application:
    'Bienvenido al {0} Bag Drop quiosco. Puede navegar a la Ayuda presionando la tecla Intro dos veces en cualquier momento ' +
    'durante esta transacción para repetir el último mensaje. O presione la tecla Intro tres veces para acceder a la ayuda ' +
    'del dispositivo. También puede salir de la transacción usando las teclas arriba y abajo para navegar a Salir. Siempre ' +
    'presione enter una vez para hacer su selección. ',
  // 'Welcome to {0} Bag Drop Kiosk. You may navigate to Help by pressing the enter key twice at anytime during this transaction to repeat the last message. Or press the enter key three times to access the device help. You may also quit the transaction by using the up and down keys to navigate to Quit. Always press enter once to make your selection. ',
  SelectLanguage: 'Presione hacia arriba hasta que escuche {0} y presione Intro para cambiar de idioma. ',
  NavKeypadHelp:
    'Este quiosco tiene un teclado de navegación para controlar la aplicación. Presione hacia abajo para obtener más instrucciones',
  NotSupportAccessibility: 'La accesibilidad no es compatible en este momento',
  AccessibilityEnabled: 'Accesibilidad habilitada',
  AccessibilityDisabled: 'Accesibilidad deshabilitada',
  TranslationUnavailable: 'La traducción de idioma no está disponible para la identificación del texto',
  StartApp: 'Presione enter para iniciar la aplicación',
  // Timeout popup
  TimeoutPopupDescription:
    'La pantalla actual está a punto de vencer. ¿Necesita más tiempo? Utilice la tecla arriba y abajo para confirmar su ' +
    'acción.',
  TimeoutPopupYes: 'Sí, presione enter para volver al paso anterior',
  TimeoutPopupNo: 'No, presione enter para finalizar la transacción actual',
  // Exit popup
  ExitPopupDescription:
    "\"Usted ha elegido cancelar su transacción. ¿Está seguro? Utilice la tecla arriba y abajo '+' para confirmar su " +
    'acción".',
  ExitPopupYes: 'Sí, presione enter para volver al paso anterior',
  ExitPopupNo: 'No, presione enter para finalizar la transacción actual',
  // Language popup
  LanguageSwitchPopupTitle: 'Por favor, seleccione su idioma',
  // MISC
  OneDynamicText: '{0}',
  TwoDynamicText: '{0}, {1}',
  ThreeDynamicText: '{0}, {1}, {2}',
  /************************************************************/
  /**           Accessibility Speeches per Screen            **/
  /************************************************************/
  // FileName: 6.2 WELCOME SCREEN
  WelcomeScreenPrompt:
    'Coloque su maleta más pesada en el transportador. Asegúrese de que haya una etiqueta de maleta pegada a su equipaje. El ' +
    'transportador está para su {0}. Su superficie superior está a 0 punto 2 metros u ocho pulgadas por encima del piso. ' +
    'Coloque el lado más grande hacia abajo sin que las ruedas entren en contacto con el transportador. De un paso atrás del ' +
    'transportador una vez que se coloque la maleta.',
  WelcomeScreenAllPrompt:
    'Utilice una de las siguientes opciones para procesar su maleta. {0} o {1}. Para escanear su tarjeta de embarque: coloque ' +
    'la cara del código de barras de su tarjeta de embarque {2} debajo del escáner. Puede usar su tarjeta de embarque de ' +
    'papel o el código de barras en su teléfono móvil. Deje el código de barras ahí hasta que escuche el siguiente mensaje. ' +
    'Pulse la tecla Ayuda para obtener información sobre la ubicación del lector de códigos de barras.',
  WelcomeScreenBPPrompt:
    'Coloque el código de barras de su tarjeta de embarque boca {0} debajo del escáner. Puede usar su tarjeta de embarque de ' +
    'papel o el código de barras en su teléfono móvil. Deje el código de barras allí hasta que escuche el siguiente mensaje. ' +
    'Presione la tecla de Ayuda para obtener información sobre la ubicación del lector de código de barras.',
  WelcomeScreenBTPrompt:
    'Coloque su maleta plana hacia abajo y horizontalmente en el cinturón entrega de maletas. Asegúrese de que la etiqueta de ' +
    'la maleta se coloque en la parte superior y no cuelgue por fuera de la banda de entrega de maletas. Si su maleta tiene ' +
    'ruedas, asegúrese de que las ruedas estén hacia arriba. Entonces aléjese del cinturón.',
  WelcomeScreenDescription: 'Esta es una muestra de la descripción de la Pantalla de bienvenida',
  // FileName: 6.5 Passenger Selection for bag drop
  PassengerSelectionScreenPrompt:
    'Por favor, seleccione los pasajeros que están listos para dejar sus maletas ahora. Presione la tecla abajo para iniciar',
  NavigateSelectPassenger:
    '{0} is {1}. Presione Entrar para {2} este pasajero. Presione la tecla abajo para seleccionar otro pasajero o vaya al ' +
    'botón Siguiente y presione Entrar para ir a la página siguiente.',
  SelectedAndDisabledPassenger:
    '{0}, ya está seleccionado. Presione la tecla abajo para seleccionar otro pasajero o vaya al botón Siguiente y presione ' +
    'Entrar para ir a la página siguiente.',
  SelectAllPassengers: 'Presione enter para seleccionar todos los pasajeros',
  SelectedAllPassengers:
    'Todos los pasajeros registrados han sido seleccionados- {0}. Presione la tecla abajo para ir al botón Siguiente y ' +
    'presione Entrar para ir a la página siguiente. ',
  PassengerSelectionScreenDescription:
    '"No podrá seleccionar pasajeros que aún no se han registrado. Puede presionar el botón Seleccionar todos los pasajeros ' +
    'para seleccionar todos los pasajeros. También puede seleccionar pasajeros individuales presionando la tecla abajo para ' +
    'ir al nombre del pasajero y luego presione Entrar para seleccionar el pasajero. Después de seleccionar pasajeros, ' +
    'presione la tecla abajo para ir al botón Siguiente y presione Entrar para ir a la página siguiente. "',
  // FileName: 6.6 Scan Boarding Pass
  ScanBoardingPassScreenPrompt:
    'Escanee la tarjeta de embarque de todos los pasajeros en su reserva que deseen dejar sus maletas ahora. Coloque cada ' +
    'cara del código de barras de la tarjeta de embarque {0} debajo del escáner. Puede usar su tarjeta de embarque de papel o ' +
    'el código de barras en sus dispositivos móviles. Deje la tarjeta de embarque en el escáner hasta que escuche un pitido y ' +
    'luego escanee la siguiente tarjeta de embarque hasta que haya terminado de escanear todas las tarjetas de embarque. ' +
    'Pulse la tecla Ayuda para obtener información sobre la ubicación del lector de códigos de barras. Navegue hasta el botón ' +
    'Siguiente y presione Entrar para ir a la página siguiente.',
  PassengerAlreadyScannedBP:
    '[TBD] {0} tarjeta de embarque ha sido escaneada. Presione la tecla abajo para ir al botón Siguiente y presione Entrar ' +
    'para ir a la página siguiente',
  // FileName: Confirm Total Weight within Pool Limits
  ConfirmTotalWeightTitle:
    'Para continuar, confirme que el peso total de todas sus maletas es inferior al límite máximo del grupo, de {0} kg. Pulsa ' +
    'la tecla izquierda para No y la tecla derecha para Sí',
  ConfirmTotalWeightYesBtn:
    'Sí, presione Entrar para confirmar que el peso total de todas sus maletas es menor que el límite máximo del grupo ' +
    'permitido. Esto le permitirá continuar procesando sus maletas.',
  ConfirmTotalWeightNoBtn:
    'No, presione Entrar para confirmar que el peso total de todas sus maletas es menor que el límite máximo del grupo ' +
    'permitido. Esto cancelará su transacción.',
  // FileName: TaKePhoto.js
  TakePhotoInstruction:
    '{0}, Instrucciones. La cámara se encuentra encima del monitor. Quítate las gafas y el sombrero, abre los ojos y cierra ' +
    'la boca. Cara hacia adelante y por favor espere.',
  TakePhotoInstructionError:
    'Su foto no coincide con su foto de identificación, quítese los anteojos y el sombrero, abra los ojos y cierre la boca, mirando hacia el frente. Puede presionar la tecla hacia abajo para volver a intentarlo.',
  // FileName: 6.14 Enter Number of Bag Tags to Print. {0} = each passenger, all passengers
  BagEntryScreenPrompt:
    '"Por favor, seleccione el número de etiquetas de maletas que se imprimirán para cada pasajero. Para este viaje, ' +
    'actualmente tienes un total de {0} etiquetas de maletas. Puede agregar o quitar etiquetas individuales de las maletas de ' +
    'pasajeros presionando la tecla arriba o abajo para ir al nombre de cada pasajero. Tenga en cuenta que el número de ' +
    'maletas declaradas en el momento del registro se selecciona automáticamente para cada pasajero. "',
  BagEntryNavigatePassenger:
    '"{0}, está seleccionado y tiene {1} maletas. Pulse a la izquierda para navegar hasta el botón menos para reducir el ' +
    'número de etiquetas de maletas para este pasajero o pulse a la derecha para navegar hasta el botón más para aumentar el ' +
    'número de etiquetas de maletas. Puede presionar la tecla abajo para moverse al siguiente pasajero o navegar a la ' +
    'pantalla siguiente.',
  BagEntryTotalBagTags: 'Se imprimirán {0} etiquetas de maletas para este viaje.',
  // FileName: 6.15 Bags Entry - Head of pool.
  BagEntryScreenHeadOfPoolPrompt:
    'Por favor, seleccione el número de etiquetas de maletas que se imprimirán para todos los pasajeros en esta reservación. ' +
    'Para este viaje, actualmente tienes un total de {0} etiquetas de maletas. Puede cambiar esto presionando el botón menos ' +
    'o más. No podrá agregar más maletas de las permitidas para su reservación. Puede presionar la tecla abajo para iniciar. ',
  BagEntryNavigateHeadOfPool:
    '{0} etiquetas de equipaje. Pulse la tecla izquierda para reducir el número de etiquetas de maletas o pulse la tecla ' +
    'derecha para aumentar el número de etiquetas de maletas.',
  BagEntryNavigateHeadOfPoolInc:
    '{0} etiquetas de equipaje. Pulse la tecla derecha para aumentar el número de etiquetas de maletas.',
  BagEntryNavigateHeadOfPoolDec:
    '{0} etiquetas de equipaje. Pulse la tecla izquierda para reducir el número de etiquetas de maletas',
  BagEntryScreenDescription: 'Esta es una muestra de la descripción de la pantalla de entrada de maletas',
  // FileName: 6.16 Print Bag Tags
  PrintBagTagsScreenPrompt:
    'Espere mientras se imprimen las etiquetas de su maleta. Ahora imprimiendo {0} o {1} etiquetas de equipaje. Pulse Ayuda ' +
    'para obtener instrucciones sobre cómo colocar las etiquetas de equipaje en sus maletas.',
  // FileName: 6.17 Put Bag on Belt
  PutBagOnBeltScreenPrompt:
    '{0} para {1}. Coloque su próxima maleta en el transportador y luego aléjese. Asegúrese de que haya una etiqueta de ' +
    'maleta pegada a su equipaje. Coloque el lado más grande hacia abajo sin que las ruedas entren en contacto con el ' +
    'transportador.',
  // '{0}, Please place your bag flat down and horizontally on the bag drop belt. Ensure the bag tag is placed on top of the bag and not hanging off the bag drop belt. If your bag has wheels, make sure the wheels are facing up. Then stand clear of belt.',
  BagStatusActivated: 'El número de etiqueta de equipaje {0} está activado',
  BagStatusNotActivated: 'El número de etiqueta de equipaje {0} no está activado',
  // FileName: 6.18 Bag Processing
  BagProcessingScreenPrompt: 'Su maleta está siendo analizada. Espere por favor. Su maleta está siendo despachada.',
  // '{0} Please wait, your bag is being procesed. This may take a few seconds.',
  // FileName: 6.19 Heavy Label Prompt
  PrintHeavytagsScreenPrompt:
    '{0}, se está imprimiendo una etiqueta pesada para su maleta. Tome la etiqueta de la impresora de etiquetas de maletas y ' +
    'colóquela en su maleta cara hacia arriba. Luego aléjese del cinturón y navegue hasta el botón Hecho y presione Entrar.',
  // FileName: 6.21 Put next bag on belt
  PutNextBagOnBelt:
    '[TBD] (Agregar sonido o indicación de que la bolsa anterior ha sido enviada antes de que comience este discurso) {0} de ' +
    '{1} maletas ha(n) sido aceptada(s). Ahora coloque la próxima maleta en el cinturón. Si no tiene más maletas, navegue ' +
    'hasta el botón No más maletas y presione Entrar.',
  NoMoreBags: 'No hay más maletas seleccionadas. Presione Entrar para ir a la página siguiente.',
  // FileName: 6.22 Claim Receipt Printing [TBD]
  ClaimReceiptPrintingScreenPrompt:
    '[TBD] Espere mientras se imprime el recibo de su maleta. Cuando escuche un pitido, tome su recibo de reclamo. (Añadir un ' +
    'sonido cuando se complete la impresión).',
  // FileName: Device Helps
  HelpBardeLocation:
    'El escáner de código de barras se encuentra {0} debajo del monitor de pantalla. Escanee su tarjeta de embarque o su ' +
    'código de barras {1}',
  HelpBeltLocation: '[TBD] Correa (es decir, ubicación de la correa)',
  // FileName: GenericErrors.js
  GenericErrorsScreenPrompt: 'Atención. {0}, {1}',
  // GenericErrorsScreenPrompt: '{0}, press enter to continue.',
  FatalErrorsMessage: '{0}, por favor, consulte a un agente para obtener ayuda.',
  // FileName: ClosingRemarks.js
  ClosingRemarksScreenPrompt:
    'Gracias. Siga a la puerta de seguridad. Su número de Puerta de embarque es {0}, tinene que estar en la Puerta de embarque al {1}',
  PaymentCardSwipeTitlePrompt:
    'Por favor inserte su tarjeta de crédito. Solicitaremos {0} al emisor de su tarjeta. Aceptamos las siguientes tarjetas: American Express, Master Card y Visa. Oprima la tecla de Ayuda para obtener información sobre el lector de tarjetas. Oprima  la tecla hacia abajo para salir.',
  HelpPaymentCard:
    'Inserte su tarjeta de crédito con el lado en relieve hacia arriba. El borde de ataque debe ser el lado estrecho más cercano al comienzo de su número de tarjeta de crédito en relieve. Inserte la tarjeta en el lector hasta que se detenga. Sostenga la tarjeta en la posición insertada durante 2 segundos y luego deslice la tarjeta fuera del lector por completo.',
  OverweightPaymentPrompt:
    'Cargos por equipaje pesado – Tarifa de equipaje pesado: {0}, Equipaje permitido: {1},  Peso total del equipaje: {2}, Oprima la tecla hacia abajo para ir a Continuar o Salir.',
  ContinueButtonPrompt: 'Continuar. Oprima Ingresar para contiuar al siguiente pantalla',
  EmailButtonPrompt: 'Email. Oprima Ingresar para ir a la pantalla, obtener email.',
  SkipButtonPrompt: 'Omitir. Oprima Ingresar para omitir esta y pasar a la siguiente pantalla.',
  ConfirmButtonPrompt: 'Continuar. Oprima Ingresar para confirmer su email.',
  EnterEmailAddressPrompt: 'El email actual es {0}. Oprima Ingresar para modificar el email.',
  EmailInputEmpty: 'Presione Ingresar para agregar email.',
  DisplayPaymentReceiptPrompt:
    'Su pago ha sido aprobado exitosamente. El monto pagado {0}. El recibo en papel no se imprimirá. Oprima la tecla de abajo para seleccionar el botón de email a enviarse por email el recibo de page o selecione continuar para ir al siguiente pantalla.',
  GetContactEmailPrompt:
    'Por favor proporcione su email. Su recibo del pago será enviado a esta dirección. Por favor use la tecla de arriba y abajo para confirmer su action.',

  PleaseRemoveCard: 'Por favor retire su tarjeta.',
  CardReadError: 'No se pudo leer su tarjeta. Presione Aceptar para intentarlo nuevamente. ',
}

export default acc_es
