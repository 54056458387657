import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { getDeviceManager } from 'main'
import { replacer, populateItineraryInfo, navigate } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import { FALSE } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { Footer } from '../footer'

import { goToLocalError } from 'utils/helper'
import useMedia from 'hooks/useMedia'
import {
  PageHeader,
  PageContent,
  PageSubContent,
  PageFooter,
  PageTitle,
  PageSubTitle,
  DynamicImage
} from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useStateRef from 'hooks/useStateRef'

const PrintReceipt = (props) => {
  const intl = useIntl()
  const atbPrinter = getDeviceManager().getDevice(deviceIds.ATB_PRINTER)
  const ticketPectab = useSelector((state) => state.responses.bagtagResources)
  const ticket = useSelector((state) => state.responses.paxDocuments)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const bagsDetail = useSelector((state) => state.localData.BagsDetail)
  const [currentTicket, setCurrentTicket, currentTicketRef] = useStateRef(0)
  if (atbPrinter) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'set printer callback')
    atbPrinter.OnDeviceEvent = atbPrinterCallback
  }
  const ticketsRef = useRef()
  if (ticket) {
    ticketsRef.current = ticket.slice()
  }
  const [totalWeight, setTotalWeight] = useState(0)
  const [numOfBags, setNumOfBags] = useState(0)

  const atbPrinterCallback = (event) => {
    let isError = false
    //this.timeoutManger.resetTimer()
    appLog(TraceLevels.LOG_EXT_TRACE, 'atbPrinterCallback ... event : ' + JSON.stringify(event))
    switch (event.key) {
      case 'pectabLoadingComplete':
        console.log('template is loaded, ready to print document...')
        setCurrentTicket(1)
        atbPrinter.printArray(ticketsRef.current[currentTicketRef.current - 1], 10000)
        break
      case 'ticketPrintingComplete':
        console.log('atbPrinterCallback() : ticketPrintingComplete ... ')
        logEvent('PrintingReceipt: 1')
        navigate('ClosingRemarks', 8)
        break
      case 'pectabFailed':
        appLog(TraceLevels.LOG_EXT_TRACE, 'pectab failed.')
        isError = true        
        break
      case 'ticketFailed':
        appLog(TraceLevels.LOG_EXT_TRACE, 'ticket failed. ')
        isError = true
        logEvent('PrintingReceipt: Failed')
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange. ' + event.value.toString())
        if (event.value.length === 2) {
          if (event.value[1]) {
            // true means fatal error
            appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange ... atb printer failed. ')
            isError = true
          }
        }
        break
      default:
    }
    if (isError) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'print receipt Failed. ')
      goToLocalError('PrintReceipt', ErrCodes.RECEIPT_PRINT_ERROR, 'PrintError', 'ClosingRemarks', null, 'ErrorPrint')
    }
  }

  const handleActions = (e) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(PrintReceipt.js): handleActions() ... ' + e.currentTarget.id)
    // TEST CODE
    if (e.currentTarget.id === 'testButtonPrinted') {
      let ev = {
        key: 'pectabLoadingComplete',
        value: 0,
      }
      atbPrinterCallback(ev)
      setTimeout(() => {
        let ev = {
          key: 'ticketPrintingComplete',
          value: 0,
        }
        atbPrinterCallback(ev)
      }, 1000)
    } else if (e.currentTarget.id === 'testButtonError') {
      let ev = {
        // key: 'statusChange', //bagtagFailed or bagtagFailed
        // value: [203, true]
        key: 'ticketFailed', //bagtagFailed or bagtagFailed
        value: null,
      }
      return atbPrinterCallback(ev)
    }
    // end OF TEST CODE
  }

  useEffect(() => {
    atbPrinter.loadPECTABArray(ticketPectab, FALSE)
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (PrintReceipt.js) componentDidMount() - loadPECTABArray')
    //  set style for size
    return () => {
      if (atbPrinter) {
        atbPrinter.OnDeviceEvent = null
      }
    }
  }, [])

  useEffect(() => {
    if (bagsDetail) {
      bagsDetail.forEach((bagData) => {
        let weight = parseFloat(bagData.measurements[0].replace('KG', ''))
        setTotalWeight(totalWeight + weight)
        setNumOfBags(numOfBags + 1)
      })
    }
  }, [bagsDetail])

  const testData = [
    {
      id: 'DESC',
      text: 'Normal Cases:',
      group: 0,
    },
    {
      id: 'testButtonPrinted',
      text: 'Print',
      group: 0,
      handler: handleActions,
    },
    {
      id: 'DESC',
      text: 'Error Cases:',
      group: 1,
    },
    {
      id: 'testButtonError',
      text: 'print error',
      group: 1,
      handler: handleActions,
    },
  ]
  const { formatMessage } = intl
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const header = <PageHeader alignItems="center">{formatMessage(messages.PrintDocumentsTitle)}</PageHeader>
  const textSection = (
    <>
      <PageTitle justifyContent={textAlign}>
        {formatMessage(messages.PrintDocuments, {
          numberOfBags: numOfBags,
          totalWeight: totalWeight,
        })}
      </PageTitle>
    </>
  )
  const animationSize = useMedia(null, [420, 350, 420], 420)
  const animationSection = (
    <DynamicImage
      imageName={`animations/embross/${sbdModel}/docPrint.gif`}
      cssName={'animation'}
      width={animationSize}
      height={animationSize}
    />
  )
  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isLangRequired={false}
        isConfirmRequired={false}
        testData={testData}
      />
    </>
  )
  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = {
    landscapeMode: '50%',
    portraitMode: '90%',
  }

  const { UIDisplay } = useUIBase(
    {
      header,
      topSection: textSection,
      bottomSection: animationSection,
      footer,
    },
    {
      contentWidth: contentWidth,
      itineraryHeights: ['200px', '120px', '200px'],
    }
  )

  return <>{UIDisplay}</>
}

PrintReceipt.propTypes = {}

export default PrintReceipt
