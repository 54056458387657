import React, { useEffect, useState } from 'react'
import { Button, Popup } from 'components'
import './testButtons.scss'

// data format: array of following objects
//groupId: string
//grounpDesc: string
//
//{
//  Id: 'string',
//  text: 'string',
//  handler: 'function',
//  cssName: 'string'
//}

/* interface TestButtonsProps {
  data: TestButtonData[]
  showPopup: boolean
  hotKey?: string
  showTestbutton?: boolean
} */

const TestButtons = ({ data, showPopup, showTestButton = false, hotKey = 't' }) => {
  let testButtons = []
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const keydownHandler = (e) => {
      if (e.ctrlKey && e.altKey && e.key === hotKey) {
        setVisible(!visible)
      }
    }
    if (showTestButton) {
      document.addEventListener('keydown', keydownHandler)
      return () => {
        document.removeEventListener('keydown', keydownHandler)
      }
    }
  }, [visible])

  if (data && showTestButton) {
    data.forEach((button) => {
      testButtons.push(
        button.handler ? (
          <Button
            key={button.id}
            id={button.id}
            justifyContent="center"
            onClick={(e) => {
              setVisible(false)
              button.handler(e)
            }}
          >
            {button.text}
          </Button>
        ) : (
          button.text
        )
      )
    })
    if (showPopup) {
      return (
        <>
          {showTestButton ? (
            <div className="test-buttons">
              <Button
                id="testButton"
                height="75px"
                margin="0 0 0 20px"
                justifyContent="center"
                cssName={'btn-popup-up'}
                onClick={() => {
                  setVisible(true)
                }}
              >
                Test
              </Button>
            </div>
          ) : null}
          <Popup visible={visible}>
            <div className="popup-body">
              <div className="test-button-container">
                <div className="test-button-buttons">{testButtons}</div>
                <div className="test-button-function">
                  <Button
                    id="hideButton"
                    justifyContent="center"
                    cssName={'btn-popup-up'}
                    onClick={() => {
                      setVisible(false)
                    }}
                  >
                    Hide
                  </Button>
                </div>
              </div>
            </div>
          </Popup>
        </>
      )
    } else {
      return <div className="test-buttons">{testButtons}</div>
    }
  } else {
    return <></>
  }
}
TestButtons.defaultProps = {
  showPopup: false
}
export default TestButtons
