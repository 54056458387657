export const SourceType = {
  FETCH: 'FETCH',
  LOCAL: 'LOCAL',
}

export const ErrType = {
  GENERAL: 'GENERAL',
  HARDWARE: 'HARDWARE',
  BAGGAGE: 'BAGGAGE',
  HOST: 'HOST',
  FATAL: 'FATAL',
}

export const ErrCodes = {
  APP_ERROR: 'SBDERR_APP_ERROR',
  ATB_PRINTER_ERROR: 'ATB_PRINTER_ERROR',
  BAGTAG_PRINT_ERROR: 'SBDERR_BAGTAG_PRINT_ERROR',
  BAG_JAMMED: 'SBDERR_BAG_JAMMED',
  BAG_LONG: 'SBDERR_BAG_LONG',
  BAG_LONG_ADDBAG: 'SBDERR_BAG_LONG_ADDBAG',
  BAG_MAX_WEIGHT: 'SBDERR_BAG_MAX_WEIGHT',
  BAG_MAX_WEIGHT_ADDBAG: 'SBDERR_BAG_MAX_WEIGHT_ADDBAG',
  BAG_MISSING: 'SBDERR_BAG_MISSING',
  BAG_NOSCAN: 'SBDERR_BAG_NOSCAN',
  BAG_OVERSIZED: 'SBDERR_OVERSIZED',
  BAG_OVERWEIGHT: 'SBDERR_OVERWEIGHT',
  OVERWEIGHT_AGENT: 'SBDERR_OVERWEIGHT_AGENT',
  OVERWEIGHT_LAST: 'SBDERR_OVERWEIGHT_LAST',
  BAG_RATIO_TOO_HIGH: 'SBDERR_BAG_RATIO_TOO_HIGH',
  BAG_REPOSITION_RETRIES_EXCEEDED: 'SBDERR_BAG_REPOSITION_RETRIES_EXCEEDED',
  BAG_SHORT: 'SBDERR_BAG_SHORT',
  BAG_TOO_TALL: 'SBDERR_BAG_TOO_TALL',
  BAG_UNABLE_TO_MEASURE: 'SBDERR_BAG_UNABLE_TO_MEASURE',
  BAG_UNABLE_TO_WEIGHT: 'SBDERR_BAG_UNABLE_TO_WEIGHT',
  BAG_UNDERHEIGHT: 'SBDERR_BAG_UNDERHEIGHT',
  BAG_UNDERHEIGHT_ADDBAG: 'SBDERR_BAG_UNDERHEIGHT_ADDBAG',
  BAG_UNDERHEIGHT_USEBIN: 'SBDERR_BAG_UNDERHEIGHT_USEBIN',
  BAG_UNDERWEIGHT: 'SBDERR_BAG_UNDERWEIGHT',
  BAG_UNDERWEIGHT_ADDBAG: 'SBDERR_BAG_UNDERWEIGHT_ADDBAG',
  BAG_UNDERWEIGHT_USEBIN: 'SBDERR_BAG_UNDERWEIGHT_USEBIN',
  BAG_REQUIRE_USEBIN: 'SBDERR_BAG_REQUIRE_USEBIN',
  BAG_UNSTABLE: 'SBDERR_BAG_UNSTABLE',
  BAG_HEAVY_ATTACHLABEL: 'BAG_HEAVY_ATTACHLABEL',
  BARCODE_EMPTY: 'SBDERR_BARCODE_EMPTY',
  BARCODE_ERROR: 'SBDERR_BARCODE_ERROR',
  BARCODE_NOT_VALID: 'SBDERR_BARCODE_NOT_VALID',
  BARCODE_RETRIES_EXCEEDED: 'SBDERR_BARCODE_RETRIES_EXCEEDED',
  BARCODE_UNREADABLE: 'SBDERR_BARCODE_UNREADABLE',
  BHS_ERROR: 'SBDERR_BHS_ERROR',
  BP_DUPLICATE_SCAN: 'SBDERR_BP_DUPLICATE_SCAN',
  BP_NOT_ON_PNR: 'SBDERR_BP_NOT_ON_PNR',
  CONVEYOR_BAG_ERROR: 'SBDERR_CONVEYOR_BAG_ERROR',
  CONVEYOR_ERROR: 'SBDERR_CONVEYOR_ERROR',
  CONVEYOR_ESTOP: 'SBDERR_CONVEYOR_ESTOP',
  CONVEYOR_INTRUSION: 'SBDERR_CONVEYOR_INTRUSION',
  CONVEYOR_OFFLINE: 'SBDERR_CONVEYOR_OFFLINE',
  CUSS_TIMEOUT: 'SBDERR_CUSS_TIMEOUT',
  DEVICE_ERROR: 'SBDERR_DEVICE_ERROR',
  ETS_COMM_ERROR: 'SBDERR_ETS_COMM_ERROR',
  ETS_MESSAGE_ERROR: 'SBDERR_ETS_MESSAGE_ERROR',
  ETS_OFFLINE: 'SBDERR_ETS_OFFLINE',
  ETS_TIMEOUT: 'SBDERR_ETS_TIMEOUT',
  MULTIPLE_BAGS: 'SBDERR_MULTIPLE_BAGS',
  MULTIPLE_TAGS: 'SBDERR_MULTIPLE_TAGS',
  NOT_ELIGIBLE_TO_ACCEPT: 'SBDERR_NOT_ELIGIBLE_TO_ACCEPT',
  NO_BAGS: 'SBDERR_NO_BAG',
  PRINTER_JAM: 'SBDERR_PRINTER_JAM',
  PRINTER_OFFLINE: 'SBDERR_PRINTER_OFFLINE',
  PRINTER_OUT: 'SBDERR_PRINTER_OUT',
  RECEIPT_PRINT_ERROR: 'SBDERR_RECEIPT_PRINT_ERROR',
  RECORD_NOT_FOUND: 'SBDERR_RECORD_NOT_FOUND',
  SBD_BAG_FLOW_NOT_ALLOWED: 'SBDERR_SBD_BAG_FLOW_NOT_ALLOWED',
  SCREEN_TIMEOUT: 'SBDERR_SCREEN_TIMEOUT',
  SEE_AGENT_GENERAL: 'SBDERR_SEE_AGENT_GENERAL',
  TAG_ALREADY_ACTIVE: 'SBDERR_TAG_ALREADY_ACTIVE',
  TAG_NOT_FOUND_IN_PNR: 'SBDERR_TAG_NOT_FOUND_IN_PNR',
  TAG_SCANNED_BP_NOT_SCANNED: 'SBDERR_TAG_SCANNED_BP_NOT_SCANNED',
  TOO_EARLY_BAGGAGE: 'SBDERR_TOO_EARLY',
  TOO_LATE_BAGGAGE: 'SBDERR_TOO_LATE',
  TRANSACTION_FAILED: 'SBDERR_TRANSACTION_FAILED',
  TRANSACTION_TIMEOUT: 'SBDERR_TRANSACTION_TIMEOUT',
  UNABLE_TO_WEIGHT: 'SBDERR_UNABLE_TO_WEIGHT',
  USER_QUIT: 'SBDERR_USER_QUIT',
  WRONG_AIRPORT: 'SBDERR_WRONG_AIRPORT',
  ERR_STATION: 'ERR_STATION',
  MAX_POOL_WEIGHT: 'SBDERR_MAX_POOL_WEIGHT',
  SBDERR_HEAVYTAG: 'SBDERR_HEAVYTAG',
  ERR_AGENT_PNR_NOT_FOUND: 'ERR_AGENT_PNR_NOT_FOUND',
  OVERSIZED: 'SBDERR_OVERSIZED',
  NOT_ELIGIBLE_TO_ACCEPT: 'SBDERR_NOT_ELIGIBLE_TO_ACCEPT',
  OUT_OF_BOUNDS: 'SBDERR_OUT_OF_BOUNDS',

  // PASSPORT READER ERRORS
  PASSPORT_ERROR: 'SBDERR_PASSPORT_ERROR',
  PASSPORT_UNREADABLE: 'SBDERR_PASSPORT_UNREADABLE',
  PASSPORT_EMPTY: 'SBDERR_PASSPORT_EMPTY',
  PASSPORT_NOT_VALID: 'SBDERR_PASSPORT_NOT_VALID',
  PASSPORT_EDATA_NOT_COMPLETE: 'SBDERR_PASSPORT_EDATA_NOT_COMPLETE',
  DOCUMENT_NOT_SUPPORTED: 'SBDERR_DOCUMENT_NOT_SUPPORTED',
  TRAVEL_DOCUMENT_DUPLICATE: 'SBDERR_TRAVEL_DOCUMENT_DUPLICATE',
  PASSPORT_RETRIES_EXCEEDED: 'SBDERR_PASSPORT_RETRIES_EXCEEDED',
  ERR_PASSPORT_INVALID_RETRY: 'ERR_PASSPORT_INVALID_RETRY',
  ERR_PASSPORT_INVALID: 'ERR_INVALID_TRAVELDOC',
  ERR_PASSPORT_DOBMATCH: 'ERR_PASSPORT_DOBMATCH',
  ERR_PASSPORT_BADNAME: 'ERR_PASSPORT_BADNAME',

  // CAMERA ERRORS
  CAMERA_ERROR: 'SBDERR_CAMERA_ERROR',
  PHOTO_DATA_EMPTY: 'SBDERR_PHOTO_DATA_EMPTY',
  PHOTO_POOR_QUALITY: 'SBDERR_PHOTO_POOR_QUALITY',
  PHOTO_RETRIES_EXCEEDED: 'SBDERR_PHOTO_RETRIES_EXCEEDED',
  // transfer error
  TRANSFER_NOT_SUPPORT: 'TRANSFER_NOT_SUPPORT',
  TARGET_APP_NOT_AVAILABLE: 'TARGET_APP_NOT_AVAILABLE',
  // generic unhandled error
  UNHANDLED_ERROR_FRONT_END: 'UNHANDLED_ERROR_FRONT_END',

  DIFFERENT_SESSION: 'DIFFERENT_SESSION',
}

export const ContinueErrCodes = [ErrCodes.BARCODE_RETRIES_EXCEEDED]

export const ErrorDictionary = [
  {
    errID: '006',
    errCode: ErrCodes.ETS_OFFLINE,
    msg: 'Host is unavailable',
    errType: ErrType.HOST,
    retry: false,
    fatal: true,
  },
  {
    errID: '100',
    errCode: ErrCodes.TRANSACTION_TIMEOUT,
    msg: 'Transaction timeout',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: true,
  },
  {
    errID: '102',
    errCode: ErrCodes.SEE_AGENT_GENERAL,
    msg: 'Any error not listed',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '103',
    errCode: ErrCodes.TRANSACTION_FAILED,
    msg: 'Transaction failed',
    errType: ErrType.FATAL,
    retry: false,
    fatal: true,
  },
  {
    errID: '501',
    errCode: ErrCodes.DEVICE_ERROR,
    msg: 'Device error',
    errType: ErrType.FATAL,
    retry: false,
    fatal: true,
  },
  {
    errID: '503',
    errCode: ErrCodes.RECEIPT_PRINT_ERROR,
    msg: 'Receipt print error',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: true,
  },
  {
    errID: '504',
    errCode: ErrCodes.BAGTAG_PRINT_ERROR,
    msg: 'Bagtag print error',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: true,
  },
  {
    errID: '513',
    errCode: ErrCodes.BARCODE_UNREADABLE,
    msg: 'Barcode is not readable',
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '514',
    errCode: ErrCodes.BARCODE_RETRIES_EXCEEDED,
    msg: 'Barcode retries exceeded',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '515',
    errCode: ErrCodes.BARCODE_ERROR,
    msg: 'Barcode error',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: true,
  },
  {
    errID: '516',
    errCode: ErrCodes.BAG_UNABLE_TO_WEIGHT,
    msg: 'Unable to weight',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: false,
  },
  {
    errID: '517',
    errCode: ErrCodes.BAG_UNABLE_TO_MEASURE,
    msg: 'Unable to measure',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: false,
  },
  {
    errID: '518',
    errCode: ErrCodes.BAG_OVERWEIGHT,
    msg: 'Bag too heavy',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '519',
    errCode: ErrCodes.BAG_UNDERWEIGHT,
    msg: 'Bag too light',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '520',
    errCode: ErrCodes.BAG_UNDERWEIGHT_ADDBAG,
    msg: 'Bag too light',
    errType: ErrType.BAGGAGE,
    retry: true,
    fatal: false,
  },
  {
    errID: '521',
    errCode: ErrCodes.BAG_UNDERWEIGHT_USEBIN,
    msg: 'Bag too light',
    errType: ErrType.BAGGAGE,
    retry: true,
    fatal: false,
  },
  {
    errID: '522',
    errCode: ErrCodes.BAG_TOO_TALL,
    msg: 'Bag too large',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '523',
    errCode: ErrCodes.BAG_MISSING,
    msg: 'Bag missing',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '524',
    errCode: ErrCodes.BAG_JAMMED,
    msg: 'Bag jammed',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: true,
  },
  {
    errID: '525',
    errCode: ErrCodes.BAG_LONG,
    msg: 'Bag too long',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '526',
    errCode: ErrCodes.BAG_LONG_ADDBAG,
    msg: 'Bag too long',
    errType: ErrType.BAGGAGE,
    retry: true,
    fatal: false,
  },
  {
    errID: '527',
    errCode: ErrCodes.BAG_UNDERHEIGHT,
    msg: 'Bag too small',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '528',
    errCode: ErrCodes.BAG_UNDERHEIGHT_ADDBAG,
    msg: 'Bag too small',
    errType: ErrType.BAGGAGE,
    retry: true,
    fatal: false,
  },
  {
    errID: '529',
    errCode: ErrCodes.BAG_UNDERHEIGHT_USEBIN,
    msg: 'Bag too small',
    errType: ErrType.BAGGAGE,
    retry: true,
    fatal: false,
  },
  {
    errID: '530',
    errCode: ErrCodes.BAG_UNSTABLE,
    msg: 'Bag unstable',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '531',
    errCode: ErrCodes.CONVEYOR_ESTOP,
    msg: 'Emergency button pressed',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: true,
  },
  {
    errID: '532',
    errCode: ErrCodes.CONVEYOR_ERROR,
    msg: 'Device error',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: true,
  },
  {
    errID: '533',
    errCode: ErrCodes.CONVEYOR_BAG_ERROR,
    msg: 'Device error',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: true,
  },
  {
    errID: '534',
    errCode: ErrCodes.CONVEYOR_OFFLINE,
    msg: 'Device offline',
    errType: ErrType.FATAL,
    retry: false,
    fatal: true,
  },
  { errID: '535', errCode: ErrCodes.BHS_ERROR, msg: 'BHS error', errType: ErrType.HARDWARE, retry: false, fatal: true },
  {
    errID: '536',
    errCode: ErrCodes.ETS_TIMEOUT,
    msg: 'Request to CTS from kiosk times out without a response',
    errType: ErrType.HOST,
    retry: false,
    fatal: false,
  },
  {
    errID: '537',
    errCode: ErrCodes.ETS_MESSAGE_ERROR,
    msg: 'CTS data error - error in message data from CTS',
    errType: ErrType.HOST,
    retry: false,
    fatal: false,
  },
  {
    errID: '538',
    errCode: ErrCodes.USER_QUIT,
    msg: 'Passenger cancelled transaction',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '539',
    errCode: ErrCodes.SCREEN_TIMEOUT,
    msg: 'KC screen timeout',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '540',
    errCode: ErrCodes.NOT_ELIGIBLE_TO_ACCEPT,
    msg: 'Flight not opened or not checked in',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '541',
    errCode: ErrCodes.BAG_MAX_WEIGHT,
    msg: 'Bag too heavy(BHS)',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '542',
    errCode: ErrCodes.BAG_MAX_WEIGHT_ADDBAG,
    msg: 'Bag too heavy(BHS)',
    errType: ErrType.BAGGAGE,
    retry: true,
    fatal: false,
  },
  {
    errID: '543',
    errCode: ErrCodes.APP_ERROR,
    msg: 'KC application error (exception)',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '544',
    errCode: ErrCodes.TOO_EARLY_BAGGAGE,
    msg: 'Too early for baggage',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '545',
    errCode: ErrCodes.TOO_LATE_BAGGAGE,
    msg: 'Too late for baggage',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '546',
    errCode: ErrCodes.BAG_OVERSIZED,
    msg: 'The baggage is oversized',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: true,
  },
  {
    errID: '547',
    errCode: ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED,
    msg: 'Reposition retries exceeded',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  { errID: '548', errCode: ErrCodes.NO_BAGS, msg: 'No bags', errType: ErrType.GENERAL, retry: false, fatal: false },
  {
    errID: '549',
    errCode: ErrCodes.SBD_BAG_FLOW_NOT_ALLOWED,
    msg: 'SBD bag flow not allowed',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '550',
    errCode: ErrCodes.TAG_ALREADY_ACTIVE,
    msg: 'SBD bag tag is already active',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '552',
    errCode: ErrCodes.BAG_SHORT,
    msg: 'Bag too short',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.BARCODE_EMPTY,
    msg: ErrCodes.BARCODE_EMPTY,
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.CONVEYOR_INTRUSION,
    msg: ErrCodes.CONVEYOR_INTRUSION,
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.UNABLE_TO_WEIGHT,
    msg: ErrCodes.UNABLE_TO_WEIGHT,
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.PRINTER_OFFLINE,
    msg: ErrCodes.PRINTER_OFFLINE,
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.PRINTER_OUT,
    msg: ErrCodes.PRINTER_OUT,
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.PRINTER_JAM,
    msg: ErrCodes.PRINTER_JAM,
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.BARCODE_NOT_VALID,
    msg: ErrCodes.BARCODE_NOT_VALID,
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.RECORD_NOT_FOUND,
    msg: ErrCodes.RECORD_NOT_FOUND,
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.WRONG_AIRPORT,
    msg: ErrCodes.WRONG_AIRPORT,
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.BP_NOT_ON_PNR,
    msg: ErrCodes.BP_NOT_ON_PNR,
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.BP_DUPLICATE_SCAN,
    msg: ErrCodes.BP_DUPLICATE_SCAN,
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '',
    errCode: ErrCodes.TAG_NOT_FOUND_IN_PNR,
    msg: ErrCodes.TAG_NOT_FOUND_IN_PNR,
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '560',
    errCode: ErrCodes.PASSPORT_UNREADABLE,
    msg: 'Passport is not readable',
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '561',
    errCode: ErrCodes.PASSPORT_RETRIES_EXCEEDED,
    msg: 'Passport retries exceeded',
    errType: ErrType.GENERAL,
    retry: false,
    fatal: false,
  },
  {
    errID: '562',
    errCode: ErrCodes.PASSPORT_ERROR,
    msg: 'Passport error',
    errType: ErrType.HARDWARE,
    retry: false,
    fatal: true,
  },
  {
    errID: '563',
    errCode: ErrCodes.OVERWEIGHT_AGENT,
    msg: 'Bag too heavy',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },

  {
    errID: '564',
    errCode: ErrCodes.OVERWEIGHT_LAST,
    msg: 'Bag too heavy',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
  {
    errID: '565',
    errCode: ErrCodes.ERR_PASSPORT_INVALID_RETRY,
    msg: 'Passport retries',
    errType: ErrType.GENERAL,
    retry: true,
    fatal: false,
  },
  {
    errID: '566',
    errCode: ErrCodes.SBDERR_HEAVYTAG,
    msg: 'heavy Bag Tag',
    errType: ErrType.BAGGAGE,
    retry: false,
    fatal: false,
  },
]
