/***************************************************************************************
 * this is first screen of this application, it start with the following scenarios:
 * 1. single app without attract loop (Dedicated or Persistent Single-application Mode)
 * 2. multi app mode active by transfer from other app (bio-matrix), receive pnr number
 * 3. multi app or single app mode with attract loop
 *
 ***************************************************************************************/
import React, { useEffect, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { startEtsTransaction, initialStore } from 'actions/etsTransactions/sessionAct'
import { TYPE_KIOSK, ETS_TXN_STATUS } from 'constants/Constants'
import {
  PageHeader,
  PageTitle,
  PageSubTitle,
  DynamicImage,
  PageFooter,
  PageSubContent,
  Spacer,
  PageContent
} from 'components/styledComponents'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import useFaceTracking from 'hooks/useFaceTracking'
import { getDeviceManager, playSound, store, getSBDAppMan, getVersion, history, getBuildAccessibility } from 'main'
import { Footer } from 'layouts/footer'
import { startCUSSTransaction } from 'utils/appTransactions'
import { generateBP, getConveyorPosition } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { Animation } from 'components'
import useCheckLandscape from 'hooks/useCheckLandscape'

import { appMgrOnChange } from 'devices/callbacks'

import { sendPhotoMatch } from 'actions/etsTransactions/sendPhotoMatch'

import { goToLocalError, goToLocalGenericError } from 'utils/helper'
import { ErrCodes } from 'constants/Errors'

const Welcome = () => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const locale = useSelector((state) => state.localData.locale)
  const dispatch = useDispatch()
  const etsTxnStatus = useSelector((state) => state.sessions.ETSTxnStatus)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const ActiveStage = useSelector((state) => state.kioskInfo.onActive)
  const faceMatchingStage = useSelector((state) => state.localData.faceMatchingStage)
  const faceMatchingPrevStage = useRef(null)
  const firstTimeIndicator = useRef(true)
  //const airportCode = useSelector(state => state.kioskInfo.airportCode)
  const aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const isLandscape = useCheckLandscape()
  console.log('kioskType:', JSON.stringify(kioskType), ' sbdModel:', sbdModel)
  const scannedCallback = (scannedValue) => {
    if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      if (config.isCUSSRequired) {
        console.log('startCUSSTransaction ...')
        getSBDAppMan().enableISBD()
        startCUSSTransaction()
      }
      if (clientConfig.hostURL) {
        startEtsTransaction(store, TYPE_KIOSK, getVersion(), { barcodeData: scannedValue })
      }
    } else {
      dispatch(getItinerarySBD({ barcodeData: scannedValue }))
    }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)

  const sendResultCallback = (value) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTracking send result callback is called, value: ' + value)
    sendPhotoMatch(value.split(',')[1])
  }

  const [faceTrackingEnable, faceTrackingDisable, faceTrackingShow, faceTrackingHide, faceTrackingCallback] =
    useFaceTracking(sendResultCallback)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const conveyorPosition = intl.formatMessage(messages[getConveyorPosition()])
    // console.log(conveyorPosition)
    const screenMessage = `${intl.formatMessage(messages[welcomeTitle])}. ${intl.formatMessage(messages[welcomeMsg])}`
    const accDef = {
      pathName: 'Welcome',
      startIndex: 0,
      isQuitRequired: !appMode,
      isLangRequired: true,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicText',
            textParameters: [screenMessage]
          }
        ],
        help: ['HelpBardeLocation', 'HelpBeltLocation']
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    console.log(
      `testing Result : faceMatchingStage: ${faceMatchingStage}, faceMatchingPrevStage ${faceMatchingPrevStage.current} ${firstTimeIndicator.current}`
    )
    if (!faceMatchingStage && !firstTimeIndicator.current && faceMatchingPrevStage.current === true) {
      appLog(TraceLevels.LOG_TRACE, '(Welcome.js) call face service enable function')
      faceTrackingEnable(config.bestShotTimeWelcome)
    }
    faceMatchingPrevStage.current = faceMatchingStage
  }, [faceMatchingStage])

  useEffect(() => {
    firstTimeIndicator.current = false
    return () => {}
  }, [])

  useEffect(() => {
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(Welcome.js) call barcode enable function')
      if (config.isBoardingpassLookupEnable) {
        enable()
      }
      if (config.isFaceRecognitionEnable) {
        faceTrackingEnable(config.bestShotTimeWelcome)
      }
    }
    return () => {
      if (config.isBoardingpassLookupEnable) {
        disable()
      }
      if (config.isFaceRecognitionEnable) {
        faceTrackingDisable()
      }
    }
  }, [])

  useEffect(() => {
    if (config.isCUSSRequired) {
      if (ActiveStage) {
        if (config.isBagtagLookupEnable) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (Welcome.js) componentDidMount() - send SQ')
          getSBDAppMan().setSQStart(-1)
          aeaBagdrop.sendAEA('SQ', -10000)
        }
      }
    }
  }, [ActiveStage])

  const handleActions = (e) => {
    let barcode = ''
    const { pnr, firstName, lastName, from, to, airLine, flightNumber, bagtag } = config.testData[0]
    const SBDAppManager = getSBDAppMan()
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(Welcome.js) handleClick() ... target: ' + e.currentTarget.id)
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          // if (config.isBagtagLookupEnable) {
          //   SBDAppManager.setAppFlow(20)
          //   aeaBagdrop.onDeviceEvent({
          //     // key: 'unsolicitedMessage',
          //     // value: 'HDCBQNI#LP=1581370468#HT=18CM#WT=36CM#LT=37CM#SW=4#CW=0.6KG#BS=BAGP'
          //     // value: 'HDCBQNI#HT=32CM#WT=43CM#LT=61CM#SW=4#CW=3.6KG#BT=0#BS=BAGP'
          //     // value: 'HDCBQNI#HT=24CM#WT=44CM#LT=59CM#SW=4#CW=4.0KG#BT=0#BS=BAGP'
          //     key: 'commandCompleted',
          //     // value: 'HDCERR5#INTR'
          //     // value: 'HDCERR5#OVHG'
          //     // value: 'HDCPROK#PA'
          //     // value: 'HDCBQOK#LP=' + bagTag + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
          //     value: `HDCBQOK#LP=${bagtag[0]}#HT=36CM#WT=36CM#LT=65CM#SW=4#CW=20.4LB#BT=0#BS=BAGP`
          //   })
          // } else {
          //   // testPassenger = testPassengers[0]
          //   barcode = generateBP(pnr, firstName, lastName, from, to, airLine, flightNumber)
          //   // barcode =
          //   //   'M1TEST/ONE            E8I9B26 DTWLASE1 1770 213Y13A0 000090F&gt;50B1OO8303BWS 0E83******00000 &gt;460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk=='
          //   // barcode = `M1TOLHR/ONE           EJD9AB2 DTWJFKDL 5671 352Y010B0001 155>318  W     DL              2900621416096380                           DL3J87FEU5010B^160MEUCIQCE3TVBBSn9RS3tvFsNv8jvAJWTkgienNM7k9/BTh9dLwIgYz37f/TC2nYLColaw2gitlqUJ8ZII9eFLGh4R640FCc=`
          //   // barcode =
          //   //   'M1TOSLC/THREEBAGS     EHZKGEM DTWSLCDL 0939 304C002C0001 155>318  W     DL              2900621412918830                           DL3J891CXVT02C^160MEQCIHGVa1E5wahuDpf+dedM2I+Sj7653DRjwKnPo5LFS8BBAiAzddRLFw0CKrAmVFFcyaVh6sFb8iy7miTFVsZeEZ9iQw=='
          //   //barcode = config.test
          //   //barcode = 'M1B/B                  PZCNTH HYDBLRI5 1527 176Y038F0001 347&gt;1182 K0143BNK 048702367000229487000000000000'
          //   //barcode ='M1DTWTOCLT/PAXTWO     EW8QGIU AUHCLTDL 0508 354Y025D0001 155>318  W     WS              2900621403998520                           WS3J8LX7ZES25D'
          //   // barcode =
          //   //   'M1SMITH/MARK          EDOM123 YYZYULNK 0123 036Y014K2101 90F>50B1OO8303BWS 0E8380000000000 ^460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk9BGGk1XDkPdJrfzAiBLSzIjq3EHP6CZOxFacKwJ41G2RqsJLvRRREGt4sTFrA==1580928289,1580928311'
          //   barcodeReaderCallback({
          //     key: 'barcodeReadInternal',
          //     value: barcode
          //   })
          // }

          // appMgrOnChange({ key: 'SBD_MODEL', value: 'v1' })
          // history.push('Intrusion')
          // goToLocalError(
          //   'commandCompleted appFlow 20',
          //   ErrCodes.MULTIPLE_TAGS,
          //   'ErrorMismatch2',
          //   'END_TXN',
          //   null,
          //   'ErrorMismatch'
          // )

          // goToLocalGenericError(
          //   'commandCompleted appFlow 5',
          //   ErrCodes.BAG_TOO_TALL,
          //   'Error_BagTooTall',
          //   'PutBagOnBelt',
          //   null,
          //   {
          //     tagNumber: '123456789'
          //   }
          // )

          goToLocalGenericError(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNDERHEIGHT_USEBIN,
            'Error_BagUnderheightUseBin',
            'END_TXN',
            null
          )
          // history.push({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitBagProcess' } })

          break
        case 'buttonScanBarcodeERROR':
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: ''
          })
          break
        case 'buttonFaceMatchTEST':
          // faceTrackingCallback({
          //   key: 'faceDescriptor',
          //   value: config.photoData
          // })
          aeaBagdrop.onDeviceEvent({
            // key: 'unsolicitedMessage',
            // value: 'HDCBQNI#LP=1581370468#HT=18CM#WT=36CM#LT=37CM#SW=4#CW=0.6KG#BS=BAGP',
            key: 'commandCompleted',
            // value: 'HDCERR5#BGPR'
            // value: 'HDCPROK#PA'
            // value: 'HDCBQOK#LP=' + bagTag + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
            value: 'HDCBQOK#LP=8006868001#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0LB#BS=BAGP'
          })
          break
        case 'ScanDocuments':
          history.push('scanDocuments')
          break
        case 'TakePhoto':
          history.push('TakePhoto')
          break
        case 'AgentScan':
          history.push('agentScan')
          break
        case 'ShowDisclaimer':
          history.push('showDisclaimer')
          break
        case 'PrintBagTag':
          history.push('printBagTag')
          break
        case 'noScan':
          SBDAppManager.setAppFlow(20)
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCERR5#BAGJ'
          })
          break
        case 'bagJam':
          SBDAppManager.setAppFlow(20)
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCBQOK#LP=ERR#HT=20CM#WT=ERR#LT=126CM#SW=4#CW=3.3KG#BS=BAGP'
          })
          break
        case 'myTest':
          /* SBDAppManager.setAppFlow(20)
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCBQOK#LP=' + bagtag[0] + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP',
          }) */
          /* barcode = {
            pathname: 'pleaseWait',
            state: {
              messageId: 'PleaseWaitFindReservation'
            }
          }
          history.push(barcode) */
          history.push('customPage')
          break
        default:
      }
    }
  }
  const testData = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'buttonScanBarcodeTEST',
      text: config.isBagtagLookupEnable ? 'Scan bagtag' : 'Scan barcode',
      group: 0,
      handler: handleActions
    },
    // {
    //   id: 'buttonFaceMatchTEST',
    //   text: 'Face match',
    //   cssName: 'test-button-up',
    //   handler: handleActions
    // },
    {
      id: 'ScanDocuments',
      text: 'ScanDoc',
      group: 0,
      handler: handleActions
    },
    {
      id: 'TakePhoto',
      text: 'TakePhoto',
      group: 0,
      handler: handleActions
    },
    {
      id: 'AgentScan',
      text: 'AgentScan',
      group: 0,
      handler: handleActions
    },
    {
      id: 'ShowDisclaimer',
      text: 'ShowDisclaimer',
      group: 0,
      handler: handleActions
    },
    {
      id: 'PrintBagTag',
      text: 'PrintBagTag',
      group: 0,
      handler: handleActions
    },
    {
      id: 'noScan',
      text: 'No Scan',
      group: 0,
      handler: handleActions
    },
    {
      id: 'bagJam',
      text: 'Bag Jam',
      group: 0,
      handler: handleActions
    },
    {
      id: 'myTest',
      text: 'test Screen',
      group: 0,
      handler: handleActions
    }
  ]
  const welcomeMsg = config.isBagtagLookupEnable ? 'WelcomePutBagOnBelt' : 'WelcomeScanBoardingPass'
  const welcomeTitle = config.isBagtagLookupEnable ? 'WelcomeTitlePutBagOnBelt' : 'WelcomeTitleScanBoardingPass'
  const animationLookup = isLandscape
    ? themeContext.AnimationLandscape.PlaceBag
    : themeContext.AnimationPortrait.PlaceBag
  const animationScanBp = isLandscape ? themeContext.AnimationLandscape.ScanBp : themeContext.AnimationPortrait.ScanBp
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const imageLocation = config.isBagtagLookupEnable
    ? `${themeContext.AnimationPath}/${sbdModel}/${animationLookup}`
    : `${themeContext.AnimationPath}/${sbdModel}/${animationScanBp}`
  const animationSection = (
    <Animation imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
  )
  const textSection = (
    <>
      <PageTitle
        fontSize={
          themeContext.Welcome
            ? themeContext.Welcome.PageTitle.fontSize[ratioKey]
            : themeContext.PageTitle.fontSize[ratioKey]
        }
        fontWeight={
          themeContext.Welcome ? themeContext.Welcome.PageTitle.fontWeight : themeContext.PageTitle.fontWeight
        }
        color={themeContext.Welcome ? themeContext.Welcome.PageTitle.color : themeContext.PageTitle.color}
        alignItems="center"
      >
        {messages.WelcomeTitlePutBagOnBeltEN}
      </PageTitle>
      <PageSubTitle
        fontSize={
          themeContext.Welcome
            ? themeContext.Welcome.PageSubTitle.fontSize[ratioKey]
            : themeContext.PageSubTitle.fontSize[ratioKey]
        }
        fontWeight={
          themeContext.Welcome ? themeContext.Welcome.PageSubTitle.fontWeight : themeContext.PageSubTitle.fontWeight
        }
      >
        {messages.WelcomePutBagOnBeltEN}
      </PageSubTitle>
      <Spacer height={'20px'} />
      <PageTitle
        fontSize={
          themeContext.Welcome
            ? themeContext.Welcome.PageTitle.fontSize[ratioKey]
            : themeContext.PageTitle.fontSize[ratioKey]
        }
        fontWeight={
          themeContext.Welcome ? themeContext.Welcome.PageTitle.fontWeight : themeContext.PageTitle.fontWeight
        }
        color={themeContext.Welcome ? themeContext.Welcome.PageTitle.color : themeContext.PageTitle.color}
        alignItems="center"
      >
        {messages.WelcomeTitlePutBagOnBeltFR}
      </PageTitle>
      <PageSubTitle
        fontSize={
          themeContext.Welcome
            ? themeContext.Welcome.PageSubTitle.fontSize[ratioKey]
            : themeContext.PageSubTitle.fontSize[ratioKey]
        }
        fontWeight={
          themeContext.Welcome ? themeContext.Welcome.PageSubTitle.fontWeight : themeContext.PageSubTitle.fontWeight
        }
      >
        {messages.WelcomePutBagOnBeltFR}
      </PageSubTitle>
    </>
  )

  const header = null

  // const footer = (
  //   <Footer
  //     isQuitRequired={!appMode}
  //     quitBtnText={intl.formatMessage(messages.buttonQuit)}
  //     isBackRequired={false}
  //     isSkipRequired={false}
  //     isConfirmRequired={false}
  //     isLangRequired={false}
  //     displayItinerary={false}
  //     atcAreaCollaps={true}
  //     testData={testData}
  //   />
  // )

  const brandLogo = <DynamicImage imageName={`${themeContext.BrandLogo}`} width={71} height={12} />

  const footer = (
    <PageFooter>
      <PageSubContent
        flexFlow={isLandscape ? 'row' : 'column-reverse'}
        width="100%"
        height={'150px'}
        padding={themeContext.Footer.atcPadding[ratioKey]}
      >
        {/* <div></div>
        <PageContent
          key="testButtons"
          flexFlow="row"
          justifyContent={isLandscape ? 'flex-end' : 'center'}
        ></PageContent> */}
      </PageSubContent>
      <PageSubContent flexFlow="row" padding="0" width="100%" height={themeContext.Footer.brandHeight[ratioKey]}>
        {themeContext.ShowEMBlogo ? brandLogo : ''}
      </PageSubContent>
    </PageFooter>
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default Welcome
