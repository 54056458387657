import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { BagtagPrinter, deviceIds, TraceLevels } from 'embross-device-manager'
import { ErrCodes } from 'constants/Errors'
import { FALSE, TRUE, FunctionCodes, ServiceNames, TransitionCodes } from 'constants/Constants'
import { PageHeader, PageTitle, PageSubTitle, DynamicImage } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { Footer } from '../footer'
import { getDeviceManager, getBuildAccessibility } from 'main'
import { goToLocalError, navigate } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'

const PrintHeavytag = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const paxDocuments = useSelector((state) => state.responses.paxDocuments)
  const bagtagResources = useSelector((state) => state.responses.bagtagResources)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const bagtags = paxDocuments.slice()
  const totalBagtag = bagtags.length
  const [enableConfirm, setEnableConfirm] = useState(false)
  const bagtagPrinter = getDeviceManager().getDevice(deviceIds.BAGTAG_PRINTER)
  const [currentBagtag, setCurrentBagtag] = useState(-1)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    setTimeout(() => {
      handleAccessibility()
    }, 200)
  }, [enableConfirm])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'PrintHeavytag',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: enableConfirm ? 'OneDynamicTextWithDownKey' : 'OneDynamicText',
            textParameters: [
              `${formatMessage(messages.PrintHeavytags)}, ${formatMessage(messages.PrintHeavytagsInstruction)}`
            ]
          }
        ]
      }
    }

    if (enableConfirm) {
      accDef.sequenceDef.sequence.push({
        id: 'confirmBtn',
        text: formatMessage(messages.buttonDone),
        buttonId: 'confirmBtn'
      })
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */
  
  const bagtagPrinterCallback = (event) => {
    let err = {}
    appLog(TraceLevels.LOG_EXT_TRACE, 'bagtagPrinterCallback ... event : ' + JSON.stringify(event))
    /* if (this.timeoutRef) {
      this.timeoutRef.getWrappedInstance().resetTimer()
    } */
    switch (event.key) {
      case 'pectabLoadingComplete':
        appLog(TraceLevels.LOG_EXT_TRACE, 'totalBagtag: ' + totalBagtag)
        if (totalBagtag > 0) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Print first bagtag.')
          setCurrentBagtag(1)
          bagtagPrinter.print(bagtags[0], config.timeoutPrintCmd)
        }
        break
      case 'bagtagPrinted':
        bagtagPrinter.offer(config.timeoutTakeBagtag)
        logEvent('PrintingHeavyTag: 1')
        break
      case 'offer':
        // 103 - when bagtag not taken after timeout 30s
        if (event.value == 0 || event.value == 104 || event.value == 103) {
          /* if (currentBagtag < totalBagtag) {
            setCurrentBagtag(currentBagtag => {currentBagtag + 1})
            appLog(TraceLevels.LOG_EXT_TRACE, 'Bagtags: printing ' + (currentBagtag + 1) + ' of ' + totalBagtag)
            bagtagPrinter.print(bagtags[currentBagtag], config.timeoutPrintCmd)
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'Heavy tag: print completed')
            setEnableConfirm(true)
          } */
          appLog(TraceLevels.LOG_EXT_TRACE, 'Heavy tag: print completed')
          setEnableConfirm(true)
        }
        break
      case 'bagtagPrintingComplete':
        break
      case 'bagtagFailed':
        logEvent('PrintingHeavyTag: Failed')  
        goToLocalError('PrintHeavyTag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')        
        break
      case 'pectabFailed':
        goToLocalError('PrintHeavyTag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        break
      case 'pectabLoaded':
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  statusChange ' + event.value.toString())
        if (event.value.length === 2) {
          if (event.value[1]) {
            // true means fatal error
            goToLocalError('PrintHeavyTag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
          }
        }
        break
      default:
    }
  }

  useEffect(() => {
    if (bagtagPrinter) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'set printer callback')
      bagtagPrinter.OnDeviceEvent = bagtagPrinterCallback
      console.log('call bagtagPrinter.loadPECTABArray')
      bagtagPrinter.loadPECTABArray(bagtagResources, FALSE)
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'bagtag printer is not available')
    }
    
    setCurrentBagtag(0)
    return () => {
      if (bagtagPrinter) {
        bagtagPrinter.OnDeviceEvent = null
      }
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    let event = null
    appLog(TraceLevels.LOG_EXT_TRACE, '(PrintBagtag.js): handleActions() ... ' + e.currentTarget.id)
    // TEST CODE
    switch (e.currentTarget.id) {
      case 'buttonPrint':
        event = {
          key: 'pectabLoadingComplete',
          value: 0
        }
        bagtagPrinterCallback(event)
        setTimeout(() => {
          event = {
            key: 'offer',
            value: 0
          }
          bagtagPrinterCallback(event)
        }, 1000)
        break
      case 'buttonFail':
        event = {
          key: 'bagtagFailed',
          value: 0
        }
        bagtagPrinterCallback(event)
        break
      case 'confirmBtn':
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          '(getItinerarySBD.js) getItinerarySBD() - Dispatch --> "PutBagOnBelt" screen ...'
        )
        navigate('PutBagOnBelt', 2)
        break
    }
  }

  const testData = [
    {
      //id: 'DESC',
      id: 'DESC-1',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'buttonPrint',
      text: 'Print',
      group: 0,
      handler: handleActions
    },
    {
      //id: 'DESC',
      id: 'DESC-2',
      text: 'Error Cases:',
      group: 1
    },
    {
      id: 'buttonFail',
      text: 'print error',
      group: 1,
      handler: handleActions
    }
  ]

  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const header = <PageHeader alignItems="center">{formatMessage(messages.PrintHeavytagsTitle)}</PageHeader>

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.PrintHeavytags)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.PrintHeavytagsInstruction)}
      </PageSubTitle>
    </>
  )

  const animationSize = useMedia(null, [400, 350, 400], 400)
  const animationSection = (
    <DynamicImage
      imageName={`animations/embross/${sbdModel}/bagtagPrint.gif`}
      cssName={'animation'}
      width={animationSize}
      height={animationSize}
    />
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isLangRequired={false}
        isConfirmRequired={enableConfirm}
        confirmBtnText={formatMessage(messages.buttonDone)}
        confirmAction={handleActions}
        testData={testData}
      />
    </>
  )

  let contentWidth = {
    landscapeMode: '50%',
    portraitMode: '90%'
  }

  const { UIDisplay } = useUIBase(
    {
      topSection: textSection,
      bottomSection: animationSection,
      footer
    },
    {
      contentWidth: contentWidth,
      itineraryHeights: ['200px', '120px', '200px']
    }
  )

  return <>{UIDisplay}</>
}

export default PrintHeavytag
