import React, { useState, Fragment, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { IconButton } from 'components'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import useMedia from 'hooks/useMedia'

const BackAct = (props) => {
  const intl = useIntl()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const themeContext = useContext(ThemeContext)

  return (
    <>
      <IconButton
        id={'backBtn'}
        cssName={props.backBtnStyle}
        color={themeContext.BackButton.color}
        bgColor={themeContext.BackButton.bgColor}
        width={themeContext.BackButton.width[ratioKey]}
        height={themeContext.BackButton.height[ratioKey]}
        disable={props.disableAction}
        onClick={props.backAction}
        fontSize={themeContext.BackButton.fontSize[ratioKey]}
        buttonPosition={themeContext.BackButton.buttonPosition}
        iconLocation={themeContext.BackButton.buttonPosition}
        padding={themeContext.BackButton.padding}
        text={props.backBtnText}
        icon={themeContext.BackButton.icon}
        iconColor={themeContext.BackButton.iconColor}
        borderRadius={themeContext.BackButton.borderRadius[ratioKey]}
        border={themeContext.BackButton.border ? themeContext.BackButton.border : ''}
      />
    </>
  )
}
BackAct.propTypes = {
  backBtnStyle: PropTypes.string,
  backAction: PropTypes.func
}

BackAct.defaultProps = {
  backAction: null
}
export default BackAct
