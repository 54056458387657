/**********************************************************/
// this callback is called when both kioskId and location 
// is received from platform. for example we can use this 
//callback to change config at kiosk level or location level.

import { config } from "dotenv"

/******************************************************** */
export const kioskIdAndLocationReady = (kioskId, location) => {
  console.log('kioskIdAndLocationReady, kioskId:', kioskId, ', location:', location)
  if (location === 'EMB') {
    config.isBagtagLookupEnable = false
    config.isBoardingpassLookupEnable = false
    config.isFaceRecognitionEnable = true
  }
}
