import React, { useState } from 'react'
import { Welcome, GenericPleaseWait, ErrorMismatch, GenericErrors, Intrusion } from 'custom/layouts'

export const customRoutes = [
  // {
  //   element: <CustomPage />,
  //   path: `${PUBLIC_URL}/customPage`
  // }
  {
    element: <Welcome />,
    path: `${PUBLIC_URL}/Welcome`
  },
  { path: `${PUBLIC_URL}/pleaseWait`, element: <GenericPleaseWait /> },
  { path: `${PUBLIC_URL}/Intrusion`, element: <Intrusion /> },
  { path: `${PUBLIC_URL}/ErrorMismatch`, element: <ErrorMismatch /> },
  { path: `${PUBLIC_URL}/Error`, element: <GenericErrors /> }
]
