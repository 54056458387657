import { store, history, getTSDManager } from 'main'
import { logInfo, logError } from 'utils/Logger'
import { goToFetchError } from 'utils/FetchWithTimeout'
import { fetchWithTimeout } from './FetchWithTimeout'
import {endBiometricsTransaction}  from 'actions/biometricsApis'
import { END_TXN_REASON } from 'constants/Constants'

const matchOneToMany = (visiblePhoto, session, callback, isEndTransaction = true) => {
  history.push('pleaseWait')
  const sessionInfo = session ? session: {
    etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
    emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
  }
  const request = {
    sessionInfo: sessionInfo,
    photo: visiblePhoto,
    currentClientTime: new Date().getTime(),
    asyncRequested: true,
    carrierCode: undefined,
    jsonExtend: undefined
  }
  const tsdMgr = getTSDManager()
  logInfo('matchOneToMany:: request ... ' + JSON.stringify(request))
  fetchWithTimeout('matchOneToMany/', request)
    .then((json) => {
      logInfo('matchOneToMany receive response ... ', json)
      if (isEndTransaction) {
        endBiometricsTransaction(sessionInfo, END_TXN_REASON.COMPLETE_OK,null,null)
      }      
      if (json.etsResponse) {        
        if (json.etsResponse.matched) {
          const travelerInfo = json.etsResponse.travelerInfo
          if (travelerInfo) {
            /* let recordLocator = {
              pnr: json.etsResponse.travelerInfo.pnr,
              firstName: json.etsResponse.travelerInfo.firstName,
              lastName: json.etsResponse.travelerInfo.lastName
            } */
            //store.dispatch(setTravelerInfo(travelerInfo))
            if (config.enableConfirmFaceMatched) {
              history.push('confirmCheckin')
            } else {
              console.log('travelerInfo:', travelerInfo) 
              //startEtsTransaction(store, 'SBD', getVersion(),{barcodeData: json.etsResponse.boardingPass.bcbp}, sessionInfo.etsSessionID )
              if (callback) {
                callback(sessionInfo.etsSessionID, {barcodeData: json.etsResponse.boardingPass.bcbp})
              }
            }
            
          } else {
            // non cuss env, go to inactive.
            history.push('inactive')
          }
        } else {
          // not match / registerred
          logInfo('matchOneToMany:: not matched')
          history.push('welcome')
        }
        
      } else {
        /* if (json.error) {
          //goToFetchError('matchOneToMany', json, store.dispatch, config.firstScreen)
          history.push('welcome')
        } else {
          store.dispatch(updateError(ErrCodes.ETS_COMM_ERROR, '', 'matchOneToMany', OOS))
        } */
        logInfo('matchOneToMany:: not matched')
        history.push('welcome')
      }
    })
    .catch((err) => {
      logError('matchOneToMany Catch:' + err)
      /* store.dispatch(updateError(err.errCode, err.message, 'matchOneToMany'))
      navigate('error') */
      history.push('welcome')
    })
}
export default matchOneToMany