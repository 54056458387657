import React, { useState, useEffect, useRef, useContext } from 'react'
import { playSound, getSBDAppMan, getBuildAccessibility } from 'main'
import { populateItineraryInfo, isEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { Footer } from '../footer'
import { END_TXN_REASON } from 'constants/Constants'
import { PageHeader, PageTitle, PageSubTitle, PageText, DynamicImage, Spacer } from 'components/styledComponents'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { ThemeContext } from 'styled-components'

const ClosingRemarks = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const locale = useSelector((state) => state.localData.locale)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const [itineraryData, setItineraryData] = useState(null)
  const timerId = useRef(null)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [itineraryData, locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'ClosingRemarks',
      startIndex: 0,
      sequenceDef: {
        sequence: getSequence()
      }
    }

    buildAccessibility(accDef)
  }

  const getSequence = () => {
    const title = intl.formatMessage(messages.ClosingRemarksTitle)
    const subtitle = intl.formatMessage(messages.ClosingRemarksSubTitle)

    const sequence = []
    if (itineraryData && !(isEmpty(itineraryData.gate) || isEmpty(itineraryData.boardTime))) {
      const closingRemarksInstructionWithGateAndTime = [
        title,
        subtitle,
        intl.formatMessage(messages.Gate, { gate: itineraryData.gate }),
        intl.formatMessage(messages.BoardingTime, { boardTime: itineraryData.boardTime })
      ]
      sequence.push({
        id: 'page-content',
        textId: 'OneDynamicTextWithDownKey',
        textParameters: [closingRemarksInstructionWithGateAndTime.join('. ')]
      })
    } else {
      const closingRemarksInstructionNoGate = [
        title,
        subtitle,
        intl.formatMessage(messages.ClosingRemarksInstructionNoGate)
      ]
      sequence.push({
        id: 'page-content',
        textId: 'OneDynamicTextWithDownKey',
        textParameters: [closingRemarksInstructionNoGate.join('. ')]
      })
    }
    sequence.push({ id: 'confirmBtn', text: intl.formatMessage(messages.buttonDone), buttonId: 'confirmBtn' })
    return sequence
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // generate itinerary information
  useEffect(() => {
    if (itineraryInfo == null || itineraryInfo == '') {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '>> (BagProcessing.js) componentDidMount() - this.props.itineraryInfo NOT AVAILABLE ...'
      )
    } else {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '(BagProcessing.js) componentDidMount() - this.props.itineraryInfo AVAILABLE !!!'
      )
      let itineraryData = populateItineraryInfo(itineraryInfo)
      if (itineraryData !== null) {
        setItineraryData(itineraryData)
      }
    }
  }, [itineraryInfo])

  useEffect(() => {
    timerId.current = setTimeout(() => {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '(ClosingRemarks.js) timeout - completeTransaction(false) : ' + config.closingRemarkTimeout
      )
      timerId.current = null
      getSBDAppMan().doQuit(END_TXN_REASON.COMPLETE_OK, '', '')
    }, config.closingRemarkTimeout * 1000)

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
    }
  }, [])

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(ClosingRemarks.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        getSBDAppMan().doQuit(END_TXN_REASON.COMPLETE_OK, '', '')
        break
      default:
    }
  }

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'], 'center')
  const animationSection = (
    <DynamicImage
      imageName={themeContext.ClosingRemarkImage}
      cssName={'emb_animation_drawbox'}
      width={animationSize.width}
      height={animationSize.height}
    />
  )

  let closingRemarkBody = <></>
  if (itineraryData) {
    if (itineraryData && !(isEmpty(itineraryData.gate) || isEmpty(itineraryData.boardTime))) {
      closingRemarkBody = (
        <>
          {'\n'+intl.formatMessage(messages.Gate, { gate: itineraryData.gate })+ '  '}
          {intl.formatMessage(messages.BoardingTime, { boardTime: itineraryData.boardTime })}
        </>
      )
    }
  }

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.ClosingRemarksTitle)}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
        textAlign="left"
      >
        {intl.formatMessage(messages.ClosingRemarksSubTitle)}
        {themeContext.ClosingRemarks.displayBoardingInfoText ? closingRemarkBody : ''}
      </PageSubTitle>
    </>
  )

  const header = null

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={handleActions}
      confirmBtnText={intl.formatMessage(messages.buttonDone)}
      confirmIcon={themeContext.ConfirmButton.doneIcon}
      displayItinerary={themeContext.ClosingRemarks.displayItinerary}
      displayBoardingInfo={themeContext.ClosingRemarks.displayBoardingInfoFooter}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth,
      displayItinerary: !config.hideNameOnClosingRemarks
    }
  )

  return <>{UIDisplay}</>
}

export default ClosingRemarks
