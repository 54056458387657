import React, { useEffect, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { PageTitle, Spacer, PageContent, PageSubContent, DynamicImage, PopupTitle } from 'components/styledComponents'
import { Button, Popup, IconButton } from 'components'
import LanguageButton from 'components/languageButton/LanguageButton'
import { playSound } from 'main'
import { updateLocalData } from 'actions/localActions'
import { changeLanguage } from 'actions/etsTransactions/changeLanguage'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { ACC_POPUPS } from 'constants/Constants'
import { getAccessibilityDevice, getAccessibilityManager, getSummaryStore } from 'main'
import useMedia from 'hooks/useMedia'

const LangAct = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const summaryStore = getSummaryStore()
  const { disabled, langTextMode, langBtnStyle } = props
  const dispatch = useDispatch()
  const [langText, setLangText] = useState(null)
  const [languageItem, setLanguageItem] = useState(null)
  const locale = useSelector((state) => state.localData.locale)
  const accessibility = getAccessibilityDevice()
  const accMgr = getAccessibilityManager()
  const [visible, setVisible] = useState(false) // popup visible
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    setLanguageItem(buildLanguageButtons())
    setLangText(buildLanguageText(locale))
  }, [locale])

  const buildAccDef = () => {
    let accDef = Object.assign(
      {},
      {
        sequenceDef: {
          type: ACC_POPUPS.POPUP,
          sequence: [{ id: 'popup-language-title', textId: 'LanguageSwitchPopupTitle' }]
        }
      }
    )
    config.languages.forEach((language) => {
      accDef.sequenceDef.sequence.push({
        id: language.id,
        textId: language.id,
        buttonId: language.id,
        language: language.id
      })
    })
    return accDef
  }

  const handleMoreLanguage = (e) => {
    playSound.beepOK()
    console.log('targetId:', e.currentTarget.id)
    dispatch(updateLocalData('switchLocale', e.currentTarget.id))
    summaryStore.Language = e.currentTarget.id
    // TODO: Once Gateway is ready to handle this call
    // dispatch(changeLanguage(e.currentTarget.id, null))
    setVisible(false)
    accMgr.switchLocale(e.currentTarget.id)
    accMgr.buildPopupGroup(false, null)
  }

  const buildLanguageText = (locale) => {
    if (!disabled) {
      if (config.languages.length === 2) {
        const firstLanguage = config.languages[0]
        const secondLanguage = config.languages[1]
        if (locale === firstLanguage.id) {
          if (props.langTextMode === 'long') {
            return secondLanguage.text
          } else {
            return secondLanguage.id.toUpperCase()
          }
        } else {
          if (props.langTextMode === 'long') {
            return firstLanguage.text
          } else {
            return firstLanguage.id.toUpperCase()
          }
        }
      }
      if (config.languages.length > 2) {
        return intl.formatMessage(messages.Language)
      }
    }
    return ''
  }

  const handleLangAction = (e) => {
    setLanguageItem(buildLanguageButtons())
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, 'handleLangAction() ... event id : ' + e.currentTarget.id)
    // There are more 2 languages
    if (config.languages.length > 2) {
      setVisible(true)
      accMgr.buildPopupGroup(true, buildAccDef())
      return
    }
    // There are ONLY TWO languages
    const firstLanguage = config.languages[0]
    const secondLanguage = config.languages[1]
    console.log('locale', locale)
    if (locale === firstLanguage.id) {
      dispatch(updateLocalData('switchLocale', secondLanguage.id))
      accMgr.switchLocale(secondLanguage.id)
      summaryStore.Language = secondLanguage.id
      // TODO: Once Gateway is ready to handle this call
      // dispatch(changeLanguage(secondLanguage.id, null))
    } else {
      dispatch(updateLocalData('switchLocale', firstLanguage.id))
      accMgr.switchLocale(firstLanguage.id)
      summaryStore.Language = firstLanguage.id
      // TODO: Once Gateway is ready to handle this call
      // dispatch(changeLanguage('en', null))
    }
  }

  const buildLanguageButtons = () => {
    let languageItem = []
    let languageItemLeft = []
    let languageItemRight = []
    if (config.languages.length > 5) {
      config.languages.forEach((item, index) => {
        if (index % 2 === 0) {
          languageItemLeft.push(
            <div className="popup-language-item" key={'item-left-' + index}>
              <IconButton
                id={item.id}
                cssName={'language-button-up'}
                color={themeContext.LanguageButton.color}
                bgColor={themeContext.LanguageButton.bgColor}
                width={themeContext.LanguageButton.width[ratioKey]}
                height={themeContext.LanguageButton.height[ratioKey]}
                iconLocation={themeContext.LanguageButton.buttonPosition}
                buttonPosition={themeContext.LanguageButton.buttonPosition}
                //disable={disabled}
                onClick={handleMoreLanguage}
                fontSize={themeContext.LanguageButton.fontSize[ratioKey]}
                text={item.text}
                icon={themeContext.LanguageButton.icon}
                iconColor={themeContext.LanguageButton.iconColor}
                disableShadow={themeContext.LanguageButton.disableBoxShadow}
                borderRadius={themeContext.LanguageButton.borderRadius[ratioKey]}
              />
            </div>
          )
        } else {
          languageItemRight.push(
            <div className="popup-language-item" key={'item-right-' + index}>
              <IconButton
                id={item.id}
                cssName={'language-button-up'}
                color={themeContext.LanguageButton.color}
                bgColor={themeContext.LanguageButton.bgColor}
                width={themeContext.LanguageButton.width[ratioKey]}
                height={themeContext.LanguageButton.height[ratioKey]}
                iconLocation={themeContext.LanguageButton.buttonPosition}
                buttonPosition={themeContext.LanguageButton.buttonPosition}
                //disable={disabled}
                onClick={handleMoreLanguage}
                fontSize={themeContext.LanguageButton.fontSize[ratioKey]}
                text={item.text}
                icon={themeContext.LanguageButton.icon}
                iconColor={themeContext.LanguageButton.iconColor}
                disableShadow={themeContext.LanguageButton.disableBoxShadow}
                borderRadius={themeContext.LanguageButton.borderRadius[ratioKey]}
              />
            </div>
          )
        }
      })
      languageItem.push(
        <React.Fragment key={'unique'}>
          <div className="popup-language-container-left">{languageItemLeft}</div>
          <div className="popup-language-container-right">{languageItemRight}</div>
        </React.Fragment>
      )
    } else {
      config.languages.forEach((item, index) => {
        languageItem.push(
          <div className="popup-language-item" key={'item-' + index}>
            <IconButton
              id={item.id}
              cssName={'language-button-up'}
              color={themeContext.LanguageButton.color}
              bgColor={themeContext.LanguageButton.bgColor}
              width={themeContext.LanguageButton.width[ratioKey]}
              height={themeContext.LanguageButton.height[ratioKey]}
              iconLocation={themeContext.LanguageButton.buttonPosition}
              buttonPosition={themeContext.LanguageButton.buttonPosition}
              //disable={disabled}
              onClick={handleMoreLanguage}
              fontSize={themeContext.LanguageButton.fontSize[ratioKey]}
              text={item.text}
              icon={themeContext.LanguageButton.icon}
              iconColor={themeContext.LanguageButton.iconColor}
              disableShadow={themeContext.LanguageButton.disableBoxShadow}
              borderRadius={themeContext.LanguageButton.borderRadius[ratioKey]}
            />
          </div>
        )
      })
    }

    return languageItem
  }

  return (
    <div>
      {config.languages.length >= 2 ? (
        <IconButton
          id={'langBtn'}
          cssName={langBtnStyle}
          color={themeContext.LanguageSelectionButton.color}
          bgColor={themeContext.LanguageSelectionButton.bgColor}
          width={themeContext.LanguageSelectionButton.width[ratioKey]}
          height={themeContext.LanguageSelectionButton.height[ratioKey]}
          iconLocation={themeContext.LanguageSelectionButton.buttonPosition}
          buttonPosition={themeContext.LanguageSelectionButton.buttonPosition}
          disable={disabled}
          onClick={handleLangAction}
          fontSize={themeContext.LanguageSelectionButton.fontSize[ratioKey]}
          text={langText}
          icon={themeContext.LanguageSelectionButton.icon}
          iconColor={themeContext.LanguageSelectionButton.iconColor}
          disableShadow={themeContext.LanguageSelectionButton.disableBoxShadow}
        />
      ) : null}
      <Popup visible={visible} cssDialogName={'popup-dialog'}>
        {/* <div className="popup-body">
          <div className="popup-content">
            <div className="popup-select-language-title" id="popup-language-title" tabIndex="0">
              <FormattedMessage {...messages.SelectLanguage} />
            </div>
            <div className="popup-language-container">{languageItem}</div>
          </div>
        </div> */}
        <div id="popup-language-title" tabIndex="0">
          <PageSubContent>
            <PopupTitle
              justifyContent="center"
              fontSize={themeContext.Popup.titleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              <FormattedMessage {...messages.SelectLanguage} />
            </PopupTitle>
          </PageSubContent>
          <PageContent flexFlow={isLandscape ? 'row' : 'column'} justifyContent={'space-around'}>
            {languageItem}
          </PageContent>
        </div>
      </Popup>
    </div>
  )
}

LangAct.propTypes = {
  disabled: PropTypes.bool,
  langBtnStyle: PropTypes.string,
  langTextMode: PropTypes.oneOf(['', 'short', 'long'])
}

LangAct.defaultProps = {
  disabled: false,
  langBtnStyle: 'lang-btn',
  langTextMode: 'long'
}

export default LangAct
