import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import ItineraryInfo from 'components/ItineraryInfo/ItineraryInfo'
import BoardingInfo from 'components/BoardingInfo/BoardingInfo'
import QuitAct from './QuitAct'
import BackAct from './BackAct'
import LangAct from './LangAct'
import SkipAct from './SkipAct'
import ConfirmAct from './ConfirmAct'
import HelpAct from './HelpAct'
import CancelAct from './CancelAct'
import PassengerInfo from './PassengerInfo'
import { isEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { PageFooter, DynamicImage, Spacer, PageContent, PageSubContent } from 'components/styledComponents'
import { TestButtons } from 'components'
import { ThemeContext } from 'styled-components'
import { useLocation, useRoutes } from 'react-router-dom'
import { getScreenId } from 'utils/helper'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import './footer.scss'
export const Button = { QUIT: 'QUIT', LANG: 'LANG', HELP: 'HELP', LOGO: 'LOGO' }

const Footer = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const isLandscape = useCheckLandscape()
  /* const dispatch = useDispatch()
  const locale = useSelector(state => state.localData.locale) */
  const { formatMessage } = intl
  const {
    btnSpace,
    isQuitRequired,
    quitBtnText,
    quitBtnStyle,
    quitAction,
    isLangRequired,
    langBtnStyle,
    isBackRequired,
    backBtnText,
    backBtnStyle,
    backAction,
    isSkipRequired,
    isSkipDisabled,
    isSkipInCenter,
    skipBtnText,
    skipAction,
    skipBtnStyle,
    isConfirmRequired,
    confirmBtnText,
    confirmAction,
    confirmBtnStyle,
    disableConfirmAction,
    isCancelRequired,
    cancelBtnText,
    cancelAction,
    displayItinerary,
    displayBoardingInfo,
    isHelpRequired,
    confirmIcon,
    atcAreaCollaps,
    testData,
    customBrandLogo
  } = props

  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  const centerButtonGroupDefault = [
    [{ key: Button.QUIT }, { key: Button.LANG }, { key: Button.HELP }],
    null,
    [{ key: Button.LOGO }]
  ]

  const showItinerary = themeContext.Footer.displayItineraryList
    ? themeContext.Footer.displayItineraryList.includes(screenId)
    : displayItinerary

  const showQuit = themeContext.Footer.displayQuitList
    ? themeContext.Footer.displayQuitList.includes(screenId)
    : isQuitRequired

  const showLang = themeContext.Footer.displayLangList
    ? themeContext.Footer.displayLangList.includes(screenId)
    : isLangRequired

  const showHelpBtn =
    config.enableHelpBtn && themeContext.Footer.displayHelpList
      ? themeContext.Footer.displayHelpList.includes(screenId)
      : false

  const centerButtonGroup = themeContext.Footer.centerButtonGroup
    ? themeContext.Footer.centerButtonGroup
    : centerButtonGroupDefault

  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const imageLocation = `${themeContext.BrandLogo}`

  const brandLogo = <DynamicImage imageName={imageLocation} width={71} height={12} />

  const carrierCode = useSelector((state) => state.localData.carrierCode)
  const kioskInfo = useSelector((state) => state.kioskInfo)

  const airlineLogo = config.useDefaultAirlineLogo ? (
    themeContext.AirlineLogo ? (
      <DynamicImage
        imageName={themeContext.AirlineLogo.logo}
        height={themeContext.AirlineLogo.height[ratioKey]}
        width={themeContext.AirlineLogo.width[ratioKey]}
        backgroundPosition={'center right'}
      />
    ) : (
      ''
    )
  ) : carrierCode ? (
    <DynamicImage
      imageName={'airlineLogo-' + carrierCode + '.png'}
      height={themeContext.airlineLogoHeight}
      width={themeContext.airlineLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    ''
  )

  const airportLogo = config.useDefaultAirportLogo ? (
    <DynamicImage
      imageName={themeContext.airportLogo}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    <DynamicImage
      imageName={`airportLogo-${kioskInfo.airportCode}.png`}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  )

  const footerLogo = config.swapLogo ? (
    <div className={'logo-wrapper-footer'}>{airportLogo}</div>
  ) : (
    <div className={'logo-wrapper-footer'}>{airlineLogo}</div>
  )

  const buttonSection = (
    <PageContent key="testButtons" flexFlow="row" justifyContent={isLandscape ? 'flex-end' : 'center'}>
      {isBackRequired ? (
        <BackAct
          backBtnStyle={backBtnStyle}
          backBtnText={isEmpty(backBtnText) ? formatMessage(messages.buttonBack) : backBtnText}
          backAction={backAction}
        />
      ) : (
        ''
      )}
      {isSkipRequired && !isSkipInCenter ? (
        <SkipAct
          skipBtnText={isEmpty(skipBtnText) ? formatMessage(messages.buttonSkip) : skipBtnText}
          skipAction={skipAction}
          skipBtnStyle={skipBtnStyle}
          isDisabled={isSkipDisabled}
        />
      ) : (
        ''
      )}
      {isCancelRequired ? (
        <CancelAct
          cancelBtnText={isEmpty(cancelBtnText) ? formatMessage(messages.buttonCancel) : cancelBtnText}
          cancelAction={cancelAction}
        />
      ) : (
        ''
      )}
      {isBackRequired || isSkipRequired || isCancelRequired ? <Spacer width="20px" /> : ''}
      {isConfirmRequired ? (
        <ConfirmAct
          confirmBtnText={isEmpty(confirmBtnText) ? formatMessage(messages.buttonConfirm) : confirmBtnText}
          confirmAction={confirmAction}
          disableAction={disableConfirmAction}
          confirmBtnStyle={confirmBtnStyle}
          confirmIcon={confirmIcon}
        />
      ) : (
        ''
      )}
      {testData ? <TestButtons data={testData} showPopup={true} showTestButton={config.showTestButtons} /> : ''}
    </PageContent>
  )

  const quitButton = showQuit ? (
    <QuitAct quitBtnText={quitBtnText} quitBtnStyle={quitBtnStyle} quitAction={quitAction} />
  ) : (
    ''
  )

  const langButton = showLang ? <LangAct langBtnStyle={langBtnStyle} /> : ''

  const helpButton = showHelpBtn ? (
    <HelpAct helpBtnText={props.helpBtnText ? props.helpBtnText : formatMessage(messages.buttonHelp)} />
  ) : (
    ''
  )

  const renderCenterButtonGroup = () => {
    let mainGroup = []

    centerButtonGroup.forEach((column) => {
      let group = []
      if (column) {
        column.forEach((item, i) => {
          switch (item.key) {
            case Button.QUIT:
              group.push(<div key={i}>{quitButton}</div>)
              break
            case Button.LANG:
              group.push(<div key={i}>{langButton}</div>)
              break
            case Button.HELP:
              group.push(<div key={i}>{helpButton}</div>)
              break
            case Button.LOGO:
              group.push(<div key={i}>{footerLogo}</div>)
              break
            default:
              break
          }
        })
        mainGroup.push(group)
      } else {
        mainGroup.push(<PageContent padding="0"></PageContent>)
      }
    })

    return (
      <PageSubContent
        flexFlow="row"
        width="100%"
        padding="0"
        height={themeContext.Footer.footerHeight[ratioKey]}
        style={{
          backgroundColor: themeContext.Footer.bgColorCenter,
          boxShadow: themeContext.Footer.disableBoxShadow ? '' : 'inset 0px 0px 3px #0000001c'
        }}
      >
        {mainGroup[0]}
        {mainGroup[1]}
        {mainGroup[2]}
      </PageSubContent>
    )
  }

  return (
    <PageFooter>
      <PageSubContent
        flexFlow={isLandscape ? 'row' : 'column-reverse'}
        width="100%"
        height={atcAreaCollaps ? '' : themeContext.Footer.atcHeight[ratioKey]}
        padding={themeContext.Footer.atcPadding[ratioKey]}
      >
        {showItinerary ? <ItineraryInfo /> : <div></div>}
        {displayBoardingInfo ? <BoardingInfo /> : ''}
        {buttonSection}
      </PageSubContent>

      {renderCenterButtonGroup()}

      <PageSubContent flexFlow="row" padding="0" width="100%" height={themeContext.Footer.brandHeight[ratioKey]}>
        {customBrandLogo ? customBrandLogo : themeContext.ShowEMBlogo ? brandLogo : ''}
      </PageSubContent>
    </PageFooter>
  )
}

Footer.propTypes = {
  isQuitRequired: PropTypes.bool,
  quitBtnText: PropTypes.string,
  quitBtnStyle: PropTypes.string,
  quitAction: PropTypes.func,
  backAction: PropTypes.func,
  skipAction: PropTypes.func,
  confirmAction: PropTypes.func,
  disableConfirmAction: PropTypes.bool,
  isBackRequired: PropTypes.bool,
  isLangRequired: PropTypes.bool,
  isSkipRequired: PropTypes.bool,
  isSkipInCenter: PropTypes.bool,
  isConfirmRequired: PropTypes.bool,
  backBtnText: PropTypes.string,
  backBtnStyle: PropTypes.string,
  skipBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  btnSpace: PropTypes.string,
  isSkipDisabled: PropTypes.bool,
  skipBtnStyle: PropTypes.string,
  displayStyle: PropTypes.string,
  confirmBtnStyle: PropTypes.string,
  testData: PropTypes.array,
  displayItinerary: PropTypes.bool,
  confirmBtnStyle: PropTypes.string,
  backBtnStyle: PropTypes.string,
  isCancelRequired: PropTypes.bool,
  cancelBtnText: PropTypes.string,
  displayBoardingInfo: PropTypes.bool,
  customBrandLogo: PropTypes.element
}

Footer.defaultProps = {
  isQuitRequired: true,
  isLangRequired: true,
  isSkipInCenter: false,
  isSkipDisabled: false,
  btnSpace: 'btn-nav-space',
  displayItinerary: true,
  confirmBtnStyle: 'confirm-btn',
  backBtnStyle: 'back-btn',
  isCancelRequired: false,
  isHelpRequired: true,
  atcAreaCollaps: false,
  displayBoardingInfo: false
}

export default Footer
