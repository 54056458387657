import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import reduxStore from 'store/configureStore'
import {
  DeviceManager,
  AppManager,
  BarcodeReader,
  OutOfServiceManager,
  TSDManager,
  deviceIds,
  TraceLevels,
  ScreenFlowEventType,
  ScreenInteractionEventType,
  EnvInfoEventType,
  PassportReader,
  FaceTracking,
  AccManager,
  KeypadSimulator
} from 'embross-device-manager'
import { TYPE_KIOSK, INITIAL_TRANSACTIONS } from 'constants/Constants'
import PlaySound from 'components/PlaySound'
import { configsToMap, isNotEmpty, updateConfigFromString, getAccLanguages } from 'utils/helper'
import AltStore from 'utils/altStore'
import {
  appMgrOnChange,
  appMgrOnActive,
  appMgrOnEvent,
  barcodeReaderOnEvent,
  onOOSEvent,
  BagtagPrinterOnEvent,
  passportReaderOnEvent,
  accessibilityOnEvent,
  faceTrackingOnEvent,
  ATBPrinterOnEvent
} from 'devices/callbacks'
import { updateSettings } from 'actions/settingActions'
import { updateSessions } from 'actions/sessionActions'
import { appLog } from 'utils/Logger'
import AEASBDAppManager from 'utils/AEAsbdAppManager'
import { routes } from 'routes'
import { customRoutes } from 'custom/routes'
import SummaryStore from 'utils/summaryStore'
import EventLogger from 'utils/eventLogger'
import TimeoutManager from 'components/timeoutManager/timeoutManager'
import { kioskIdAndLocationReady } from 'custom/callbacks'
import AcuantClient from 'utils/acuantClient'

const beepOKSound = require(`resources/audios/good.wav`)
const beepErrSound = require(`resources/audios/error.wav`)
export const playSound = new PlaySound(beepOKSound, beepErrSound)
export const history = createBrowserHistory({ window })
export const store = reduxStore
export let prevPath = ''
const mergedRoutes = [...customRoutes, ...routes]
const renderApp = (appRoutes) => {
  const Root = require('./containers/root').default
  const container = document.getElementById('root')
  if (container) {
    const root = ReactDOM.createRoot(container)
    root.render(
      <Provider store={store}>
        <Root history={history} routes={appRoutes} locale={config.defaultLanguage.toLowerCase()} />
      </Provider>
    )
  }
}
const timeoutMgr = new TimeoutManager()
timeoutMgr.setDisableTimeout(config.disableScreenTimeout)
console.info(`******** ${getAppName()} Start  **********`)
console.info('application version: ' + getVersion() + ', build date:' + getBuildDate())
document.body.addEventListener('click', logClickEvent)
//document.body.addEventListener("mousedown", logClickEvent)
const aStore = new AltStore()
// render inactive screen
renderApp(mergedRoutes)

let accessibilityDevice = null
let accMgr = null
let eventLogger = null
let summaryStore = null

let acuantClient = null
if (config.validateDocument) {
  acuantClient = new AcuantClient(config.acuantHost)
}

// TSD manager
const tsdManager = new TSDManager(onOOSEvent, config.statsVersion)
tsdManager.applicationType= 'SBD'
tsdManager.noLogs()
tsdManager.setLogAppLogDetails(true)
tsdManager.setClearAtStartTxn(false) // do not clear events
const devMgr = new DeviceManager(
  '127.0.0.1',
  clientConfig.appLink_port,
  false === config.isCUSSRequired,
  config.defaultLocation
)
//set TSD manager
devMgr.setTSDManager(tsdManager)
// if no CUSS then set the default kiosk id
if (!config.isCUSSRequired) {
  devMgr.setDefaultKioskId(config.kioskId)
}
devMgr.setPromiseTimeout(config.promiseTimeout)
// typical devices
//setup application manager
devMgr.setAppManager(appMgrOnChange, appMgrOnActive, appMgrOnEvent)
// setup barcode reader
if (config.requiredDevices.includes('BARCODE_READER') || config.optionalDevices.includes('BARCODE_READER')) {
  devMgr.addDevices(deviceIds.BARCODE_READER, barcodeReaderOnEvent, config.requiredDevices.includes('BARCODE_READER'))
}
// AEA_BAGDROP events will be handled by AEAsbdAppManager so no need to provide callback below
if (config.requiredDevices.includes('AEA_BAGDROP') || config.optionalDevices.includes('AEA_BAGDROP')) {
  devMgr.addDevices(deviceIds.AEA_BAGDROP, null, config.requiredDevices.includes('AEA_BAGDROP'))
}

// setup passportReader reader
if (config.requiredDevices.includes('PASSPORT_READER') || config.optionalDevices.includes('PASSPORT_READER')) {
  devMgr.addDevices(
    deviceIds.PASSPORT_READER,
    passportReaderOnEvent,
    config.requiredDevices.includes('PASSPORT_READER')
  )
}
if (config.requiredDevices.includes('FACE_TRACKING') || config.optionalDevices.includes('FACE_TRACKING')) {
  devMgr.addDevices(deviceIds.FACE_TRACKING, faceTrackingOnEvent, config.requiredDevices.includes('FACE_TRACKING'))
}

if (config.requiredDevices.includes('BAGTAG_PRINTER') || config.optionalDevices.includes('BAGTAG_PRINTER')) {
  devMgr.addDevices(deviceIds.BAGTAG_PRINTER, BagtagPrinterOnEvent, config.requiredDevices.includes('BAGTAG_PRINTER'))
}
if (config.requiredDevices.includes('ATB_PRINTER') || config.optionalDevices.includes('ATB_PRINTER')) {
  devMgr.addDevices(deviceIds.ATB_PRINTER, ATBPrinterOnEvent, config.requiredDevices.includes('ATB_PRINTER'))
} 

devMgr.addDevices(
  deviceIds.CUSS_ACCESSIBILITY,
  accessibilityOnEvent,
  config.requiredDevices.includes('CUSS_ACCESSIBILITY')
)

devMgr.onConnected = OnConnected
devMgr.appManager.onSocketOpened = OnSocketOpened
devMgr.appManager.onActive = onActive
devMgr.setMaxTraceLevel(TraceLevels.LOG_EXT_TRACE) // should be overwritten later by value from ETS config

// OOS manager
const authUrl = clientConfig.hostURL ? clientConfig.hostURL + '/authenticate/' : null
const url = clientConfig.hostURL ? clientConfig.hostURL + '/heartbeat/' : null
const oosManager = new OutOfServiceManager(authUrl, url, devMgr, onAuthenticationCompleted, onInService, onOutOfService)
if (oosManager) {
  oosManager.setHeartbeatTimeout(config.timeoutHostTransaction * 1000)
  oosManager.setHeartbeatTime(config.heartbeatInterval * 1000)
  oosManager.setHeartbeatTimeUnavail(config.shortHeartbeatInterval * 1000)
  oosManager.setIsHBResponseInService(isResponseInService)
  oosManager.setServerDataUpdate(serverDataUpdate)
  oosManager.setDeviceTime(config.deviceCheckTimer * 1000)
  oosManager.setDeviceTimeUnavail(config.deviceUnavailCheckTimer * 1000)
  oosManager.setServerTimeDeltaMaxDiff(config.serverTimeDeltaMaxDiff)
  oosManager.setServerTimeDeltaMinChange(config.serverTimeDeltaMinChange)
  oosManager.setFirstAvailDelay(config.firstAvailDelay)
}

const sbdAppManager = new AEASBDAppManager(store)

devMgr.connect()

// handle accessibility
loadAccessibilityManager()

history.listen((location) => {
  //location.pathname
  let fromScreen = prevPath
  prevPath = location.pathname
  let paxOrd = ''
  let paxName = ''
  let trCode = ''
  let errCode = ''
  if (location.state !== undefined && location.state !== null) {
    fromScreen =
      location.state.from !== null && location.state.from !== undefined ? fmtStr(location.state.from) : fromScreen
    //paxOrd = fmtStr(location.state.paxOrdinal) === '' ? fmtStr(location.state.travelerOrdinal) : fmtStr(location.state.paxOrdinal)
    //paxName = fmtStr(location.state.firstName)
    paxOrd = fmtStr(location.state.statistics != null ? location.state.statistics.paxOrdinal : null)
    paxName = fmtStr(location.state.statistics != null ? location.state.statistics.paxName : null)
    trCode = fmtStr(location.state.transCode) //fmtStr(store.getState().sessions.transition)
  }
  if (location.pathname === 'retry' || location.pathname === 'error') {
    errCode = isNotEmpty(store.getState().errorDetails) ? store.getState().errorDetails.errCode : ''
  }
  if (fromScreen !== location.pathname) {
    if (trCode) {
      tsdManager.addScrFlowEvent(
        ScreenFlowEventType.CONTROL_TRANSITION,
        fromScreen,
        location.pathname,
        trCode,
        paxOrd,
        paxName,
        errCode
      )
    } else {
      tsdManager.addScrFlowEvent(
        ScreenFlowEventType.LOCAL_TRANSITION,
        fromScreen,
        location.pathname,
        trCode,
        paxOrd,
        paxName,
        errCode
      )
    }
  }
})

function onActive(evt) {
  appLog(TraceLevels.LOG_TRACE, 'onActive - main evt: ' + evt.key + ' ' + evt.value)
  if (sbdAppManager) sbdAppManager.notifyActive()
  // continue notification if active is true
  if (evt.value) {
    appMgrOnActive()
  }
}

function logClickEvent(e) {
  summaryStore.updateTouch()
  let currentPath = history.location.pathname
  console.log('current PATH', currentPath)
  let t = e.target
  if (t.nodeName !== 'BUTTON') {
    // Button with nested div inside.
    const divLevelsInBtn = 3 // number of nested div inside a button
    let tempT = t
    for (let i = 0; i < divLevelsInBtn; i++) {
      tempT = tempT.parentNode
      if (tempT?.nodeName === 'BUTTON') t = tempT
    }
  }

  /** Send data via tsd */
  if (t.nodeName === 'BUTTON') {
    if (!t.disabled)
      tsdManager.addScrInterEvent(ScreenInteractionEventType.ACTIVE_BUTTON_PRESSED, `${currentPath}`, t.id)
    else tsdManager.addScrInterEvent(ScreenInteractionEventType.INACTIVE_BUTTON_PRESSED, `${currentPath}`, t.id)
  } else {
    //console.log('event:',e)
    const path = e.composedPath()
    t = path[0]
    tsdManager.addScrInterEvent(
      ScreenInteractionEventType.NONINTERACTIVE_AREA_TOUCHED,
      `${currentPath}`,
      t.nodeName + '-' + t.id,
      e.x,
      e.y
    )
  }
}

function onInService() {
  appLog(TraceLevels.LOG_TRACE, 'onInService() is called from client. PSAM:' + store.getState().kioskInfo.PSAM)
  if (config.isCUSSRequired) {
    history.push('Inactive')
  } else {
    kioskIdAndLocationReady(store.getState().kioskInfo.kioskId, store.getState().kioskInfo.location)
    summaryStore = new SummaryStore(config.kioskId, config.defaultLanguage)
    eventLogger = new EventLogger('eventLog', config.kioskId, getVersion(), config.defaultLocation)
  }
}

function onOutOfService(reason) {
  appLog(TraceLevels.LOG_TRACE, 'onOutOfService() is called. ' + JSON.stringify(reason))
  history.push('outOfService')
}

function onAuthenticationCompleted(json) {
  devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'onAuthenticationCompleted() is called.')
  if (json.etsResponse) {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'ETS response ... action ... ' + json.etsResponse.action)
    store.dispatch(updateSettings('updateFirstTransition', json.firstTransition))
    store.dispatch(updateSettings('updateServiceDefinition', json.etsResponse.serviceDefinition))
    store.dispatch(updateSettings('updateServiceConfiguration', configsToMap(json.etsResponse.initialConfigElements)))
    if (config.enableETSconfig) {
      let location = store.getState().kioskInfo.location
      let initialTransaction = json.etsResponse.initialConfigElements.filter(item => (item.section === 'InitialTransitions') && (item.id === location))
      if (initialTransaction && initialTransaction.length > 0) {
        switch(initialTransaction[0].value) {
          case INITIAL_TRANSACTIONS.BP_SCAN:
           config.isBagtagLookupEnable = false
           config.isBoardingpassLookupEnable = true
           config.firstScreen = 'welcome'
          break
          case INITIAL_TRANSACTIONS.FACE_TRACKING:
            config.isBagtagLookupEnable = false
            config.isBoardingpassLookupEnable = false
            config.firstScreen = 'recognition'
          break
          case INITIAL_TRANSACTIONS.BAGTAG_LOOKUP:
            config.isBagtagLookupEnable = true
            config.isBoardingpassLookupEnable = false
            config.firstScreen = 'welcome'
          break 
        }
      } 
      devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'update ETS server config values:')
      updateDevManTimeoutsFromETS()
      //find out firstScreen

    } else {
      devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'update ETS server config option is disabled')
    }
    return true
  } else if (json.error) {
    return false
  }
  return false
}

function isResponseInService(json) {
  devMgr.sendLogMsg(
    TraceLevels.LOG_EXT_TRACE,
    'HB isResponseInService: json.etsResponse.inService: ' + json.etsResponse.inService
  )
  return json.etsResponse.inService
}

function serverDataUpdate(version, etsTime, delta) {
  devMgr.sendLogMsg(
    TraceLevels.LOG_EXT_TRACE,
    'HB serverVersion: ' + version + ' serverTime: ' + etsTime + ' serverTimeDelta: ' + delta
  )
  // update store
  if (store.getState().sessions.ETSVersion !== version) store.dispatch(updateSessions('updateETSVersion', version))
  if (store.getState().sessions.ETSTime !== etsTime) store.dispatch(updateSessions('updateETSTime', etsTime))
  if (store.getState().sessions.ETSTimeDelta !== delta) store.dispatch(updateSessions('updateETSTimeDelta', delta))
}

function OnConnected() {
  // INITIALIZE applog
  if (devMgr.setLogger('appManager', true)) {
    // default log file
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'DeviceManager opened')
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, '***** Application version: ' + getVersion())
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, '***** Application build date: ' + getBuildDate())
    let version = navigator.appVersion
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, '***** Browser: ' + version)
    eventLogger = new EventLogger('eventLog', '', getVersion(), '')
    summaryStore = new SummaryStore(config.kioskId, config.defaultLanguage)
  } else {
    console.log('appManager log not initialized!!!')
  }
  // send version
  devMgr.appManager.sendVersion(getVersion(), true)
  devMgr.appManager.getConfig('KIOSK_TYPE')
  devMgr.appManager.getConfig('SBD_MODEL')
  const faceTracking = devMgr.getDevice(deviceIds.FACE_TRACKING)
  if (faceTracking) {
    faceTracking.getStreamingURL()
  }
  devMgr.devices.map((device) => {
    if (device.deviceId === deviceIds.CUSS_ACCESSIBILITY) {
      device.getKeypadDeviceHelp()
      device.getHeadsetDeviceHelp()
    } else {
      device.getKioskDeviceHelp()
    }
  })
  // set SBD app manager
}

function OnSocketOpened(restarting) {
  if (restarting) {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'OnSocketOpened - restarting')
  } else {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'OnSocketOpened - start OOS')
    if (false === config.isCUSSRequired)
      devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, '**************  no CUSS connection **********(config) ')

    oosManager.setCheckDeviceActive(true) // enable checking devices before transaction started PSAM
    oosManager.start(
      config.defaultLanguage.toLowerCase(),
      TYPE_KIOSK,
      getVersion(),
      config.carrierCode,
      clientConfig.hostURL === null
    )
  }
}

//overrides timeout settings in appConfig.js and update devMan with new values
function updateDevManTimeoutsFromETS() {
  let configInfo = store.getState().settings.serviceConfiguration
  if (configInfo) {
    for (const [key, value] of Object.entries(configInfo)) {
      updateConfigFromString(key, value)
      devMgr.sendLogMsg(TraceLevels.LOG_TRACE, key + ' : ' + config[key])
    }
  }
  // update device manager timeouts
  devMgr.setDefaultTimeout(config.deviceManagerTimeout * 1000)
  oosManager.setHeartbeatTimeout(config.timeoutHostTransaction * 1000)
  oosManager.setHeartbeatTime(config.heartbeatInterval * 1000)
  oosManager.setHeartbeatTimeUnavail(config.shortHeartbeatInterval * 1000)
  oosManager.setDeviceTime(config.deviceCheckTimer * 1000)
  oosManager.setDeviceTimeUnavail(config.deviceUnavailCheckTimer * 1000)
}

function fmtStr(str) {
  if (str === undefined || str === null) return ''
  else return str
}

/**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */

function loadAccessibilityManager(skipADTrequired = false, firstTransition) {
  if (config.adtRequired || skipADTrequired) {
    if (config.isCUSSRequired) {
      devMgr.devices.map((device) => {
        if (device.deviceId === deviceIds.CUSS_ACCESSIBILITY) {
          device.getKeypadDeviceHelp()
          device.getHeadsetDeviceHelp()
        } else {
          device.getKioskDeviceHelp()
        }
      })
      accessibilityDevice = devMgr.getDevice(deviceIds.CUSS_ACCESSIBILITY)
    } else {
      accessibilityDevice = new KeypadSimulator()
      accessibilityDevice.createDeviceEvent()
    }

    const options = {
      history: history,
      locale: store.getState().localData.locale,
      deviceInfo: store.getState().deviceInfo,
      translations: getAccLanguages()
    }
    accMgr = new AccManager(accessibilityDevice, devMgr, options, config)
  } else {
    accessibilityDevice = { enabled: false }
  }
}

function buildAccessibility(accDef) {
  accMgr.buildAccessibilityGroup(accDef, store.getState().localData.locale)
}

export function getBuildAccessibility() {
  return buildAccessibility
}

export function getAccessibilityDevice() {
  return accessibilityDevice
}

export function getAccessibilityManager() {
  return accMgr
}
/**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

export function getDeviceManager() {
  return devMgr
}

export function getOOSManager() {
  return oosManager
}

export function getSBDAppMan() {
  return sbdAppManager
}

export function getTSDManager() {
  return tsdManager
}

export function getAltStore() {
  return aStore
}

export function getTimeoutMgr() {
  return timeoutMgr
}

/*global VERSION*/
export function getVersion() {
  return VERSION
}

/*global application name*/
export function getAppName() {
  return APPLICATION_NAME
}

/*global BUILD_DATE*/
export function getBuildDate() {
  return '"' + BUILD_DATE + '"'
}

export function getDevice(deviceId) {
  return devMgr.getDevice(deviceId)
}

export function getSummaryStore() {
  return summaryStore
}

export function getEventLogger() {
  return eventLogger
}

export function getAcuantClient() {
  return acuantClient
}
