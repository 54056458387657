import React, { useState, useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Footer } from '../footer'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { getDeviceManager, history, getTimeoutMgr } from 'main'
import { updateLocalData } from 'actions/localActions'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { updateError } from 'actions/commonActions'
import { TestButtons } from 'components'
import { replacer, populateItineraryInfo, delay, checkLandscape, goToLocalError, navigate } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import { ErrCodes } from 'constants/Errors'
import { FALSE, TRUE, FunctionCodes, ServiceNames, TransitionCodes } from 'constants/Constants'
import useMedia from 'hooks/useMedia'
import {
  PageHeader,
  PageContent,
  PageSubContent,
  PageFooter,
  PageTitle,
  PageSubTitle,
  DynamicImage,
  Spacer
} from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useStateRef from 'hooks/useStateRef'
import { BagtagPrinterOnEvent } from 'devices/callbacks'
import { ThemeContext } from 'styled-components'

const PrintBagtag = (props) => {
  const timeoutMgr = getTimeoutMgr()
  const dispatch = useDispatch()
  const intl = useIntl()
  const paxDocuments = useSelector((state) => state.responses.paxDocuments)
  const bagtagResources = useSelector((state) => state.responses.bagtagResources)
  const transition = useSelector((state) => state.sessions.transition)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const bagtagPrinter = getDeviceManager().getDevice(deviceIds.BAGTAG_PRINTER)
  const [currentBagtag, setCurrentBagtag, currentBagtagRef] = useStateRef(0)
  const [enableConfirm, setEnableConfirm] = useState(false)
  const printedRef = useRef(false)
  const bagtagsRef = useRef([])
  const totalBagtag = paxDocuments.length
  const themeContext = useContext(ThemeContext)

  const printNext = () => {
    if (currentBagtagRef.current < totalBagtag) {
      //currentBagtag.current = currentBagtag.current + 1
      //setCurrentBagtag(currentBagtagRef.current + 1)
      appLog(TraceLevels.LOG_EXT_TRACE, 'Bagtags: printing ' + (currentBagtagRef.current + 1) + ' of ' + totalBagtag)
      bagtagPrinter.print(bagtagsRef.current[currentBagtagRef.current], config.timeoutPrintCmd)
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'Bagtags: print completed')
      // delay on leaving the page when bagtag finish printing
      setEnableConfirm(true)
    }
  }

  const bagtagPrinterCallback = (event) => {
    let err = {}
    appLog(TraceLevels.LOG_EXT_TRACE, 'bagtagPrinterCallback ... event : ' + JSON.stringify(event))
    if (timeoutMgr) {
      timeoutMgr.resetTimer()
    }
    switch (event.key) {
      case 'pectabLoadingComplete':
        appLog(TraceLevels.LOG_EXT_TRACE, 'totalBagtag: ' + totalBagtag)
        if (totalBagtag > 0) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Print first bagtag.')
          bagtagPrinter.print(bagtagsRef.current[currentBagtagRef.current], config.timeoutPrintCmd)
        }
        break
      case 'bagtagPrinted':
        appLog(TraceLevels.LOG_EXT_TRACE, 'event: bagtagPrinted')
        bagtagPrinter.offer(config.timeoutTakeBagtag)
        printedRef.current = true
        setCurrentBagtag((preBagtag) => preBagtag + 1)
        logEvent('PrintingBagTag: 1')
        break
      case 'offer':
        appLog(TraceLevels.LOG_EXT_TRACE, 'event: offer', event)
        // 103 - when bagtag not taken after timeout 30s
        if ((event.value == 0 || event.value == 104 || event.value == 103) && printedRef.current) {
          printedRef.current = false
          delay(config.offerDelay).then(printNext())
        }
        break
      case 'commandTimeout':
        if (event.value === 'offer') {
          printedRef.current = false
          delay(config.offerDelay).then(printNext())
        }
      case 'bagtagPrintingComplete':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  bagtagPrintingComplete ' + event)
        //
        //printNext()
        break
      case 'bagtagFailed':
        // err = {
        //   message: 'bagtag Failed',
        //   detail: ErrCodes.BAGTAG_PRINT_ERROR,
        //   source: 'PrintBagtag.js'
        // }
        logEvent('PrintingBagTag: Failed')
        appLog(TraceLevels.LOG_EXT_TRACE, 'bagtag Failed. ' + JSON.stringify(err))
        goToLocalError('PrintBagtag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        break
      case 'pectabFailed':
        // err = {
        //   message: 'bagtag pectab Failed',
        //   detail: ErrCodes.BAGTAG_PRINT_ERROR,
        //   source: 'PrintBagtag.js'
        // }
        appLog(TraceLevels.LOG_EXT_TRACE, 'loading bagtag pectab Failed. ' + JSON.stringify(err))
        goToLocalError('PrintBagtag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        break
      case 'pectabLoaded':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  pectabLoaded ' + event.value.toString())
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  statusChange ' + event)
        if ((event.value[0] > 300 && event.value[0] < 310) || event.value[0] === 108) {
          goToLocalError('PrintBagtag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        }
        break
      default:
    }
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (PrintBagtag.js) componentDidMount() - loadPECTABArray')
    if (paxDocuments) {
      bagtagsRef.current = paxDocuments
    }
    if (bagtagPrinter) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'set printer callback')
      bagtagPrinter.OnDeviceEvent = bagtagPrinterCallback
      bagtagPrinter.loadPECTABArray(bagtagResources, FALSE)
      //setCurrentBagtag(1)
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'bagtag printer is not available')
    }

    //  set style for size
    return () => {
      if (bagtagPrinter) {
        bagtagPrinter.OnDeviceEvent = BagtagPrinterOnEvent
      }
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(PrintBagtag.js): handleActions() ... ' + e.currentTarget.id)
    // TEST CODE
    if (e.currentTarget.id === 'testButtonPrinted') {
      //if (currentBagtagRef.current === 0) {
      let ev = {
        key: 'pectabLoadingComplete',
        value: 0
      }
      bagtagPrinterCallback(ev)
      //}
      setTimeout(() => {
        let ev = {
          key: 'bagtagPrinted',
          value: 0
        }
        bagtagPrinterCallback(ev)
        setTimeout(() => {
          let ev = {
            key: 'offer', //bagtag taken
            value: 104
          }
          bagtagPrinterCallback(ev)
        }, 500)
      }, 1000)
    } else if (e.currentTarget.id === 'testButtonError') {
      let ev = {
        // key: 'statusChange', //bagtagFailed or bagtagFailed
        // value: [203, true]
        key: 'bagtagFailed', //bagtagFailed or bagtagFailed
        value: null
      }
      return bagtagPrinterCallback(ev)
    }
    // end OF TEST CODE
    if (e.currentTarget.id === 'confirmBtn') {
      dispatch(updateLocalData('appFlow', 2))
      switch (transition) {
        case TransitionCodes.PRINT_BAGTAG:
          dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.PRINT_BAGTAG))
          break
        case TransitionCodes.HEAVY_TAG:
          navigate('PutBagOnBelt', 2)
          break
        default:
      }
    }
  }

  const testData = [
    {
      id: 'DESC-1',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'testButtonPrinted',
      text: 'Print',
      group: 0,
      handler: handleActions
    },
    {
      id: 'DESC-2',
      text: 'Error Cases:',
      group: 1
    },
    {
      id: 'testButtonError',
      text: 'print error',
      group: 1,
      handler: handleActions
    }
  ]
  const { formatMessage } = intl
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const header = <PageHeader alignItems="center">{formatMessage(messages.PrintBagtagsTitle)}</PageHeader>
  const textSection = (
    <>
      <PageTitle justifyContent={textAlign}>
        {formatMessage(messages.PrintBagtagsTitle)}
      </PageTitle>
      <PageSubTitle justifyContent={textAlign} height="200px">
        {formatMessage(messages.PrintBagtagsSubTitle)}
      </PageSubTitle>
    </>
  )
  const animationSize = useMedia(null, [420, 350, 420], 420)
  const animationSection = (
    <DynamicImage
      imageName={`animations/embross/${sbdModel}/bagtagPrint.gif`}
      cssName={'animation'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )
  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isLangRequired={false}
        isConfirmRequired={enableConfirm}
        confirmBtnText={formatMessage(messages.buttonDone)}
        confirmAction={handleActions}
        confirmIcon={themeContext.ConfirmButton.doneIcon}
        testData={testData}
      />
    </>
  )
  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = {
    landscapeMode: '50%',
    portraitMode: '95%'
  }

  const { UIDisplay } = useUIBase(
    {
      topSection: textSection,
      bottomSection: animationSection,
      footer
    },
    {
      contentWidth: contentWidth,
      itineraryHeights: ['200px', '120px', '200px']
    }
  )

  return <>{UIDisplay}</>
}

PrintBagtag.propTypes = {}

export default PrintBagtag
