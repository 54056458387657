import React, { useState, Fragment, useContext, useRef } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton } from 'components'
import HelpTxn from './HelpTxn'
import { ThemeContext } from 'styled-components'
import { playSound, getSBDAppMan, getAccessibilityDevice, getAccessibilityManager, getTimeoutMgr } from 'main'
import { END_TXN_REASON } from '../../constants/Constants'
import PropTypes from 'prop-types'
import { Spacer } from 'components/styledComponents'
import { turnLights } from 'utils/lights'
import { getScreenId, getTimeoutDuration } from 'utils/helper'
import { useIntl } from 'react-intl'
import useMedia from 'hooks/useMedia'

const HelpAct = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const [visible, setVisible] = useState(false)
  const { helpAction, helpBtnText } = props
  const popupTimer = useRef(null)
  const kioskInfo = useSelector((state) => state.kioskInfo)
  const accMgr = getAccessibilityManager()
  const timeoutManger = getTimeoutMgr()
  const location = useLocation()
  const disableTimeoutPaths = kioskInfo.PSAM
    ? ['outofservice', 'inactive', 'welcome']
    : ['outofservice', 'inactive', 'passengerselection']

  const screenMessage = `${formatMessage(messages.HelpTxnTitle)}. ${formatMessage(messages.HelpTxnSubTitle)}`
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        { id: 'helpTxnLabel', textId: 'OneDynamicText', textParameters: [screenMessage] },
        { id: 'helpTxnNo', text: formatMessage(messages.buttonQuitNo), buttonId: 'helpTxnNo' }
      ]
    }
  }

  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const handleHelpAction = () => {
    playSound.beepOK()
    turnLights('kioskAgentHelp', true)
    timeoutManger.stopTimer()
    startPopupTimer()
    setVisible(true)
    accMgr.buildPopupGroup(true, accDef)
  }

  const popupCallback = (answer) => {
    playSound.beepOK()
    turnLights('kioskAgentHelp', false)
    clearPopupTimer()
    const screenId = getScreenId(location.pathname)
    const timeoutDuration = getTimeoutDuration(screenId)
    if (disableTimeoutPaths.length > 0 && disableTimeoutPaths.indexOf(screenId) < 0) {
      timeoutManger.resetTimer(timeoutDuration)
    } else {
      console.log('screen:' + screenId + ' timer is disabled.')
      timeoutManger.stopTimer()
    }
    console.log('answer is NO')
    setVisible(false)
    accMgr.buildPopupGroup(false, { disableFocus: true, keepPrevIndex: true })
  }

  const startPopupTimer = () => {
    popupTimer.current = setTimeout(() => {
      popupCallback('NO')
    }, (config.timeoutAgentOverride ? config.timeoutAgentOverride : 300) * 1000)
    console.log(
      'QuitTxn startPopupTimer() ... START POPUP Timer ... ',
      popupTimer,
      ' ... duration ... ',
      config.timeoutAgentOverride ? config.timeoutAgentOverride : 300
    )
  }

  const clearPopupTimer = () => {
    if (popupTimer.current !== null) {
      clearTimeout(popupTimer.current)
      console.log('QuitTxn clearPopupTimer() ... STOP POPUP Timer ... ', popupTimer.current)
      popupTimer.current = null
    }
  }

  return (
    <>
      <HelpTxn visible={visible} popupCallback={popupCallback} buttonGap={'50px'} />
      <IconButton
        id={'helpBtn'}
        //cssName={'quit-btn'}
        color={themeContext.HelpButton.color}
        bgColor={themeContext.HelpButton.bgColor}
        width={themeContext.HelpButton.width[ratioKey]}
        height={themeContext.HelpButton.height[ratioKey]}
        iconLocation={themeContext.HelpButton.buttonPosition}
        buttonPosition={themeContext.HelpButton.buttonPosition}
        padding={themeContext.HelpButton.padding}
        //disable={props.disableAction}
        onClick={handleHelpAction}
        fontSize={themeContext.HelpButton.fontSize[ratioKey]}
        text={helpBtnText}
        icon={themeContext.HelpButton.icon}
        iconColor={themeContext.HelpButton.iconColor}
        disableShadow={themeContext.HelpButton.disableBoxShadow}
      />
    </>
  )
}
HelpAct.propTypes = {
  helpBtnText: PropTypes.string,
  helpBtnStyle: PropTypes.string,
  helpAction: PropTypes.func
}

HelpAct.defaultProps = {
  helpBtnText: 'Help',
  helpBtnStyle: 'btn-quit-up',
  helpAction: null
}
export default HelpAct
