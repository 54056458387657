import { combineReducers } from 'redux'
import { LocalActions, HEADSET, KEYPAD } from 'constants/Constants'
import { deviceIds } from 'embross-device-manager'

export function getDeviceData(
  state = {
    Barcode_Reader: null,
    Cuss_Accessibility_keypad: null,
    Cuss_Accessibility_headset: null,
    Cuss_Accessibility: null,
    Passport_Reader: null,
    ATB_Printer: null,
    BagTag_Printer: null,
    Card_Reader: null
  },
  action
) {
  // initial value
  let data = Object.assign({}, state)
  //console.log('deviceReducer:  ', action)
  if (action.type === LocalActions.DEVICE_HELP) {
    switch (action.key) {
      case deviceIds.BARCODE_READER:
        data.Barcode_Reader = action.value
        break
      case deviceIds.CARD_READER:
        data.Card_Reader = action.value
        break
      case deviceIds.ATB_PRINTER:
        data.ATB_Printer = action.value
        break
      case deviceIds.CUSS_ACCESSIBILITY:
        if (action.comp === HEADSET) data.Cuss_Accessibility_headset = action.value
        else if (action.comp === KEYPAD) data.Cuss_Accessibility_keypad = action.value
        else data.Cuss_Accessibility = action.value
        break
      case deviceIds.BAGTAG_PRINTER:
        data.BagTag_Printer = action.value
        break
      case deviceIds.PASSPORT_READER:
        data.Passport_Reader = action.value
        break
      default:
        return state
    }
    return data
  } else return state
}
