import React from 'react'
import GenericErrors from 'layouts/common/GenericErrors'
import OutOfService from 'layouts/common/OutOfService'
// import PleaseWait from 'layouts/common/PleaseWait'
import GenericPleaseWait from 'layouts/common/PleaseWait'
import Inactive from 'layouts/common/inactive'

/* (1) WELCOME */
import Welcome from 'layouts/sbd/Welcome'
import ScanWelcomeDocuments from 'layouts/sbd/ScanWelcomeDocuments'
import ScanBoardingPass from 'layouts/sbd/ScanBoardingPass'
import PassengerSelection from 'layouts/sbd/PassengerSelection'
import ConfirmTotalWeight from 'layouts/sbd/ConfirmTotalWeight'
import BagsEntry from 'layouts/sbd/BagsEntry'
import HazMatQuestion from 'layouts/sbd/HazMatQuestion'
/* (2) BAG PROCESSING */
import PutBagOnBelt from 'layouts/sbd/PutBagOnBelt'
import BagProcessing from 'layouts/sbd/BagProcessing'
import AcceptingBag from 'layouts/sbd/AcceptingBag'
import AgentOverride from 'layouts/agent/AgentOverride'
import NextBagQuestion from 'layouts/sbd/NextBagQuestion'

/* (3) FINISH */
import ClosingRemarks from 'layouts/sbd/ClosingRemarks'
import PrintReceipt from 'layouts/sbd/PrintReceipt'

/* (4) Optional Print Bagtag */
import PrintBagtag from 'layouts/sbd/PrintBagtag'
import PrintHeavytag from 'layouts/sbd/PrintHeavytag'

/* RETRY PAGES */
import ErrorMismatch from 'layouts/sbd/ErrorMismatch'
import ErrorBPMismatch from 'layouts/sbd/ErrorBPMismatch'
import Intrusion from 'layouts/sbd/Intrusion'
import RepositionBag from 'layouts/sbd/RepositionBag'
/* Quit or Continue error */
import ErrorPrint from 'layouts/sbd/ErrorPrint'

/* BIOMETRIC PAGES */
import ScanDocuments from 'layouts/biometric/ScanDocuments'
import ScanOppositeDoc from 'layouts/biometric/ScanOppositeDoc'
import TakePhoto from 'layouts/biometric/TakePhoto'
import PhotoRetry from 'layouts/biometric/photoRetry'
import VerifyDocuments from 'layouts/agent/VerifyDocuments'
import AgentScan from 'layouts/agent/AgentScan'
import ShowDisclaimer from 'layouts/biometric/ShowDisclaimer'
import Recognition from 'layouts/biometric/recognition'

/* PAYMENT*/
import OverweightPayment from 'layouts/payment/OverweightPayment'
import PaymentCardSwipe from 'layouts/payment/PaymentCardSwipe'
import DisplayPaymentReceipt from 'layouts/payment/DisplayPaymentReceipt'
//import GetContactEmail from 'layouts/payment/GetContactEmail'

export const routes = [
  { path: `${PUBLIC_URL}/`, element: <Inactive /> },
  { path: `${PUBLIC_URL}/Inactive`, element: <Inactive /> },
  { path: `${PUBLIC_URL}/Welcome`, element: <Welcome /> },
  { path: `${PUBLIC_URL}/ScanWelcomeDocuments`, element: <ScanWelcomeDocuments /> },
  { path: `${PUBLIC_URL}/PassengerSelection`, element: <PassengerSelection /> },
  { path: `${PUBLIC_URL}/HazMatQuestion`, element: <HazMatQuestion /> },
  { path: `${PUBLIC_URL}/ConfirmTotalWeight`, element: <ConfirmTotalWeight /> },
  { path: `${PUBLIC_URL}/VerifyDocuments`, element: <VerifyDocuments /> },
  { path: `${PUBLIC_URL}/BagsEntry`, element: <BagsEntry /> },
  { path: `${PUBLIC_URL}/ScanBoardingPass`, element: <ScanBoardingPass /> },
  { path: `${PUBLIC_URL}/PutBagOnBelt`, element: <PutBagOnBelt /> },
  { path: `${PUBLIC_URL}/BagProcessing`, element: <BagProcessing /> },
  { path: `${PUBLIC_URL}/AcceptingBag`, element: <AcceptingBag /> },
  { path: `${PUBLIC_URL}/ClosingRemarks`, element: <ClosingRemarks /> },
  { path: `${PUBLIC_URL}/PrintReceipt`, element: <PrintReceipt /> },
  { path: `${PUBLIC_URL}/PrintBagtag`, element: <PrintBagtag /> },
  { path: `${PUBLIC_URL}/PrintHeavytag`, element: <PrintHeavytag /> },
  { path: `${PUBLIC_URL}/pleaseWait`, element: <GenericPleaseWait /> },
  { path: `${PUBLIC_URL}/AgentOverride`, element: <AgentOverride /> },
  { path: `${PUBLIC_URL}/Intrusion`, element: <Intrusion /> },
  { path: `${PUBLIC_URL}/OutOfService`, element: <OutOfService /> },
  { path: `${PUBLIC_URL}/ScanDocuments`, element: <ScanDocuments /> },
  { path: `${PUBLIC_URL}/ScanOppositeDoc`, element: <ScanOppositeDoc /> },
  { path: `${PUBLIC_URL}/ShowDisclaimer`, element: <ShowDisclaimer /> },
  { path: `${PUBLIC_URL}/TakePhoto`, element: <TakePhoto /> },
  { path: `${PUBLIC_URL}/Recognition`, element: <Recognition /> },
  { path: `${PUBLIC_URL}/PhotoRetry`, element: <PhotoRetry /> },
  { path: `${PUBLIC_URL}/AgentScan`, element: <AgentScan /> },
  { path: `${PUBLIC_URL}/Error`, element: <GenericErrors /> },
  { path: `${PUBLIC_URL}/ErrorMismatch`, element: <ErrorMismatch /> },
  { path: `${PUBLIC_URL}/ErrorBPMismatch`, element: <ErrorBPMismatch /> },
  { path: `${PUBLIC_URL}/ErrorPrint`, element: <ErrorPrint /> },
  { path: `${PUBLIC_URL}/OverweightPayment`, element: <OverweightPayment /> },
  { path: `${PUBLIC_URL}/PaymentCardSwipe`, element: <PaymentCardSwipe /> },
  { path: `${PUBLIC_URL}/DisplayPaymentReceipt`, element: <DisplayPaymentReceipt /> },
  { path: `${PUBLIC_URL}/NextBagQuestion`, element: <NextBagQuestion /> }
]
