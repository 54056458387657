import { deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import EGateListener from 'listeners/eGateListener'

/**
 *  Device representing an EGate
 *  @extends {Device}
 */
export default class EGate extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'eGate'
    /**
     * Device id - deviceIds.EGATE {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.EGATE
    this.setListener(new EGateListener())
  }
  /**
   *  call to open the gate and keep the gate open until closeGate is called
   */
  openGate() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'openGate().')
      //this.socket.sendRequest(this.deviceId, 'openGate')
      this.socket.sendCommand(this.deviceId, 'openGate')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'openGate() exception: ' + e)
    }
  }
  /**
   *  Async call to close the gate opened using openGate()
   */
  closeGate() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'closeGate().')
      //this.socket.sendRequest(this.deviceId, 'closeGate')
      this.socket.sendCommand(this.deviceId, 'closeGate')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'closeGate() exception: ' + e)
    }
  }
  /**
   *  Async call to open the gate for the passenger. The gate will automatically close after passenger has passed through the gate. The gate will also automatically close if no one passes through within 10 seconds (this is configurable)
   * @param {int} gate null is for gate with single door. 1 is for entry gate and 2 is for exit gate.
   */
  openGateForPax(gate = null) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'openGateForPax(). ', gate)
      //this.socket.sendRequest(this.deviceId, 'openGate')
      if (gate !== null) {
        this.socket.sendRequest(this.deviceId, 'openGateForPax', gate)
      } else {
        this.socket.sendRequest(this.deviceId, 'openGateForPax')
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'openGateForPax() exception: ' + e)
    }
  }
  /**
   *  Async call to get egate mode
   * @return {String} returns egate mode: DEFAULT / LOCKDOWN / EMERGENCY / MANUAL_BOARDING / MAINTENANCE / FREE_PASS
   */
  queryGateMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'queryGateMode')
      this.socket.sendRequest(this.deviceId, 'queryGateMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'queryGateMode() exception: ' + e)
    }
  }

  /**
   * call to enable emergency mode
   * @return 0 if success, 1 if failed
   */
  enableEmergencyMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'enableEmergencyMode')
      this.socket.sendCommand(this.deviceId, 'enableEmergencyMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'enableEmergencyMode() exception: ' + e)
    }
  }

  /**
   * call to disable emergency mode
   * @return 0 if success, 1 if failed
   */
  disableEmergencyMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'disableEmergencyMode')
      this.socket.sendCommand(this.deviceId, 'disableEmergencyMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'disableEmergencyMode() exception: ' + e)
    }
  }

  /**
   * call to start warning alarm
   * @return 0 if success, 1 if failed
   */
  startWarningAlarm() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'startWarningAlarm')
      this.socket.sendCommand(this.deviceId, 'startWarningAlarm')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'startWarningAlarm() exception: ' + e)
    }
  }

  /**
   * call to start standard alarm
   * @return 0 if success, 1 if failed
   */
  startStandardAlarm() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'startStandardAlarm')
      this.socket.sendCommand(this.deviceId, 'startStandardAlarm')
    } catch (e) {
      console.log('startStandardAlarm() exception: ' + e)
      this.logMessage(TraceLevels.LOG_ALERT, 'startStandardAlarm() exception: ' + e)
    }
  }

  /**
   *  Async call to clear alarm
   * @return 0 if success, 1 if failed
   */
  clearAlarm() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'clearAlarm')
      this.socket.sendCommand(this.deviceId, 'clearAlarm')
    } catch (e) {
      console.log('clearAlarm() exception: ' + e)
      this.logMessage(TraceLevels.LOG_ALERT, 'clearAlarm() exception: ' + e)
    }
  }

  /**
   *  Async call to check is in manual boarding mode
   */
  isGateInManualBoarding() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isGateInManualBoarding().')
      this.socket.sendRequest(this.deviceId, 'isGateInManualBoarding')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isGateInManualBoarding() exception: ' + e)
    }
  }

  /**
   *  Async call to check passenger in lane after barriers are open
   *  @return true or false
   */

  isPassengerInLaneAfterBarriersOpen() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isPassengerInLaneAfterBarriersOpen().')
      this.socket.sendRequest(this.deviceId, 'isPassengerInLaneAfterBarriersOpen')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPassengerInLaneAfterBarriersOpen() exception: ' + e)
    }
  }

  /**
   *  Async call to check if gate in alarm
   *  @return true or false
   */

  isGateInAlarm() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isGateInAlarm().')
      this.socket.sendRequest(this.deviceId, 'isGateInAlarm')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isGateInAlarm() exception: ' + e)
    }
  }

  /**
   *  Async call to check if gate in vandalism State
   *  @return true or false
   */

  isGateInVandalismState() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isGateInVandalismState().')
      this.socket.sendRequest(this.deviceId, 'isGateInVandalismState')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isGateInVandalismState() exception: ' + e)
    }
  }

  /**
   * Send setup string to the E-Gate to operate in manual mode (openGate() opens the gate and closeGate() closes the gate) or in automatic mode(only need to call openGateForPax()).
   * @param {String} data - setup string (AUTOMATIC_DOOR_CONTROL or MANUAL_DOOR_CONTROL)
   * @example <CONFIG_OPERATION_MODE>AUTOMATIC_DOOR_CONTROL</CONFIG_OPERATION_MODE>
   */
  setup(data) {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'setup(): ' + data)
      this.socket.sendRequest(this.deviceId, 'setup', data)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setup() exception: ' + e)
    }
  }

  /**
   * This will open first barrier and you will receive eGateOpenedEntry.
   * ex) If validation fails, app will call this
   */
  allowPaxToLeaveMantrapThroughEntry() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'allowPaxToLeaveMantrapThroughEntry().')
      this.socket.sendRequest(this.deviceId, 'allowPaxToLeaveMantrapThroughEntry')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'allowPaxToLeaveMantrapThroughEntry() exception: ' + e)
    }
  }

 /**
  * Get version of the face tracking.
  */
  getVersion() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion()')
      this.socket.sendRequest(this.deviceId, 'getVersion')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getVersion() exception: ' + e)
    }
  }
}
