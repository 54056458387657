import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useIntl } from 'react-intl'
import { playSound, history, getAccessibilityDevice } from 'main'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { getBagTagPrintStreams } from 'actions/etsTransactions/getBagTagPrintStreams'
import { EMBTable } from 'components'
import {
  ETS_TXN_STATUS,
  HELP_POPUP,
  HELP_OK_BTN,
  FunctionCodes,
  ServiceNames,
  PASSENGER_VALIDATION_STATUS
} from 'constants/Constants'
import { turnLights } from 'utils/lights'
import { replacer, generateBP, getObjectFromArrayByKey, checkLandscape, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { Footer } from '../footer'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { parseBarcode } from '../../utils/mediaParser'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import { PageHeader, PageSubTitle, PageContent, DynamicImage, Spacer } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { ThemeContext } from 'styled-components'
import { Animation } from 'components'
const ScanBoardingPass = (props) => {
  const [landscapeMode, screenWidth, screenHeight] = checkLandscape()
  const displayName = 'ScanBoardingPass'
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const embTableWidth = useMedia(null, ['440px', '400px', '400px'], '400px')
  const tableTextAlign = useMedia(null, ['flex-start', 'flex-start', 'center'], 'center')
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const [paxData, setPaxData] = useState([])
  const testNumber = useRef(0)
  const scannedCallback = (scannedValue) => {
    props.getItinerarySBD({ barcodeData: scannedValue }, '')
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
  const columns = [
    {
      header: intl.formatMessage(messages.ScanBoardingPassPaxListHeader),
      width: embTableWidth,
      textAlign: tableTextAlign
    }
  ]
  const accessibility = useRef()
  useEffect(() => {
    let itinData = null
    let data = []
    let scannedPaxNames = ''
    accessibility.current = getAccessibilityDevice()
    if (!itineraryInfo) {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '>> (ScanBoardingPass.js) componentDidMount() - itineraryInfo NOT AVAILABLE ...'
      )
      navigate('error')
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '(ScanBoardingPass.js) componentDidMount() - itineraryInfo AVAILABLE !!!')
      itineraryInfo.scanSequence.map((ord) => {
        for (let i = 0; i < itineraryInfo.passengerBagtagDetails.length; i++) {
          if (itineraryInfo.passengerBagtagDetails[i].passenger.ordinal === ord) {
            data.push({
              data: [
                itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
                  ' ' +
                  itineraryInfo.passengerBagtagDetails[i].passenger.lastName
              ]
            })
            scannedPaxNames +=
              itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
              ' ' +
              itineraryInfo.passengerBagtagDetails[i].passenger.lastName +
              ','
          }
        }
      })
      console.log('data', data)
    }
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(ScanBoardingPass.js) call barcode enable function')
      enable()
      //turnLights('scanBarcode', true)
    }
    setPaxData(data)
    testNumber.current = itineraryInfo.scanSequence.length
    console.log('pax data:', data)
    let accDef = {
      pathName: 'ScanBoardingPass',
      startIndex: 0,
      screenDescriptionId: 'ScanBoardingPassScreen',
      sequenceDef: {
        sequence: [
          { id: 'ScanBoardingPassTitle', textId: 'ScanBoardingPassTitle' },
          { id: 'ScanBoardingPassSubTitle', textId: 'ScanBoardingPassSubTitle' },
          { id: 'scannedPaxList', textId: 'scannedPaxList', textParameters: [scannedPaxNames] },
          { id: 'quitBtn', textId: 'NavQuit', buttonId: 'quitBtn' },
          { id: 'langBtn', textId: 'NavSelectLanguage', buttonId: 'langBtn' },
          { id: 'confirmBtn', textId: 'NavContinue', buttonId: 'confirmBtn' }
        ],
        nextSequence: ['confirmBtn']
      }
    }
    // props.updateAccessibility(AccessibilityActions.UPDATE_ACCESSIBILITY, accDef)
    if (accessibility.current.enabled) {
      accessibility.current.DeviceEvent = {
        key: 'componentDidMount',
        value: accDef
      }
    }
    return () => {
      disable()
      //turnLights('scanBarcode', false)
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    let selectedPassengers = []
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanBoardingPass.js) handleClick() ... target: ' + e.currentTarget.id)
    let location = {
      state: {
        from: 'ScanBoardingPass',
        statistics: {
          paxOrdinal: null,
          paxName: null
        }
      }
    }
    /// TEST CODE

    let testPassager = null
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          testPassager = testPassengers[testNumber.current]
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            // value: 'M1MAGAR/RAVI          EHVDTE  DELBOMAI 0624 119Y017D0002 15D>5180OO0041BAI              2A09823046994170 AI                        N*50600000K0900'
            // value: 'M1SHIMA/GOYAL         EJ72S9  DELBOMAI 0624 119Y017D0002 15D>5180OO0041BAI              2A09823046994170 AI                        N*50600000K0900'
            value: generateBP(
              itineraryInfo.recordLocator,
              testPassager.firstName,
              testPassager.lastName,
              // config.testData.from,
              config.defaultLocation,
              config.testData.to,
              config.testData.airLine,
              config.testData.flightNumber
            )
          })
          break
        case 'buttonScanUnreadable':
          console.log('test boarding pass is unreadable')
          testPassager = testPassengers[testNumber.current]
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: null
          })
          break
        case 'buttonScanNotInPNR':
          console.log('test boarding pass is not in current PNR')
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: generateBP('AAAAAA', 'firstName', 'lastName', 'AUH', 'YVR', 'NK', '1001')
          })
          break
        case 'buttonScanDuplicate':
          console.log('test boarding pass is duplicate')
          testPassager = testPassengers[0]
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: generateBP(
              itineraryInfo.recordLocator,
              testPassager.firstName,
              testPassager.lastName,
              'AUH',
              'YVR',
              'NK',
              '1001'
            )
          })
          break
      }
    }
    let passenger = null
    let passengerBagtagDetail = null
    switch (e.currentTarget.id) {
      case 'confirmBtn':
        /*for (let i = 0; i < scanSequence.length; i++) {
          passengerBagtagDetail = getObjectFromArrayByKey(passengerBagtagDetails, 'passenger/ordinal', scanSequence[i])
          if (passengerBagtagDetail.passenger) {
            passenger = passengerBagtagDetail.passenger
            selectedPassengers.push({
              ordinal: passenger.ordinal,
              firstName: passenger.firstName,
              lastName: passenger.lastName,
              bioCheck: PASSENGER_VALIDATION_STATUS.UNKNOWN,
              visaCheck: PASSENGER_VALIDATION_STATUS.UNKNOWN,
              passportScanned: false
            })
          }
        }
        props.updateLocalData('updateValidatePaxResult', selectedPassengers)*/
        props.identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_SCAN)
    }
  }
  const testButtons = [
    {
      id: 'buttonScanBarcodeTEST',
      text: 'Test',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanUnreadable',
      text: 'Unreadable',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanNotInPNR',
      text: 'Not in PNR',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanDuplicate',
      text: 'Duplicate',
      handler: handleActions,
      cssName: 'test-button-up'
    }
  ]

  const handleNextAction = (e) => {

  }
  const header = (
    <PageHeader id={'ScanBoardingPassTitle'}>{intl.formatMessage(messages.ScanBoardingPassTitle)}</PageHeader>
  )
  const textSection = (
    <>
      <PageSubTitle id={'ScanBoardingPassSubTitle'} alignItems="center" height={'60px'}>
        {formatMessage(messages.ScanBoardingPassSubTitle)}
      </PageSubTitle>
      <PageContent flexFlow="row">
        {landscapeMode ? null : <Spacer width="56px" />}
        <EMBTable
          columns={columns}
          headerCellCss={''}
          cellCss={''}
          scrollbarWidth={36}
          scrollbarGap={20}
          rows={paxData.reverse()}
          headerHeight={50}
          rowHeight={30}
          rowFontSize={20}
          headFontSize={20}
          maxDisplayRows={5}
        />
      </PageContent>
    </>
  )
  const animationScanBp = isLandscape ? themeContext.AnimationLandscape.ScanBp : themeContext.AnimationPortrait.ScanBp
  const imageLocation = `${themeContext.AnimationPath}/${sbdModel}/${animationScanBp}`
  const animationSection = (
    <Animation imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
  )
  const footer = (
    <Footer
      isQuitRequired={true}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={handleNextAction}
      confirmBtnText={formatMessage(messages.buttonNext)}
      testData={testButtons}
    />
  )
  const contentWidth = { landscapeMode: '50%', portraitMode: '90%' }
  const popupSection = <>{popupContainer.retryMediaAccess}</>
  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    { contentWidth: contentWidth, displayItinerary: true, itineraryHeights: ['200px', '120px', '200px'] }
  )

  return <>{UIDisplay}</>
}

ScanBoardingPass.propTypes = {
  updateLocalData: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
  getItinerarySBD: PropTypes.func.isRequired,
  getBagTagPrintStreams: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    validatePaxResult: state.localData.validatePaxResult,
    appFlow: state.localData.appFlow,
    itineraryInfo: state.responses.itineraryInfo,
    barcodeScanAttempts: state.localData.errorBarcodeAttempts,
    configElements: state.sessions.configElements
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { updateLocalData, updateError, getItinerarySBD, getBagTagPrintStreams, identifyService },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanBoardingPass)
