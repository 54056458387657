import React, { useRef, useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Footer } from '../footer'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  getDeviceManager,
  playSound,
  store,
  getSBDAppMan,
  getVersion,
  history,
  getTimeoutMgr,
  getBuildAccessibility
} from 'main'
import { sendPhotoMatch } from 'actions/etsTransactions/sendPhotoMatch'
import { TestButtons, Popup, Button } from 'components'

import { replacer, getTestBP, generateBP, checkLandscape, maskPCI, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import ItineraryInfo from 'components/ItineraryInfo' // used only for testing with no CUSS

import { AuroraPanel } from 'components'
import { GeneratePP, GenerateChipPP } from 'utils/passportGenerator'
//import { itinerarySuccessHandler } from 'actions/etsTransactions/getItinerarySBD'

import { END_TXN_REASON, MEDIATYPES } from 'constants/Constants'
import { useIntl } from 'react-intl'
import { updateLocalData } from 'actions/localActions'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import { PageTitle, PageSubTitle, PageHeader, PageSubContent, DynamicImage } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'

const anmRemovePassport = `animations/embross/${config.sbdModel}/RemovePassport.gif`

function usePassportScanner(passportScannedCallback) {
  let removeTimer = null
  let mediaData = null
  let dg2Image = null
  let passportDataReceived = false
  let passportRemoved = false
  const [retryAttempts, setRetryAttempts] = useState(0)
  const passportReader = getDeviceManager().getDevice(deviceIds.PASSPORT_READER)
  const timeoutTakeDocs = config.timeoutTakeDocs * 1000
  const maxScanAttempts = config.mediaAccessRetryCount
  const removePassportRef = useRef(null)
  const retryScanRef = useRef(null)
  const passportReaderCallback = (event) => {
    // Reset timer
    getTimeoutMgr().resetTimer()
    appLog(TraceLevels.LOG_TRACE, '(usePassportScanner.js) passportReaderOnEvent() is called from client: ' + event.key)
    /*
      should only contain device specific logic in the callback. common device callback logic should put in the useErrorAttemptHandle hook.
    */
    // deviceErrorHandler(event.key)
    let tracks = []
    switch (event.key) {
      case 'passportInserted':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportInserted')
        break
      case 'passportDamaged':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportDamaged')
        handleRetryAttempts()
        break
      case 'passportRemoved':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportRemoved')
        if (removeTimer) {
          clearTimeout(removeTimer)
          removePassportRef.current.hide()
        }
        if (passportDataReceived) {
          console.log('mediaData: ', mediaData)
          passportScannedCallback(mediaData, dg2Image)
        }
        passportRemoved = true
        break
      case 'passportReadInternal':
        appLog(TraceLevels.LOG_TRACE, 'Event:  passportReadInternal ' + maskPCI(event.value, deviceIds.PASSPORT_READER))
        // Set passport data
        if (event.value !== '') {
          dg2Image = null
          tracks = null
          mediaData = {
            track1: null,
            track2: null,
            track3: null,
            mediaType: MEDIATYPES.PASSPORT
          }
        } else {
          handleRetryAttempts()
          appLog(TraceLevels.LOG_EXT_TRACE, 'Event: passportReadInternal scanned mediaData is empty, try again...')
        }
        /** Send MediaData to ETS: Received passportRemoved event before the MediaData has been collected **/
        if (passportRemoved) {
          console.log('mediaData: ', mediaData)
          passportScannedCallback(mediaData, dg2Image)
        } else {
          console.log('Passport is not removed yet...')
          removeTimer = setTimeout(() => {
            if (removePassportRef) {
              removePassportRef.current.show()
            }
            appLog(TraceLevels.LOG_EXT_TRACE, 'Event: passportReadInternal display remove passport animation popup')
          }, timeoutTakeDocs)
          appLog(
            TraceLevels.LOG_TRACE,
            'Event: passportReadInternal passport remove timer: ' + removeTimer + ' ' + timeoutTakeDocs
          )
        }
        passportDataReceived = true
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  statusChange ' + event.value.toString())
        break
      default:
    }
  }
  const enable = () => {
    passportReader.OnDeviceEvent = passportReaderCallback
    passportReader.enable()
  }
  const disable = () => {
    passportReader.OnDeviceEvent = null
    passportReader.disable()
    removePassportRef.current.hide()
    if (removeTimer) {
      clearTimeout(removeTimer)
      removeTimer = null
    }
  }
  // General error handling for the device callback. Always call after the main callback function
  // useErrorAttemptHandle(eventValue)

  const handleRetryAttempts = () => {
    if (retryAttempts + 1 === maxScanAttempts) {
      getSBDAppMan().doQuit(END_TXN_REASON.MEDIA_ACCESS_RETRY, 'Maximum media access retries exceeded')
    } else {
      setRetryAttempts(retryAttempts + 1)
      retryScanRef.current.show()
    }
  }

  const popupContainer = {
    removePassport: (
      <Popup ref={removePassportRef}>
        <div id="removePassport" tabIndex="0">
          <span className={'page-title'}>
            <FormattedMessage {...messages.ScanDocs_RemoveDoc} />
          </span>
        </div>
        <div className="emb-ScanDocuments-bodyContainerPopup">
          <div className="emb-body-center">
            <DynamicImage imageName={anmRemovePassport} cssName={'emb_animation_drawbox'} width={420} height={420} />
          </div>
        </div>
      </Popup>
    ),
    retryMediaAccess: (
      <Popup ref={retryScanRef}>
        <div className="popup-body">
          <div id="retryMediaAccess" className="popup-title" tabIndex="0">
            <FormattedMessage {...messages.Error_PassportInvalid} />
          </div>
          <div className="popup-content">
            <FormattedMessage
              {...messages.Error_MediaRetriesRemaining}
              values={{ remains: maxScanAttempts - retryAttempts }}
            />
          </div>
          <div className="popup-buttons-right">
            <Button id={'btnOK'} cssName={'btn-popup-up'} onClick={() => retryScanRef.current.hide()}>
              <FormattedMessage {...messages.buttonOk} />
            </Button>
          </div>
        </div>
      </Popup>
    )
  }

  return [enable, disable, passportReaderCallback, popupContainer]
}

const ScanWelcomeDocuments = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const buildAccessibility = getBuildAccessibility()
  const { formatMessage } = intl
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const scannedCallback = (scannedValue, image) => {
    if (props.location && props.location.state && props.location.state.json) {
      //itinerarySuccessHandler(props.location.state.json, '(getItinerarySBD.js)')
    }
  }
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const [enable, disable, passportReaderCallback, popupContainer] = usePassportScanner(scannedCallback, sbdModel)

  useEffect(() => {
    handleAccessibility()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js)  did mount,call scan passport enable function...')
    enable()

    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) UNMOUNTED')
      //turnLights('scanPassport', false)
      appLog(TraceLevels.LOG_TRACE, '(ScanDocuments.js) disable passport reader.')
      disable()
    }
  }, [])

  /** For Testing **/
  const handleClick = (e) => {
    let passportData = null
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) : handleClick() ... ' + e.currentTarget.id)
    switch (e.currentTarget.id) {
      case 'buttonScanDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        // passportData = GenerateChipPP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        passportData = GenerateChipPP('TEST1', 'TEST2', 'CAN', photo1)
        // passportData = testData
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        break
      case 'buttonSwipeDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        // passportData = GeneratePP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        passportData = GenerateChipPP('TEST1', 'TEST2', 'CAN', photo1)
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        break
      case 'buttonScanDocsRemove':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        // passportData = GeneratePP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        passportData = GenerateChipPP('TEST1', 'TEST2', 'CAN', photo1)
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        setTimeout(() => disable(), 7000)
        break
      case 'buttonNotReadable':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportDamaged ')
        return passportReaderCallback({
          key: 'passportDamaged',
          value: ''
        })
      case 'buttonInvalidPassport':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GeneratePP('FirstName', 'LastName', null)
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'push: /')
        navigate(config.firstScreen)
    }
  }

  const testButtons = [
    {
      id: 'buttonScanDocs',
      text: 'Scan',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonSwipeDocs',
      text: 'Swipe',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonScanDocsRemove',
      text: 'Remove',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonInvalidPassport',
      text: 'Invalid',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonNotReadable',
      text: 'Not Readable',
      handler: handleClick,
      group: 0
    }
  ]

  const animationSize = useMedia(null, [420, 350, 420], 420)
  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${sbdModel}/PassportScan.gif`}
      width={animationSize}
      height={animationSize}
    />
  )

  // let message = formatMessage(messages.ScanDocs_Body, { passengerSequence: getScanSequence(validatePaxResult) })
  const paxDetail = props.location.state.json.etsResponse.passengerBagtagDetails[0]
  const paxName = `${paxDetail.passenger.firstName} ${paxDetail.passenger.lastName}`
  let message = formatMessage(messages.ScanDocs_Welcome_Body, { passengerSequence: paxName })
  const textSection = (
    <PageSubContent width={'100%'} justifyContent={'center'}>
      <PageTitle>{message}</PageTitle>
    </PageSubContent>
  )

  /* let message = docRetryStatus
    ? formatMessage(messages.ScanDocs_Retry, {
        passengerSequence: currentPassenger
          ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
          : getScanSequence(validatePaxResult)
      })
    : formatMessage(messages.ScanDocs_Body, {
        passengerSequence: currentPassenger
          ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
          : getScanSequence(validatePaxResult)
      })

  const textSection = (
    <PageSubContent width={'100%'} justifyContent={'center'}>
      <PageTitle>{message}</PageTitle>
    </PageSubContent>
  ) */

  const header = <PageHeader alignItems="center">{formatMessage(messages.ScanDocs_Welcome_Header)}</PageHeader>

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        testData={testButtons}
      />
    </>
  )

  const popupSection = (
    <>
      {popupContainer.removePassport}
      {popupContainer.retryMediaAccess}
    </>
  )

  const handleAccessibility = () => {
    const textParameters = [intl.formatMessage(messages.ScanDocs_Header), message]
    const accDef = {
      pathName: 'ScanDocuments',
      startIndex: 0,
      isLangRequired: true,
      isQuitRequired: true,
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters }]
      }
    }
    buildAccessibility(accDef)
  }

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ScanWelcomeDocuments
