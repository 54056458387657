import React, { useState, useContext, useEffect, useRef } from 'react'
import { populateItineraryInfo } from 'utils/helper'
import { AuroraPanel, Spacer } from 'components'
import { PageContent, PageSubContent, PageTitle } from 'components/styledComponents'
import ItineraryInfo from 'components/ItineraryInfo/ItineraryInfo'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import { Page } from '../../components/styledComponents'
// interface itineraryInfo {
//   recordLocator: String,
//   outboundFlights:xxxx,
//   passengerBagtagDetails: xxxxx,
//   scanSequence: Number[],
//   allowance: xxxxx
// }

// interface UIBaseObj {
//   UIDisplay: JSX.Element,
//   itineraryInfo: null|Boolean
// }

const useUIBase = (
  { header = null, topSection = null, bottomSection = null, footer = null, popupContainer = null },
  {
    contentWidth = { landscapeMode: '50%', portraitMode: '100%' },
    displayItinerary = true,
    itineraryHeights = null,
    ...options
  }
) => {
  const itineraryHeight = itineraryHeights ? useMedia(null, itineraryHeights, '120px') : '120px'
  const isLandscape = useCheckLandscape()
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const [itineraryData, setItineraryData] = useState(null)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    setItineraryData(itineraryInfo ? populateItineraryInfo(itineraryInfo) : null)
  }, [])
  let headerDisplay = header ? <>{header}</> : null

  let contentDisplay = ''
  if (isLandscape) {
    if (topSection && bottomSection) {
      contentDisplay = (
        <PageContent flexFlow={'column'} justifyContent={themeContext.MainContent.justifyContent}>
          <PageSubContent width="100%">{bottomSection}</PageSubContent>
          {/* <Spacer height="20px" width="20px" /> */}
          <PageSubContent width="100%">{topSection}</PageSubContent>
        </PageContent>
      )
    } else {
      contentDisplay = (
        <PageContent>
          <PageSubContent justifyContent="center" width="50%">
            {/* {itineraryData ? (
              <ItineraryInfo boxHeight={itineraryHeight} itineraryInfo={displayItinerary ? itineraryData : null} />
            ) : (
              ''
            )} */}
            {topSection ? topSection : bottomSection ? bottomSection : ''}
          </PageSubContent>
        </PageContent>
      )
    }
  } else {
    contentDisplay = (
      <>
        {headerDisplay}
        {/* {itineraryData ? (
          <ItineraryInfo boxHeight={itineraryHeight} itineraryInfo={displayItinerary ? itineraryData : null} />
        ) : (
          ''
        )} */}
        {/* {topSection ? bottomSection : topSection}
        {topSection ? topSection : bottomSection} */}
        <PageContent flexFlow={'column'} justifyContent={themeContext.MainContent.justifyContent}>
          <PageSubContent justifyContent={'center'} alignItems={'center'} width="100%">{bottomSection}</PageSubContent>
          <PageSubContent alignItems="center" textAlign="center" justifyContent={'center'} width="100%">
            {topSection}
          </PageSubContent>
        </PageContent>
      </>
    )
  }

  const display = (
    <>
      <PageContent flexFlow={'column'}>
        <PageContent padding={themeContext.MainContent.outerPadding[ratioKey]}>
          <PageContent
            flexFlow={'column'}
            id={'page-content'}
            tabIndex={'0'}
            width={'100%'}
            borderRadius={themeContext.MainContent.borderRadius}
            boxShadow={themeContext.MainContent.boxShadow}
            background={themeContext.MainContent.background}
            padding={themeContext.MainContent.innerPadding[ratioKey]}
          >
            {isLandscape ? headerDisplay : null}
            {contentDisplay}
          </PageContent>
        </PageContent>
        {footer}
        {popupContainer}
      </PageContent>
    </>
  )

  return { UIDisplay: display, itineraryInfo }
}

export default useUIBase
