import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import './popup.scss'

const Popup = (props) => {
  const themeContext = useContext(ThemeContext)
  const { visible, showOverlay, children, width, height, isFullPage } = props
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const style = {
    marginLeft: `-${themeContext.Popup.width[ratioKey] / 2}px`,
    marginTop: `-${themeContext.Popup.height[ratioKey] / 2}px`,
    width: `${themeContext.Popup.width[ratioKey]}px`,
    height: `${themeContext.Popup.height[ratioKey]}px`,
    backgroundColor: themeContext.Popup.bgColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: themeContext.Popup.padding[ratioKey]
  }
  return (
    <section className={visible ? 'popup-show' : 'popup-hide'}>
      {showOverlay ? <div className={visible ? 'overlay-show' : 'overlay-hide'} /> : ''}
      <div className="popup-dialog" style={style}>
        {children}
      </div>
    </section>
  )
}

Popup.defaultProps = {
  visible: false,
  isFullPage: false,
  showOverlay: true,
  width: '600px',
  height: '800px'
}

export default Popup
