import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import { ErrCodes } from 'constants/Errors'

export async function fetchWithTimeout(
  url,
  data,
  ms = config.timeoutHostTransaction * 1000
) {
  let response
  const axiosInstance = axios.create({
    baseURL: clientConfig.biometricsUrl,
    timeout: config.timeoutHostTransaction * 1000
  })
  try {
    response = await axiosInstance.post(url, data, { timeout: ms })
    return response.data
  } catch (error) {
    if (error.response) {
      const errorDetail = {
        sourceType: SourceType.LOCAL,
        errCode: ErrCodes.ETS_MESSAGE_ERROR,
        msg: {
          title: 'Error_Default',
          subTitle: 'Error_SeeAgent'
          //'The request was made and the server responded with a status code that falls out of the range of 2xx'
        },
        source: 'fetchWithTimeout',
        isOOS: false
      }
      throw errorDetail
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const errorDetail = {
        sourceType: SourceType.LOCAL,
        errCode: ErrCodes.ETS_COMM_ERROR,
        msg: {
          title: 'Error_Default',
          subTitle: 'Error_SeeAgent'
        },
        source: 'fetchWithTimeout',
        isOOS: false
      }
      throw errorDetail
    } else {
      // Something happened in setting up the request that triggered an Error
      const errorDetail = {
        sourceType: SourceType.LOCAL,
        errCode: ErrCodes.ETS_COMM_ERROR,
        msg: {
          title: 'Error_Default',
          subTitle: 'Error_SeeAgent'
        },
        source: 'fetchWithTimeout',
        isOOS: false
      }
      throw errorDetail
    }
  }
}
